/**
 * Schema required for implementing process
 */

import React from "react";

import Base from './../base';

import { Link } from 'react-router-dom'

import { Steps, Tag } from 'antd'

import { Checklist } from '../index';

import StepAdd from './components/step-add/step-add';

import StepDetail from './components/step-detail/step-detail';

import { Checklists, Process, StepTransactions } from './../../models';

class Step extends Base {

    constructor() {

        super()

        this.fields = [{
            field: 'name',
            caption: 'Name'
        }, {
            field: 'description',
            caption: 'Description'
        }, {
            field: 'identifier',
            caption: 'Identifier'
        }, {
            field: 'active',
            type: 'boolean',
            options: [true, false],
            caption: 'Active'
        }, {
            field: 'order',
            caption: 'Order',
            type: 'number'
        }
        ]

        // this.columns = [];
    }

    get getEndpoint() {
        return 'steps'
    }

    get path() {
        return `steps`
    }

    get getName() {
        return `steps`
    }

    /**
     * Load the step record and its dependent  tables
     */
    getDetail = async (step_id) => {

        const result = await this.getRecord(step_id)

        var queries = [{
            field: 'step_id',
            value: step_id
        }]

        const { checklists = [] } = await Checklists.get(queries);

        return {
            ...result,
            checklists
        }
    }

    /**
     * Start a step with an identifier
     * This would be used in each modailty component
     * 
     * @param {*} identifier 
     * @returns 
     */
    async startStepWithIdentifer(identifier, body) {

        console.log("Initiating step ", identifier)

        let app = this.getFireStoreApp()

        let batch = app.batch();

        // Get the first step
        const step = await this.getStepWithIdentifier(identifier);

        // Building the body for the step transaction
        body = {
            ...body,

            // step_id: step.id,
            // process_id: step.process_id,

            // start_time: new Date(),
            // end_time: null,
        }

        // Start the step with process
        await Process.startStepBatch({ batch, step, body });

        // Once everything is completed commit
        return await batch.commit();
    }

    async startStepBatch({ batch, step }) {

    }


    /**
     * Complete Step
     */
    async completeStep({ identifier, body }) {

        let app = this.getFireStoreApp();

        let batch = app.batch();

        await this.completeStepWithIdentifier({ batch, body, identifier });

        return batch.commit();
    }


    /**
     * Complete a step with identifier
     * 
     * @param {*} identifier 
     */
    async completeStepWithIdentifier({ batch, identifier, body }) {

        console.log("Initiating step ", identifier)

        // let app = this.getFireStoreApp()

        // let batch = app.batch();

        // Get the first step
        const step = await this.getStepWithIdentifier(identifier);

        return await this.completeStepBatch({ batch, step, body });

    }


    /**
     * 
     * @returns 
     */
    async getStepWithIdentifier(identifier) {

        var queries = [{
            field: 'identifier',
            value: identifier
        }]

        const { steps = [] } = await this.get(queries)

        return {
            ...steps[0]
        }
    }

    /**
     * 
     */
    async getMatchingTransaction(identifier, record_id) {

        const step = await this.getStepWithIdentifier(identifier);

        if (step.id) {

            return await StepTransactions.getData(step.id, record_id)

        } else {

            return {}

        }


    }


    /**
     * Complete the step by identifier
     * # Note This function might need an update 
     * considering more cases that needs to be handled 
     * 
     * @param {*} identifier 
     */
    async completeStepBatch({ step, batch, body }) {

        if (step.stage === 'stop') {

            let stepTransactionRefernece = StepTransactions.getRecordReference()

            let stepParams = {
                ...body,
                step_id: step.id,
                start_time: new Date(),
                end_time: new Date(),
                completed: true
            }

            await batch.set(stepTransactionRefernece, this.appendDefaultValues(stepParams))

            // Since its a stop step , we have to update the time of the header step
            const record = await this.getRecord(step.header_id);

            // 
            return await this.completeStepBatch({ step: record, batch, body })

        } else {

            var queries = [{
                field: 'record_id',
                value: body.record_id
            }, {
                field: 'step_id',
                value: step.id
            }]

            let { step_transactions = [] } = await StepTransactions.get(queries);

            if (step_transactions.length) {

                let step_transaction = step_transactions[0];

                // 
                let stepTransactionReference = StepTransactions.getRecordReference(step_transaction.id);

                let stepParams = {
                    end_time: new Date(),
                    completed: true
                }

                return await batch.update(stepTransactionReference, this.appendDefaultValues(stepParams))

            } else {

            }



        }
    }

    // async completeStepBatch(identifier) {

    //     // Get the first step
    //     const step = steps[0];

    //     // Create step transaction record
    //     let stepTransactionReference = Steps.getRecordReference();

    //     console.log("Step Transaction Add started");

    //     let body = {
    //         step_id: step.id,
    //         process_id: step.process_id,
    //         // process_transaction_id: process_transaction_id,
    //         record_id: record_id,
    //         model_identifier: 'Candidates',

    //         start_time: new Date(),
    //         end_time: null,
    //     }

    //     return await batch.set(stepTransactionReference, this.appendDefaultValues(body));
    // }


    get columns() {

        return [

            {
                caption: 'Name',
                render: (record) => {

                    return <Link className="booking-card" to={`/candidates/${record['id']}`}>
                        {record.name}
                    </Link>


                },
                key: 'created_at',
            },


            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },

            {
                caption: 'Identier',
                field: 'identier',
                key: 'identier',
            },

        ];
    }

    Card = (record) => {

        return (<Link className="booking-card" to={`sales/${record['id']}`}>
            <div className="card">
                <h2 className="">

                    {record['amount']}

                </h2>
                <h3 className="head">

                    {record['name']}

                </h3>

                <h3 className="title">

                    {record['title']}

                </h3>

                <p className="address">
                    Booked on {record['Booking Date']}
                    <Tag color="magenta">{record['Consultant Name']}</Tag>
                </p>

            </div>
        </Link>)
    }

    DetailComponent = ({ model }) => <StepDetail model={model} />

    ModalAddComponent = ({ model, match, ...props }) => <StepAdd model={model} match={match} {...props} />

}

export default Step;