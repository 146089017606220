/**
 * @author Sameena
 * Component used to switch languages using i18n
 * For now the languages are hardcoded
 * 
 */
import React, { useState, useEffect, useContext } from "react";

import { useTranslation } from "react-i18next";
import { Select } from "../../elements";

export default function LanguageSwitcher({ }) {
    const options = [{
        field: 'en',
        label: 'English'
    },
    {
        field: 'hi',
        label: 'Hindi'
    },
    {
        field: 'ja',
        label: 'Japanese'
    }, {
        field: 'ma',
        label: 'Mongolian'
    }]

    function changeLanguage(value) {

        console.log('languageChange', value);

        i18n.changeLanguage(value)

        localStorage.setItem('selectedLanguage', value);

    }


    const { t, i18n } = useTranslation();



    return (
        <Select options={options} onSelect={changeLanguage} value={i18n.language} />
    )
}