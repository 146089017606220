
import React, { useEffect, useState, useContext } from 'react';

import { Form, Input, Button, Select } from 'antd';

import { GlobalContext } from '../../../../Store';

import { ReferenceSelect } from './../../../../elements/'

import { Models, LookupValues, LookupTypes } from '../../../../models';

import { useParams } from 'react-router-dom';

const { Option } = Select;

/**
 *
 *
 * @param {*} param0
 * @returns
 */
export default function ColumnsAdd({ model, formContent, callback, index, additional_queries, ...props }) {

    // 
    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const { user } = useContext(GlobalContext);

    const [type, setType] = useState({});

    useEffect(() => {

        getColumnTypes();

    }, []);


    async function getColumnTypes() {

        var queries = [{
            field: 'name',
            value: 'Column Types'
        }]

        const result = await LookupTypes.get(queries)

        console.log(result);
        if (result['lookup-types'].length) {

            setType(result['lookup-types'][0]);
        }
    }


    /**
     * 
     * 
     * @param {*} values 
     */
    const onSubmit = (values) => {

        console.log(values);

        additional_queries.forEach(({ field, value }) => {

            values[field] = value;

        })

        setLoading(true);

        // Only allow on angular site
        if (formContent.id) {

            model.update(formContent.id, values).then(() => {
                callback();

                setLoading(false);
            });


        } else {

            model.add(values, user).then(() => {
                callback();

                setLoading(false);

            });
        }

    };


    return (
        <section className="collection-add">
            {/* <p level={4}>Add Column</p> */}

            <Form initialValues={formContent} form={form} layout="vertical" onFinish={onSubmit}>

                {/* Caption */}
                <Form.Item label="Caption" name="caption" required>
                    <Input />
                </Form.Item>
                {/* Caption Ends */}

                {/* Column Name */}
                <Form.Item label="Field" name="field" required>
                    <Input />
                </Form.Item>
                {/* Column Name Ends */}

                {/* Column Name */}
                <Form.Item label="Description" name="description" required>
                    <Input />
                </Form.Item>
                {/* Column Name Ends */}

                {/* Model */}
                {type.id ? <Form.Item label="Column Types" name="column_type_id" required>

                    <ReferenceSelect model={LookupValues} queries={[{ field: 'lookup_type_id', value: type.id }]} />

                </Form.Item> : null}

                {/* Model Ends */}

                {/* Model */}
                <Form.Item label="Reference Model" name="reference_model_id" >

                    <ReferenceSelect model={Models} queries={[]} />

                </Form.Item>
                {/* Model Ends */}

                {/* Model */}
                <Form.Item label="Column Options" name="options" >

                    <Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" >

                    </Select>

                </Form.Item>
                {/* Model Ends */}

                <Form.Item>
                    <Button loading={loading} htmlType={'submit'} type="primary">
                        Submit
                    </Button>
                </Form.Item>
            </Form>
        </section>
    );
}



// function TagsCreator({ value, onChange }) {

//     function handleChange(value) {


//     }


//     return (<Select mode="tags" style={{ width: '100%' }} placeholder="Tags Mode" onChange={handleChange}>

//     </Select>)

// }