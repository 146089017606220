/**
 *
 * Global header component
 */

import React, { useState, useEffect, useContext } from "react";

import { GlobalContext } from "./../../Store";

import { Link, useLocation } from "react-router-dom";

import { Typography, Button, Avatar } from "antd";

import GenericHeader from "../header/generic-header";

import {
  MenuOutlined,
  UserOutlined,
  SettingOutlined,
  DatabaseOutlined,
  DoubleLeftOutlined,
} from "@ant-design/icons";

import { Row, Col, Drawer } from "antd";

import { ReloadOutlined } from "@ant-design/icons";

import SideMenu from "./../sidemenu/sidemenu";

import "./global-header.scss";

import LanguageSwitcher from "../language-switcher/language-switcher";

import { useTranslation } from "react-i18next";


const { Title } = Typography;

export default function GlobalHeader({
  loading,
  appSettings,
  children,
  isConnected,
  history,
  modules = [],
  sidemenu = [],
  reload,
  meta = {},
}) {

  let location = useLocation();
  // let location = {};

  const { isMobile, user = { locations: [] } } = useContext(GlobalContext);

  const [visible, setVisible] = useState(false);

  // Variable to handle toggling of menu
  const [collapsed, setCollapsed] = useState(false);
  // varibale handle branch switcher

  const { globalCustomerHeader = () => { } } = appSettings;

  const { t, i18n } = useTranslation();

  useEffect(() => {

    setTimeout(() => {

      i18n.changeLanguage(localStorage.selectedLanguage);

    }, 0)


  }, []);


  /**
   * Function to handle toggling of menu
   */

  const toggleCollapsed = () => {
    setVisible(true);

    collapsed === true ? setCollapsed(false) : setCollapsed(true);
  };

  /**
   * Function to remove toggling on mobile view
   */
  const hideToggle = () => {
    setVisible(true);

    setCollapsed(false);
  };

  /**
   * onClose Function
   */

  const onClose = () => {
    setVisible(false);
  };

  // const { model = {} } = menu;

  const { model: BaseModel = {}, menu = {} } = meta;

  const { model = {} } = menu;

  return (
    <div
      className={`global-header ${process.env.REACT_APP_THEME}  ${isConnected ? "connected" : ""
        }`}
      style={{ height: 10 }}
    >
      {/* <MenuOutlined style={{left:'1%',top:'1%', fontSize:18, position:'absolute', zIndex:999}} onClick={showSidebar}/> */}

      <div className="layout-content">
        <div
          className={`left-bar ${!isConnected && !isMobile ? "" : "hide"}${!collapsed ? "open" : "close"
            }`}
        >
          {!isMobile && !isConnected ? (
            <div
              className={`sidebar-container ${!collapsed ? "open" : "close"}`}
            >
              <SideMenu
                // isOpen={isOpen}
                collapsed={collapsed}
                setCollapsed={setCollapsed}
                loading={loading}
                appSettings={appSettings}
                callback={onClose}
                modules={modules}
                history={history}
              />
            </div>
          ) : (
            <Drawer
              placement="left"
              onClose={onClose}
              visible={visible}
              className="side-drawer-content"
            >
              <div
                className={`sidebar-container ${!collapsed ? "open" : "close"}`}
              >
                <SideMenu
                  // isOpen={isOpen}
                  collapsed={collapsed}
                  setCollapsed={setCollapsed}
                  loading={loading}
                  appSettings={appSettings}
                  callback={onClose}
                  modules={modules}
                  history={history}
                />
              </div>
            </Drawer>
          )}
        </div>

        {/* Right Section of the Component Loader */}
        <div className="right-section">
          {/* For not connected section */}
          {!isConnected ? (
            <div className="page-wrapper">
              {/*  */}
              <div className="page-header-name">

                <span
                  onClick={!isMobile ? toggleCollapsed : hideToggle}
                  className="toggle-box toggle-menu"
                >
                  <MenuOutlined />

                </span>

                {/* Back Button */}
                {location.pathname !== "/" ? (
                  <span
                    className="toggle-box"
                    onClick={() => {
                      history && history.goBack();
                    }}
                  >
                    <GenericHeader />
                  </span>
                ) : null}
                {/* Back Button Ends */}
                {location.pathname !== "/" ? (
                  <h3 className="menu-caption header-caption">
                    {menu.caption}
                  </h3>
                ) : null}
              </div>

              {/* Page Menu Actions */}
              {user.role || user.id ? (
                <div className="page-menu">

                  {/** branchswitcher Option  */}
                  <div className="branch-switcher">{globalCustomerHeader()}</div>


                  {/* Search Option */}

                  {/* <ModalSearch /> */}

                  {/* Search Option Ends */}

                  {/* Configurator Actions */}
                  {user.isAdmin ? (
                    <>
                      {/* Models */}
                      {menu && menu.id ? (
                        <Link to={`/menus/${menu.id}`}>
                          <Button type="secondary" size={"small"}>
                            <SettingOutlined />
                          </Button>
                        </Link>
                      ) : null}
                      {/* Models Ends */}
                    </>
                  ) : null}
                  {/* Configurator Actions Ends */}

                  {/* Reload Button */}
                  <Button onClick={reload} type="secondary" size={"small"}>
                    <ReloadOutlined />
                  </Button>

                  {/* Reload Button Ends */}

                  {/** Switch Languages starts */}
                  {process.env.REACT_APP_ENABLE_LANGUAGE_SWITCHER ? <LanguageSwitcher /> : null}
                  {/** Switch Languages ends */}

                  {/* User Profile */}
                  <div>
                    <ProfileAvatar />
                    {user.name}
                  </div>
                  {/* User Profile Ends */}
                </div>
              ) : null}

              {/* Page Menu Actions Ends */}
            </div>
          ) : null}

          {/* The children is rendered */}
          {children}
          {/* The children is rendered */}
        </div>
        {/* Right Section of the Component Loader Ends */}
      </div>
    </div>
  );
}

function ProfileAvatar() {
  const { user = { locations: [] } } = useContext(GlobalContext);

  useEffect(() => { }, []);

  return (
    <Link className="profile-avatar" to="/profile">
      {user.photograph ? (
        <>
          <img
            className="profile-picture"
            src={user.photograph}
            alt={"user photograph"}
          />
        </>
      ) : (
        <Avatar shape="square" size="small" icon={<UserOutlined />} />
      )}

      {/* {user.name} */}
    </Link>
  );
}
