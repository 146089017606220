import {
  PostData,
  GetData,
  PutData,
  PatchData,
  DeleteData,
} from "./../http/http.utils";

let headers = {};

var settings = {
  getToken: () => {},
};

export default class ApiUtils {
  /**
   * Update the settings manualy
   *
   * Used in case of branch switcher
   *
   *
   * @param {*} config
   */
  updateSettings = (config) => {
    settings = config;
  };

  static getUser = () => {
    return this.user;
  };

  static get = ({ url, config = { queries: [], order: {} }, ...props }) => {
    const { headers } = settings;

    return GetData({
      url: createUrlParams(url, config),
      settings,
      headers,
      ...props,
    }).catch((result) => {
      console.log(result);
    });
  };

  static getRecordDetail = ({ url, config = { queries: [] }, ...props }) => {
    const { headers } = settings;

    return GetData({
      url: createUrlParams(url, config),
      settings,
      headers,
      ...props,
    }).catch((result) => {
      console.log(result);
    });
  };

  static post = ({ url, formBody, ...props }) => {
    const { headers } = settings;

    return PostData({
      url,
      formBody,
      settings,
      headers,
      ...props,
    });
  };

  static put = ({ url, formBody, ...props }) => {
    const { headers } = settings;

    return PutData({
      url,
      settings,
      formBody,
      headers,
      ...props,
    });
  };

  static patch = ({ url, formBody, ...props }) => {
    const { headers } = settings;

    return PatchData({
      url,
      settings,
      formBody,
      headers,
      ...props,
    });
  };

  static delete = ({ url, formBody, ...props }) => {
    const { headers } = settings;

    return DeleteData({
      url,
      settings,
      headers,
      ...props,
    });
  };

  static upload = ({ url, data }) => {
    return fetch(process.env.REACT_APP_endpoint + url, {
      // Your POST endpoint
      method: "POST",
      headers: {
        //   'App-Type': 313,
        //   'App-Version': '1.0.1',
        Authorization: "Bearer " + localStorage.access_token,
        // type:'multipart/formData'
      },
      //   credentials: 'include',
      body: data,
    }).then(
      (result) => {
        return result.json();
      },
      (error) => {
        console.log(error);
        return error;
      },
      (progress) => {
        console.log(progress);
        return progress;
      }
    );
  };

  /**
   * Get Auth Status
   *
   * @param {*} param0
   * @returns
   */
  static getAuthStatus = ({ settings: config, token }) => {
    headers = headers;

    settings = config;

    return GetData({
      url: `auth/profile`,
      settings,

      headers,
      token
    });
  };
}

/**
 *
 */
function createUrlParams(url, config) {
  var base_url = url;

  let { queries = [], limit, order } = config;

  if (queries.length > 0 || limit > 0) {
    base_url += "?";
  }

  queries.forEach((ele) => {
    base_url += `${ele.field}=${ele.value}&`;
  });

  // Remove the last ambersent added
  if (queries.length) {
    base_url = base_url.substring(0, base_url.length - 1);
  }

  // If there is limit  , add it
  if (limit > 0) {
    base_url += `&limit=${limit}`;
  }

  if (config.includes) {
    base_url += `&includes=${config.includes}`;
  }

  if (order && order.order) {
    base_url += `&order=${order.order},${order.order_by}`;
  }

  return base_url;
}
