/**
 * Component to initialize i18n
 */

import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';


i18n
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  .use(LanguageDetector)
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init({
    debug: true,
    useSuspense: true,
    lng: localStorage.selectedLanguage || process.env.REACT_APP_LANGUAGE || 'en',
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    resources: {
      en: {
        translation: {
          description: {
            part1: 'Edit <1>src/App.js</1> and save to reload.',
            part2: 'Learn React',
            part3: "Download",
            part4: "New Appointment"
          }
        }
      },
      hi: {
        translation: {
          description: {
            part1: 'Ändere <1>src/App.js</1> und speichere um neu zu laden.',
            part2: 'Lerne React',
            part3: "डाउनलोड करना",
            part4: "नव नियुक्ति"
          }
        }
      },
      ja: {
        translation: {
          description: {
            part1: 'ثثثثثثثثثثثث',
            part2: 'ثثثثثثثثثثثث',
            part3: "ダウンロード",
            part4: "新しい予定"
          }
        }
      },
      ma: {
        translation: {
          description: {
            part1: 'afspraken',
            part2: 'ثثثثثثثثثثثث',
            part3: "татаж авах",
            part4: "Шинэ уулзалт"
          }
        }
      }
    }
  });

export default i18n;