/**
 * Tab Customizer 
 * 
 * @description Customize the tabs for the form
 * @author Ashique Mohammed
 * @version 0.0.1
 * @date 28/01/2023
 * 
 */

import React, { useEffect, useState, Fragment, useContext } from "react";

import { Input, Form, Switch, Modal } from 'antd';

import { Button } from "../../../../elements";

import { ConfirmModal } from './../../../../components/'

import { EditOutlined, DeleteOutlined } from '@ant-design/icons';

import './tab-customizer.scss';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};


/**
 * Customize the field to control its output , validity and more
 */
function TabCustomizer({ field, onFieldUpdate, onFieldRemove, index }) {

    const [form] = Form.useForm();

    // Form Initial values for configuring
    let formValues = field;

    const [visible, setVisible] = useState(false);

    /**
     * On Submit of the form
     * 
     * @param {*} values 
     */
    const onFinish = (values) => {
        console.log(values);

        onFieldUpdate(index, field, values);

        setVisible(false);
    }

    /**
     * On edit of field
     */
    const onEdit = () => {
        setVisible(true);
    }

    /**
     * On delete of field
     */
    const onDelete = () => {

        onFieldRemove(index);
    }

    return (<div className="field-customizer">

        <div className="actions">

            <Button className={"field-editor"} size="small" onClick={onEdit}>
                <EditOutlined style={{ fontSize: 12 }} />
            </Button>

            <ConfirmModal callback={onDelete} caption={<DeleteOutlined style={{ fontSize: 12 }} />}>
            </ConfirmModal>

        </div>

        <Modal
            width={'50%'}
            header={null}
            destroyOnClose
            className=""
            visible={visible}
            title={'Configure Field'}
            footer={null}
            onCancel={() => { setVisible(false); }}>

            <Form
                name="form-configurator"
                form={form}
                {...layout}
                onFinish={onFinish}
                layout="vertical"

                initialValues={{
                    ...formValues,
                    required: true
                }}>

                <Form.Item label="Caption" name="caption" required tooltip="Caption for the field">
                    <Input placeholder="Enter Caption" />
                </Form.Item>

                <Form.Item label="Required" name="required" required tooltip="Is this a mandatory field?">
                    <Switch placeholder="Select required" />
                </Form.Item>

                <Form.Item label="Field" name="field" required tooltip="Is this a mandatory field?">
                    <Input placeholder="Enter field" />
                </Form.Item>

                {/* <Form.Item> */}
                <Button htmlType="submit" type="primary">Submit</Button>
                {/* </Form.Item> */}

            </Form>
        </Modal>
    </div>)
}

export default TabCustomizer;