/**
 * Schema required for implementing process
 */

import React from "react";


import { Base } from './../';

import { Link } from 'react-router-dom'

import { Tag } from 'antd'

import StepAdd from './../steps/components/step-add/step-add';

class StepTransaction extends Base {

    constructor() {

        super()

        this.fields = [{
            field: 'name',
            caption: 'Name'
        }, {
            field: 'description',
            caption: 'Description'
        }, {
            field: 'identifier',
            caption: 'Identifier'
        }, {
            field: 'active',
            type: 'radio',
            options: [true, false],
            caption: 'Active'
        }, {
            field: 'order',
            caption: 'Order',
            type: 'number'
        }
        ]

        // this.columns = [];
    }

    get getEndpoint() {
        return 'step_transactions'
    }

    get path() {
        return `step_transactions`
    }

    get getName() {
        return `step_transactions`
    }

    /**
     * Get the matching record
     * 
     * @param {*} identifier 
     * @param {*} record_id 
     * @returns 
     */
    getData = (step_id, record_id) => {

        var queries = [{
            field: 'record_id',
            value: record_id
        }, {
            field: 'step_id',
            value: step_id
        }]

        return this.get(queries);

    }


    get columns() {
        return [

            {
                caption: 'Name',
                render: (record) => {

                    return <Link className="booking-card" to={`/candidates/${record['id']}`}>
                        {record.name}
                    </Link>


                },
                key: 'created_at',
            },


            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },

            {
                caption: 'Identier',
                field: 'identier',
                key: 'identier',
            },

        ];
    }

    Card = (record) => {

        return (<Link className="booking-card" to={`sales/${record['id']}`}>
            <div className="card">
                <h2 className="">

                    {record['amount']}

                </h2>
                <h3 className="head">

                    {record['name']}

                </h3>

                <h3 className="title">

                    {record['title']}

                </h3>

                <p className="address">
                    Booked on {record['Booking Date']}
                    <Tag color="magenta">{record['Consultant Name']}</Tag>
                </p>

            </div>
        </Link>)
    }

    // DetailComponent = ({ model }) => <ProcessDetail model={model} />

    ModalAddComponent = ({ model, match, ...props }) => <StepAdd model={model} match={match} {...props} />

}

export default StepTransaction;