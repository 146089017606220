/**
 *
 * Select Box
 */

import React, { useState, useEffect } from "react";

import { Select } from "antd";

const { Option } = Select;

import PropTypes from "prop-types";

/**
 */

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function SelectBox({
  options,
  onChange,
  value,
  label = "label",
  field = "id",

  ...componentProps
}) {
  return (
    <div className="select-box" style={{ width: "150px" }}>
      <Select
        style={{ width: "100%" }}
        onChange={onChange}
        value={value}
        {...componentProps}
      >
        {options.map((option, key) => (
          <Option key={key} value={option[field]}>
            {option[label]}
          </Option>
        ))}
      </Select>
    </div>
  );
}

SelectBox.propTypes = {
  /** An array of objects representing the options for the select box. Each object in the array should have a label and a value property. */
  options: PropTypes.array.isRequired,
  /** A function that will be called whenever the value of the select box changes. The new value of the select box will be passed as an argument to this function. */
  onChange: PropTypes.func.isRequired,
  /** The current value of the select box. This value should correspond to the value property of one of the objects in the options array. */
  value: PropTypes.string.isRequired,
  /** A string representing the label for the select box. This will be displayed above the select box. */
  label: PropTypes.string,
  /** A string representing the name of the field in each object of the options array that should be used as the value of the select box. By default, this is set to "id". */
  field: PropTypes.string,
}

export default SelectBox;
