/**
 * Component used for Table Actions
 * 
 * @author Ashique
 * 
 * Ensure to follow a minimal standard
 */

import React, { useState, useEffect } from 'react';

import { CustomActions } from '../../..';

import { TableField } from '../table-data/table-data';

import './table-actions.scss';


export default function TableActions({ columns = [], records = [], actions }) {

    return (<>

        {/* Actions Table */}
        <div className="actions-table">
            <table striped className="sortable">
                <thead>
                    <tr>
                        <th className="action-header">
                            <span className="fa fa-cog fa-lg">
                                #
                            </span>
                        </th>
                    </tr>
                </thead>
                <tbody>
                    {
                        records.map((record, rowKey) => (
                            <tr className="table-row" key={rowKey}>

                                {
                                    actions.map((action) => {

                                        return <TableField row={record} column={action} />

                                    })
                                }

                                {/* <CustomActions record={record} /> */}
                            </tr>
                        ))
                    }
                </tbody>
            </table>
        </div>
        {/* Actions Table Ends */}

    </>)
}

