import React, { useState, useEffect, useContext } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

/**
 *
 * Routes defined for generic workflow
 *
 * @param {*} param0
 */
export default function TaskRoutes({ steps, list: List, form: Form }) {
    return (
        <div>
            {steps.map((step, key) => {
                return step.submenus && step.submenus.length ? (
                    step.submenus.map((submenu) => (
                        <Route
                            key={key}
                            exact
                            path={`${submenu.path}`}
                            component={({ ...routeParams }) =>
                                submenu.isForm ? <Form {...routeParams} step={submenu} /> : <List {...routeParams} step={submenu} />
                            }
                        />
                    ))
                ) : (
                    <RouteConfig key={key} list={List} form={Form} config={step} />
                );

                {
                    /* return step.submenus && step.submenus.length ? step.submenus.map((submenu) => <RouteConfig list={List} form={Form} config={submenu} />) : <RouteConfig list={List} form={Form} config={step} />; */
                }
            })}
        </div>
    );
}

function RouteConfig({ config, list: List, form: Form }) {
    return (
        <Route
            path={`${config.path}`}
            component={({ ...routeParams }) => (config.isForm ? <Form {...routeParams} step={config} /> : <List {...routeParams} step={config} />)}
        />
    );
}
