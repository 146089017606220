/**
 * Schema required for implementing process
 */

import React from "react";


import Base from './../base';

import { Link } from 'react-router-dom'

import { Tag } from 'antd'

// import StepAdd from './components/step-add/step-add';

class Checklist extends Base {

    constructor() {

        super()

        this.fields = [{
            field: 'title',
            caption: 'Title'
        }, {
            field: 'description',
            caption: 'Description'
        }, {
            field: 'active',
            type: 'boolean',
            options: [true, false],
            caption: 'Active'
        }, {
            field: 'order',
            caption: 'Order',
            type: 'number'
        }]

        // this.columns = [];
    }

    get getEndpoint() {
        return 'checklists'
    }

    get path() {
        return `checklists`
    }

    get getName() {
        return `checklists`
    }


    get columns() {
        return [

            {
                caption: 'Title',
                field: 'title',
                key: 'title',
            },

            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },

            {
                caption: 'Identier',
                field: 'identier',
                key: 'identier',
            },

        ];
    }

    Card = (record) => {

        return (<Link className="booking-card" to={`sales/${record['id']}`}>
            <div className="card">
                <h2 className="">

                    {record['amount']}

                </h2>
                <h3 className="head">

                    {record['name']}

                </h3>

                <h3 className="title">

                    {record['title']}

                </h3>

                <p className="address">
                    Booked on {record['Booking Date']}
                    <Tag color="magenta">{record['Consultant Name']}</Tag>
                </p>

            </div>
        </Link>)
    }

    // DetailComponent = ({ model }) => <ProcessDetail model={model} />

    // ModalAddComponent = ({ model, match, ...props }) => <StepAdd model={model} match={match} {...props} />

}

export default Checklist;