/**
 * 
 * Component for antd select
 *
 */

import React, { useState, useEffect } from 'react';

import { Select as AntdSelect} from 'antd';

const { Option } = AntdSelect;

/**
 */

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function Select({
    options,

    onSelect,
    value, 

    label = "label",
    field = 'id',

    ...componentProps
}) {

    return (
        <div className="select-box" style={{ width: '150px' }}>
            <AntdSelect style={{ width: '100%' }} onSelect={onSelect} value={value} {...componentProps}>
                {options.map((option, key) => (
                    <Option key={key} value={option.field}>
                        {option[label]}
                    </Option>
                ))}
            </AntdSelect>
        </div>
    );
}

export default (Select);