/**
 *  @author Sameena
 * Coponent for ant Tab
 */

import { Tabs } from "antd";
import TabPane from 'rc-tabs/lib/TabPanelList/TabPane'
const TabsType = 'line' | 'card' | 'editable-card';
const TabsPosition = 'top' | 'right' | 'bottom' | 'left';
const Tab = Object.assign(Tabs, {
          TabPane,
          TabsType,
          TabsPosition
})
export default Tab