/**
 * This is one different level of component moulding that has added 
 * for his 
 */

import React, { useEffect, useState } from 'react';

import { Route, Redirect } from 'react-router-dom';

import { Typography } from 'antd';

import FingerPrintReader from './../../../components/finger-print-reader/finger-print-reader';

import './fingerprint-protected.scss';

import { Button } from './../../../elements/'

const { Title } = Typography;

export default function FingerprintProtected({ children, template, menu, onVerification, authorized, completed, ...rest }) {

  const [loading, setLoading] = useState(false);

  const [access, setAccess] = useState(null);

  useEffect(() => {

  }, [])

  /**
   * On Update of result from reader
   */
  const onUpdate = async (json) => {

    setLoading(true);

    await onVerification(json);

    setAccess(json);

    setLoading(false);

    // onVerification(json)
  }

  let enableReader = false;

  if (menu.finger_print_protected && !access) {

    enableReader = true;

  }

  if (completed) {
    enableReader = false;
  }

  if (authorized) {
    enableReader = false;
  }

  /**
   * Override the 
   */
  const override = async () => {

    setLoading(true);

    await onVerification({})

    setAccess({});

    setLoading(false);

  }

  return (
    <Route
      {...rest}
      render={(props) => {

        return !enableReader
          ?

          React.cloneElement(children,
            {
              access,
              ...props
            })
          :
          <>

            <Title level={3}>
              {menu.name}
            </Title>

            <p>
              You are trying to access a fingerprint protected page .
            </p>


            <div className='fingerprint-protected card'>

              <FingerPrintReader template={template} onChange={onUpdate} scanMode={true} />

              {/* Override the FingerPrint */}
              <Button loading={loading} className={'override-button'} onClick={override}>
                Override
              </Button>
              {/* Override the FingerPrint */}

            </div>
          </>

      }}
    />
  )
}
