/**
 * Generic Detail accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Descriptions, Skeleton, Tabs, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom';

import './lookup-detail.scss';

import GenericList from '../../../../modules/generic/generic-list/generic-list';

import { ReloadOutlined, OrderedListOutlined, PicCenterOutlined, CaretLeftOutlined, CaretRightOutlined } from '@ant-design/icons';

//  import useDeviceDetect from './../../../../../hooks/device-detect';

import { GlobalContext } from '../../../../Store';

import { MenuRoles, LookupValues } from '../../../../../core/models';

const { Title } = Typography;

const { TabPane } = Tabs;

/**
 * Generic List
 *
 * @param {*} param0
 */
function LookupDetail({ match, model, columns: cols, schema = { custom_actions: [] }, disableEdit = schema.disableEdit }) {

    const [record, setRecords] = useState([]);

    const { params } = match;

    console.log(params);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();

        console.log('hello');
    }, []);

    const loadData = () => {
        setLoading(true);

        getRecords(schema);
    };

    const getRecords = (schema) => {
        // Get the model by queries
        return model.getRecord(match.params.id, model).then((result) => {
            setRecords(result);

            setLoading(false);
        });
    };

    function refresh() {
        loadData();
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model.name}</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        {disableEdit ? null : (
                            <Space size="middle">
                                <Button type="dashed">
                                    <Link to={`/${model.name}/${match.params.id}/e/`}>Edit</Link>
                                </Button>
                            </Space>
                        )}
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    <div className="card">
                        <Descriptions title="Detail">
                            {Object.keys(record).map((field) => {

                                if (record[field]) {

                                    if (typeof record[field] === 'string' || typeof record[field] === 'number') {
                                        return <Descriptions.Item label={field}>{record[field]}</Descriptions.Item>;
                                    } else if (typeof record[field] === 'object') {
                                        let nested = record[field];

                                        Object.keys(record[field]).map((entry) => {
                                            return <Descriptions.Item label={entry}>{record[field][entry]}</Descriptions.Item>;
                                        });
                                    } else {
                                        console.log(field, record[field]);
                                    }

                                }

                            })}

                            {/* {cols.map((column) => <Descriptions.Item label={column.caption}>{record[column.field]}</Descriptions.Item>)} */}
                        </Descriptions>
                    </div>

                    <Tabs>
                        <TabPane tab="Lookup Values" key="0">
                            <GenericList
                                model={LookupValues}
                                additional_queries={[
                                    {
                                        field: 'lookup_type_id',
                                        value: params.id,
                                    },
                                ]}
                            />
                        </TabPane>
                    </Tabs>
                </>
            )}
        </section>
    );
}

export default withRouter(LookupDetail);
