/**
 *  @author Sameena
 * Component for antd Radio
 */

import React from "react";


import { Radio } from 'antd';
import Group from 'antd/lib/radio/group';
import Button from 'antd/lib/radio/radioButton';


const AntdRadio=Object.assign(Radio,{
  Group,
  Button
})
export default AntdRadio;
