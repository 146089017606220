/**
 * Generic Detail accepts an array of fields to update any resource
 */

import React, { useState, useEffect } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Row, Skeleton, Tabs, Space, Tag } from 'antd';

import { withRouter } from 'react-router-dom';

import './model-detail.scss';

import GenericList from './../../../../modules/generic/generic-list/generic-list';

import { ReloadOutlined } from '@ant-design/icons';

import { Columns } from '../../../../../core/models';

const { Title } = Typography;

const { TabPane } = Tabs;

/**
 * Generic List
 *
 * @param {*} param0
 */
function ModelDetail({ match, model, columns: cols, schema = { custom_actions: [] }, disableEdit = schema.disableEdit }) {

    const [record, setRecords] = useState([]);

    const { params } = match;

    console.log(params);

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        loadData();

        console.log('hello');
    }, []);

    const loadData = () => {
        setLoading(true);

        getRecords(schema);
    };

    const getRecords = (schema) => {
        // Get the model by queries
        return model.getRecord(match.params.id, model).then((result) => {
            setRecords(result);

            setLoading(false);
        });
    };

    function refresh() {
        loadData();
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model.name}</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>

                        {disableEdit ? null : (
                            <Space size="middle">
                                <Button type="dashed">
                                    <Link to={`/${model.name}/${match.params.id}/e/`}>Edit</Link>
                                </Button>
                            </Space>
                        )}
                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {loading ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    <div className="card">

                        <div gutter={0} className="detail-wrapper">


                            <div className="detail-element">
                                <span>Name</span>
                                <Row span={6}>
                                    <h3>{record.name}</h3>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <span>Description</span>
                                <Row span={6}>
                                    <h3>{record.description}</h3>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <Tabs>
                        <TabPane tab="Columns" key="0">
                            <GenericList
                                index={'model_id'}
                                model={Columns}
                                additional_queries={[
                                    {
                                        field: 'model_id',
                                        value: params.id,
                                    },
                                ]}
                            />
                        </TabPane>
                    </Tabs>
                </>
            )}
        </section>
    );
}

export default withRouter(ModelDetail);
