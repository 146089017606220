
import React, { useState, useEffect, useContext } from "react";

import { Link } from 'react-router-dom'

import { Typography, Statistic, Badge, Empty } from "antd";

import { ProcessTransactions, Steps } from "../../../../models";

import { GlobalContext } from './../../../../Store';

const { Title } = Typography;

export default function TaskOverview({ steps = [] }) {

    const { user } = useContext(GlobalContext);

    const [tasks, setTasks] = useState([]);

    useEffect(() => {

        // Load all the steps for this role 
        loadSteps(user);

    }, [])

    /**
     * Load the steps 
     */
    function loadSteps(user) {

        var queries = [{
            field: 'role_id',
            operator: 'array-contains',
            value: user.role_id
        }, {
            field: 'active',
            value: true
        }]

        Steps.get(queries, { orderBy: 'order' }).then((result) => {

            setTasks(result.steps);

            // setTasks(Steps.orderArray(result.steps, 'order'));
        })
    }

    return (
        <section className="dashboard">

            {tasks.length ? <>

                <div className="list-header">

                    <Title level={4}>Task Overview</Title>

                </div>

                <>

                    <div gutter={16} className="score">

                        {
                            steps.sort((record) => record.order).map((step, key) => {

                                return <Score step={step} key={key} />

                            })
                        }

                    </div>

                </>
            </>
                :
                <Empty description={false} />
            }


        </section>
    );
}



function Score({ step, url }) {

    const [records, setRecords] = useState([]);

    const [loader, setLoader] = useState(true);

    useEffect(() => {

        getData();

        return () => {

        }

    }, [])

    /**
     * 
     */
    function getData() {

        setLoader(true);

        var queries = [{
            field: 'step_id',
            value: step.id
        }];

        ProcessTransactions.get(queries).then((result) => {

            setRecords(result.process_transactions);

        })
    }

    return (<Link className="card dashboard-item" to={`/list/${step.id}`}>

        <Badge status={'success'} />

        <Statistic className="" title={step.name} value={records.length} />

        {/* <small>
            {step.description}
        </small> */}

    </Link>)
}


// import React, { useState, useEffect, useContext } from "react";

// import { Link } from 'react-router-dom'

// import { Typography, Statistic, Tabs, Badge } from "antd";

// import { GlobalContext } from './../../../../Store';

// import FirebaseUtils from './../../../../utils/firebase.utils';

// import { Steps } from "../../../../models";

// const { TabPane } = Tabs;

// const { Title } = Typography;


// export default function TaskOverview({ steps = [] }) {

//     const { user } = useContext(GlobalContext)

//     const [tasks, setTasks] = useState();

//     useEffect(() => {

//         // Load all the steps for this role 
//         loadSteps();

//     }, [])

//     /**
//      * Load the steps 
//      */
//     function loadSteps() {

//         Steps.get().then((result) => {

//             setTasks(result.steps);
//         })
//     }


//     return (
//         <section className="dashboard">

//             {steps.length ? <>

//                 <div className="list-header">

//                     <Title level={4}>Task Overview</Title>

//                 </div>

//                 <Tabs defaultActiveKey="0">

//                     {
//                         user.locations.map((location, index) => {

//                             return (
//                                 <TabPane tab={`${location} Customers`} key={index}>

//                                     <>

//                                         <div gutter={16} className="score">


//                                             {
//                                                 steps.filter((step) => !step.isForm).map((step, key) => {

//                                                     return step.submenus && step.submenus.length ? step.submenus.map((submenu) => <Score url={location} step={submenu} />) : <Score url={location} step={step} />;

//                                                 })
//                                             }

//                                         </div>

//                                     </>
//                                 </TabPane>
//                             )
//                         })
//                     }

//                 </Tabs>


//             </> : null}






//         </section>
//     );
// }



// function Score({ step, url }) {


//     const { user } = useContext(GlobalContext)

//     const [requests, setRequests] = useState({ all: {}, requests: {} });

//     const [loader, setLoader] = useState(true);


//     useEffect(() => {

//         getData();

//         return () => {

//         }

//     }, [])


//     function getData() {

//         // console.log(user);

//         setLoader(true);

//         var queries = [];

//         if (typeof step.queries === "function") {

//             queries = step.queries({ user });

//         } else {

//             queries = step.queries;

//         }


//         FirebaseUtils.getRecords(queries, step.model || 'requests').then((result) => {

//             setLoader(false);

//             setRequests(result[step.model.name || 'requests']);

//         });

//     }




//     return (<Link className="card dashboard-item" to={`${step.path}`}>

//         <Badge status={'success'} />

//         <Statistic className="" title={step.caption} value={requests.length} />

//         {/* <small>
//             {step.description}
//         </small> */}

//     </Link>)


// }