/**
 * Common Profile Page
 */

import React, { useState, useEffect, useContext } from 'react';

import { GlobalContext } from './../../Store';

import { Menu, Divider, message, Select as ReactSelect, Space, Dropdown, Modal, Form } from 'antd';

import FileUpload from '../../components/file-upload/file-upload';

import { Typography } from 'antd';

import { Tag } from 'antd';

import FirebaseUtils from '../../utils/firebase.utils';
//  import ApiUtils from '../../../../utils/api.utils';

import './profile.scss';

import { Tabs } from 'antd';

import { Avatar } from 'antd';

// import GLOBAL from './../global';

const { SubMenu } = Menu;

const { Title, Text } = Typography;

const { Option } = ReactSelect;

const layout = {
    labelCol: {
        xs: { span: 8 },
        sm: { span: 8 },
        lg: { span: 8 },
    },
    wrapperCol: {
        xs: { span: 16 },
        sm: { span: 16 },
        lg: { span: 16 },
    },
};

const { TabPane } = Tabs;

export default function Profile({ children, model, history, modules = [], sidemenu = [], ...props }) {

    const { user } = useContext(GlobalContext);

    useEffect(() => { }, []);

    console.log(user);

    function onProfileChange() { }

    /**
     *
     * @param {*} caption
     * @param {*} attachments
     */
    function onUpload(file) {
        var params = {
            photograph: file.url,
        };

        console.log(file);

        // if (['png', 'jpeg', 'jpg'].indexOf(file.type) !== -1) {
        FirebaseUtils.updateUserInfo(user.id, params).then(() => {
            message.success('Your profile has been affected');

            window.location.reload(true);
        });
        // } else {
        //     message.error('File type not supported');
        // }

        // setDisabled(false);

        // form.setFieldsValue({ [caption]: attachments });
    }

    return (
        <div className="profile card">
            <Title level={3}>Your Profile</Title>

            <Divider></Divider>

            <div className="profile-headline">
                {/* <div>
                    {!user.photograph ? (
                        <div className="avatar-selector" onClick={onProfileChange}>
                            <FileUpload
                                maxSize={1}
                                callback={onUpload}
                                onProgress={() => {
                                }}
                                accept={'image/*'}
                            />
                        </div>
                    ) : (
                        <>
                            <img className="profile-picture" src={user.photograph} alt={'user photograph'} />

                            <FileUpload
                                caption="Edit"
                                listType="text"
                                maxSize={1}
                                callback={onUpload}
                                accept={'image/*'}
                                onProgress={() => {
                                }}
                            />
                        </>
                    )}
                </div> */}

                <Title level={3}>{user.name}</Title>
            </div>

            <Divider></Divider>

            {/* <p className="page-desc">{loader ? '. Loading data' : ``}</p> */}

            <div className="section-content">
                <div className="section-row">
                    <div className="section-label">
                        <Text type="secondary">Name</Text>
                    </div>
                    <div className="section-value">
                        <strong>{user.name}</strong>
                    </div>
                </div>

                <div className="section-row">
                    <div className="section-label">
                        <Text type="secondary">Role</Text>
                    </div>
                    <div className="section-value">
                        <strong>{user.role && user.role.name}</strong>
                    </div>
                </div>

                <div className="section-row">
                    <div className="section-label">
                        <Text type="secondary">Phone</Text>
                    </div>
                    <div className="section-value">
                        <strong>{user.phone}</strong>
                    </div>
                </div>

                <div className="section-row">
                    <div className="section-label">
                        <Text type="secondary">Email</Text>
                    </div>
                    <div className="section-value">
                        <strong>{user.email}</strong>
                    </div>
                </div>

                {/* <div className="section-row">
                    <div className="section-label">
                        <Text type="secondary">Role</Text>
                    </div>
                    <div className="section-value">
                        <strong>{user.role.name}</strong>
                    </div>
                </div>  */}
                {/* <div className="section-row">
                    <div className="section-label">
                        <Text type="secondary">Location</Text>
                    </div>
                    <div className="section-value">
                        <div className="locations">
                            {user.locations.map((loc, key) => (
                                <Tag color="blue" key={key}>
                                    {loc}
                                </Tag>
                            ))}
                        </div>
                    </div>
                </div> */}


                {/* <div className="section-row">
                    <div className="section-label">
                        <Text type="secondary">Location</Text>
                    </div>
                    <div className="section-value">
                        <div className="locations">
                        </div>
                    </div>
                </div>  */}

                {/* <div className="section-row">
                     <div className="section-label">
                         <Text type="secondary">Subscribe for Notifications</Text>
                     </div>
                     <div className="section-value">
                         <strong><Switch checkedChildren="Yes" unCheckedChildren="No" /></strong>
                     </div>
                 </div> */}
            </div>
        </div>
    );
}
