import React, { useEffect, useState, useContext } from 'react';

import { Form, Input, Button, Select, Typography, Skeleton, Radio, InputNumber } from 'antd';

import { GlobalContext } from '../../../../Store';

import { Steps, Process, Forms, Pages, Roles } from '../../../../models/';

const { Title } = Typography;

const { Option } = Select;

export default function StepAdd({ model, edit, history, additional_queries = [], formContent = {}, callback, ...props }) {

    const [loading, setLoading] = useState(false);

    const [form] = Form.useForm();

    const [process, setProcess] = useState([]);

    const [steps, setSteps] = useState([]);

    const { user } = useContext(GlobalContext);

    const [forms, setForms] = useState([]);

    const [pages, setPages] = useState([]);

    const [roles, setRoles] = useState([]);

    useEffect(() => {

        getProcess();

        getSteps();

        getForms();

        getPages();

        getRoles();

    }, []);

    /**
     * Load the Process
     */
    const getProcess = () => {

        Process.get([]).then((result) => {
            // console.log(result);

            setProcess(result.process);
        });
    };

    /**
     * Load the Sub Process
     */
    const getSteps = () => {

        model.get([]).then((result) => {
            // console.log(result);

            setSteps(result.steps);
        });
    };

    const getForms = () => {

        Forms.get().then((result) => {

            setForms(result.forms);
        })
    }

    const getPages = () => {

        Pages.get().then((result) => {

            setPages(result.pages);
        })
    }

    const getRoles = () => {

        Roles.get().then((result) => {

            setRoles(result.roles);
        })
    }

    const onSubmit = (values) => {
        // console.log(values);

        setLoading(true);

        // Add the mapping id
        additional_queries.forEach(({ field, value }) => {

            values = {
                ...values,
                [field]: value
            }
        })

        // Params
        if (formContent.id) {
            // Update of model
            model.update(formContent.id, values, user).then(() => {
                // Location.back();

                callback();

            });
        } else {
            // add new model
            model.add(values, user).then(() => {
                // Location.back();
                callback();

            });
        }
    };

    return (
        <section className="collection-add">
            <Title level={4}>{edit ? 'Edit' : 'Add'} Step</Title>

            {loading ? (
                <Skeleton />
            ) : (
                <Form initialValues={formContent} form={form} layout="vertical" onFinish={onSubmit}>

                    {/* Name */}
                    <Form.Item name="name" label="Name" required>
                        <Input placeholder="Enter name" />
                    </Form.Item>
                    {/* Name Ends */}

                    {/* Description */}
                    <Form.Item name="description" label="Description" required>
                        <Input placeholder="Enter description" />
                    </Form.Item>
                    {/* Description Ends */}

                    {/* Identifier */}
                    <Form.Item name="identifier" label="Identifier" required>
                        <Input placeholder="Enter identifier" />
                    </Form.Item>
                    {/* Identifier Ends */}

                    {/* Active */}
                    <Form.Item label="Active" name="active" required>
                        <Radio.Group style={{ width: '100%' }}>
                            {[true, false].map((model) => (
                                <Radio value={model}>{model ? 'Yes' : 'No'}</Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    {/* Active Ends */}


                    {/* Start/Stop */}
                    <Form.Item label="Stage" name="stage">
                        <Radio.Group style={{ width: '100%' }}>
                            {['start', 'stop'].map((model) => (
                                <Radio value={model}>{model}</Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    {/* Start/Stop Ends */}

                    {/* Order */}
                    <Form.Item name="order" label="Order" required>
                        <InputNumber placeholder="Enter order" />
                    </Form.Item>
                    {/* Order Ends */}

                    {/* Model Identifier */}
                    <Form.Item name="model_identifier" label="Moder Identifier" required>
                        <Input placeholder="Enter Model Identifier" />
                    </Form.Item>
                    {/* Model Identifier Ends */}

                    {/* Submit Model Identifier */}
                    <Form.Item name="submit_model_identifier" label="Submit Moder Identifier">
                        <Input placeholder="Enter Submit Model Identifier" />
                    </Form.Item>
                    {/* Submit Model Identifier Ends */}

                    {/* Type */}
                    <Form.Item name="type" label="Type" required>
                        <Radio.Group style={{ width: '100%' }}>
                            {['hierarchical', 'parallel'].map((model) => (
                                <Radio value={model}>{model}</Radio>
                            ))}
                        </Radio.Group>
                    </Form.Item>
                    {/* Order Ends */}

                    {/* Process */}
                    <Form.Item label="Trigger Process (On Completion)" name="trigger_process_ids">
                        <Select style={{ width: '100%' }} mode="multiple">
                            {process.map((record) => (
                                <Option value={record.id}>{record.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* Process Ends */}

                    {/* Sub Process Ids */}
                    <Form.Item label="Trigger Sub Process (On Completion)" name="trigger_sub_process_ids">
                        <Select style={{ width: '100%' }} mode="multiple">
                            {steps.map((record) => (
                                <Option value={record.id}>{record.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* Sub Process Ids Ends */}

                    {/* Forms */}
                    <Form.Item label="Form" name="form_id">
                        <Select style={{ width: '100%' }}>
                            {forms.map((record) => (
                                <Option value={record.id}>{record.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* Forms Ends */}

                    {/* Pages */}
                    <Form.Item label="Page" name="page_id">
                        <Select style={{ width: '100%' }} allowClear>
                            {pages.map((record) => (
                                <Option value={record.id}>{record.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* Pages Ends */}

                    {/* Pages */}
                    <Form.Item label="Role" name="role_id">
                        <Select mode="multiple" style={{ width: '100%' }}>
                            {roles.map((record) => (
                                <Option value={record.id}>{record.name}</Option>
                            ))}
                        </Select>
                    </Form.Item>
                    {/* Pages Ends */}

                    <Form.Item>
                        <Button loading={loading} htmlType={'submit'} type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </section>
    );
}
