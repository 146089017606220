/**
 *
 * Sidemenu component
 * 
 * @author Ashique Mohammed
 * @co-author Sneha 
 * 
 * 
 */

import React, { useState, useEffect, useContext } from "react";

import { GlobalContext } from "./../../Store";

import { Menu, message, Skeleton } from "antd";

import FirebaseUtils from "./../../utils/firebase.utils";

import { useHistory } from "react-router-dom";

import { useTranslation, Trans } from 'react-i18next';

import { Menus } from "../../models";

import "./sidemenu.scss";

const { SubMenu } = Menu;


/**
 * 
 * @param {*} collapsed 
 * @param {*} icon 
 * @param {*} caption 
 * @returns 
 */
function CollapsedIconMenu({ menu, collapsed, icon, caption }) {

  console.log('image_path', menu);

  // Import t and i18n from useTranslation
  const { t, i18n } = useTranslation();

  return (

    <>

      {/* If value of collapsed is false show caption & icon else hiding caption and showing only icon*/}
      {!collapsed ? (

        <div className="menu-collapsed">

          <div>

            {
              menu && menu.image_path ?
                <img style={{ width: '25px' }} src={menu.image_path}></img>
                :
                <i className={`fa-solid ${icon}`} />
            }

          </div>

          <div>

            <span className="caption">

              {/* {caption} */}
              {/* <Trans i18nKey="Appointments"></Trans> */}
              {t(`${caption}`)}

            </span>

          </div>

        </div>

      ) : (

        <div className="menu-collapsed">

          <span className="anticon">

            {
              menu && menu.image_path ?
                <img style={{ width: '25px' }} src={menu.image_path}></img>
                :
                <i className={`fa-solid ${icon}`} />
            }

          </span>

          <span style={{ paddingLeft: '6px' }}>

            {/* <>{caption}</> */}
            {t(`${caption}`)}

          </span>

        </div>

      )}

    </>

  )

}

export default function SideMenu({
  loading,
  modules = [],
  callback,
  appSettings,
  collapsed,
}) {
  const { brandLogo, footerLogo, renderCustomHeader = () => { } } = appSettings;

  let history = useHistory();

  const [selected, setSelected] = useState([1]);

  const [menu, setMenu] = useState({});

  const { user = { locations: [] }, dispatch } = useContext(GlobalContext);

  useEffect(() => {
    // Here we have to consider three cases now ,
    // One is firebase for which it is primarly designed for
    // Then Nura Old Schema
    // Saudi New Schema
    // Bringing all to one structure is the ideal
    // if (user.role || user.ID) {
    //     setLoading(false);
    // }
  }, [user]);

  /**
   * Logout Function
   */
  async function handleLogout() {
    
    let dbPtrValue = appSettings.headers.db_ptr;

    localStorage.clear();

    if (process.env.REACT_APP_PRIMARY_BACKEND === "SQL") {

      // const result = Users.logout()
      localStorage.clear();
      
      localStorage.setItem('db_ptr', dbPtrValue);

      let userInfo = {
        dms: {},
        locations: [],
        ...{ loggedCheckDone: true },
      };

      dispatch({ type: "user", payload: userInfo });

      history.push("/login");

      message.success("You have logged out successfully.");
    } else {
      FirebaseUtils.logout().then(() => {
        history.push("/login");

        message.success("You have logged out successfully.");
      });
    }
  }

  /**
   * Menu Click Function
   */

  const onMenuClick = (menu, index) => {
    setSelected([index]);

    history.push(menu.path);

    setMenu(menu);

    callback();
  };
  /**
   * Function renders the footer logo
   *
   * @param {*} footerLogo
   */
  function renderFooter(footerLogo) {
    return (
      <div className={`sidebar-footer ${!collapsed ? "open" : "close"}`}>
        <img className="footer-logo" src={footerLogo} alt={"footer-logo"} />
      </div>
    );
  }

  let icon;

  return (
    <div className="sidemenu">

      {/* Intro Component */}
      <div className="intro">
        {/* Logo Bar */}
        <div className="logo-wrapper">
          {/* Changing the size of logo bar according to the value of collapsed */}
          <img
            className={`logo-welcome ${!collapsed ? "open" : "close"}`}
            onClick={() => {
              history.push("/");
            }}
            src={brandLogo}
            alt="Logo"
          />

          {/* <button class="openbtn" onclick={()=>{openNav()}}>☰</button>   */}

          {/* If value of collapsed is true show version else not showing */}

          {!collapsed ? (
            <small>{process.env.REACT_APP_package_version}</small>
          ) : (
            ""
          )}
        </div>
        {/* Logo Bar Ends */}

        {/* If value of collapsed is true render header else not rendering */}

        {!collapsed ? renderCustomHeader() : ""}

      </div>
      {/* Intro Component Ends */}


      {/* Search for Queries */}

      {/* <div className="menu-search">
 
                 <small level={5}>Search</small>
 
                 <Input placeholder="Search" />
             </div> */}

      {/* Search for Queries Ends */}

      <div
        className="menu-item"
      >
        {loading ? (
          <Skeleton />
        ) : (
          <Menu
            // selectedKeys={[selected]}
            // style={{ width: 256 }}
            // defaultSelectedKeys={selected}
            // defaultOpenKeys={['']}
            inlineCollapsed={collapsed}
            mode="inline"
            theme={process.env.REACT_APP_THEME}
          // theme={''}
          >

            {/* <Menu.Item
              onClick={() => {
                setSelected([1]);
                history.push("/");
                setMenu({ caption: "Home" });
                callback();
              }}
              key="home-menu"
            >
              If value of collapsed is true show caption & icon else hiding caption and showing only icon

              <CollapsedIconMenu
                menu={menu}
                caption="Home"
                icon="fa-solid fa-house"
                collapsed={collapsed}
              />
            </Menu.Item> */}


            {/* {
              Menus.screenMenus(modules).map((menu, index) => {

                return <MenuItem history={history} menu={menu} index={index} />
              })
            } */}

            {Menus.screenMenus(modules, "order")

              .filter((record) => {
                icon = record;

                if (record.id) {
                  if (record.is_visible) {
                    return true;
                  }

                  if (record.icon_name) {
                    return true;
                  } else {
                    return false;
                  }
                } else {
                  return true;
                }
              })
              .map((menu, index) => {
                // return <MenuItem menu={menu} index={index} />

                let sub_menus =
                  menu && menu.sub_menus
                    ? Menus.screenMenus(menu.sub_menus)
                    : [];

                if (menu && sub_menus && sub_menus.length) {

                  let randomIndex = parseInt(Math.random() * 10000000000);

                  return (
                    <SubMenu

                      className="popup"

                      key={`first-level-${randomIndex}-${menu.caption}`}

                      title={

                        <>

                          <CollapsedIconMenu

                            menu={menu}

                            caption={menu.caption}

                            icon={menu.icon_name || 'fa-solid fa-sliders'}

                            collapsed={collapsed}

                          />

                        </>

                      }

                    >
                      {sub_menus.map((submenu, innerIndex) => {
                        let randomIndex = parseInt(Math.random() * 10000000000);

                        let third_menus =
                          submenu && submenu.sub_menus
                            ? Menus.screenMenus(submenu.sub_menus)
                            : [];

                        if (third_menus && third_menus.length) {
                          return (
                            <SubMenu

                              className="popup"

                              key={`second-level-${randomIndex}-${submenu.id}`}

                              title={

                                <span>

                                  <CollapsedIconMenu

                                    menu={menu}

                                    caption={submenu.caption}

                                    icon={submenu.icon_name || 'fa-solid fa-sliders'}

                                    collapsed={collapsed}

                                  />

                                </span>
                              }
                            >
                              {third_menus.map((menu) => {
                                let randomIndex = parseInt(
                                  Math.random() * 10000000000
                                );

                                return (
                                  <Menu.Item
                                    onClick={() => {
                                      onMenuClick(menu, index);
                                    }}
                                    key={`second-level-${randomIndex}-${index}`}
                                  >

                                    <CollapsedIconMenu

                                      menu={menu}

                                      caption={menu.caption}

                                      icon={menu.icon_name || 'fa-solid fa-sliders'}

                                      collapsed={collapsed}

                                    />

                                  </Menu.Item>
                                );
                              })}
                            </SubMenu>
                          );
                        } else {
                          let randomIndex = parseInt(
                            Math.random() * 10000000000
                          );

                          return (
                            <Menu.Item
                              onClick={() => {
                                onMenuClick(submenu, index);
                              }}
                              key={`first-level-${randomIndex}-${innerIndex}`}
                            >

                              <CollapsedIconMenu

                                menu={menu}

                                caption={submenu.caption}

                                icon={submenu.icon_name || 'fa-solid fa-sliders'}

                                collapsed={collapsed}

                              />

                            </Menu.Item>

                          );

                        }

                      })}

                    </SubMenu>

                  );
                } else {
                  let randomIndex = parseInt(Math.random() * 10000000000);

                  return (
                    <Menu.Item
                      onClick={() => {
                        onMenuClick(menu, index);
                      }}
                      key={`${menu.id}-${randomIndex}`}
                    >

                      <CollapsedIconMenu

                        menu={menu}

                        caption={menu.caption}

                        icon={menu.icon_name || 'fa-solid fa-sliders'}

                        collapsed={collapsed}

                      />

                    </Menu.Item>

                  );
                }
              })}

            <Menu.Item onClick={handleLogout} key="logout-button">

              <CollapsedIconMenu

                caption="Logout"

                icon="fa-arrow-right-from-bracket"

                collapsed={collapsed}

              />

            </Menu.Item>

          </Menu>
        )}

        {/* Footer Logo */}
        {/* {renderFooter(footerLogo)} */}
        {/* Footer Logo Ends */}
      </div>
    </div>
  );
}

/**
 * The Menu Item Takes a menu and creates more
 * sub menus
 */
function MenuItem({ menu, index, history }) {

  function renderMenus({ menu, index }) {

    let sub_menus = [];

    if (menu.sub_menus) {
      sub_menus = Menus.screenMenus(menu.sub_menus)
    }

    if (menu && sub_menus && sub_menus.length) {

      // return 'hello';
      return (
        <SubMenu
          key={menu.id}
          title={
            <span>
              <span>{menu.caption}</span>
            </span>
          }
        >
          {menu.sub_menus.map((menu, index) => {
            return <MenuItem menu={menu} index={menu.id} />;
          })}
        </SubMenu>
      );
    } else {
      return (
        <Menu.Item
          onClick={() => {
            history.push(menu.path);
          }}
          key={`${menu.id}`}
        >
          {menu.caption} {menu.id}
        </Menu.Item>
      );
    }
  }


  return renderMenus({ menu, index });

}