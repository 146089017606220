/***
 *
 * Candidate Visit
 *
 * @description
 * @author Sneha
 */

import React, { useEffect, useState, useContext, useRef } from 'react';

import { Link } from 'react-router-dom';

import { Input, Card, Table, Button, Space, Typography, Skeleton, message, Select } from 'antd';

import { DateUtils, GlobalContext, Location, RangePicker, ReferenceSelect, CustomActions, Branches } from 'soxo-firebase-core';

import { CandidateVisits } from './../../models/'

import './candidate-list.scss';
const { Option } = Select;

const { Title } = Typography;

const { Search } = Input;

export default function CandidateList({ model, edit, history, match, menu, ...props }) {

    let searchRef = useRef(null);

    const { attributes = {} } = menu;

    const { enableAdd = false } = attributes;

    let [query, setQuery] = useState('');

    const bookingColums = [

        {
            title: '#',
            dataIndex: 'index',
            render: (value, item, index) => index + 1,
        },

        {
            title: 'Name',
            key: 'name',
            render: (record) => {

                return record.name
            }
        },

        {
            title: 'Mofa Number',
            key: 'mofa_number',
            render: (record) => {

                return record.mofa_number
            },
            // dataIndex: 'candidate.mobile',
        },

        {
            title: 'Passport Number',
            key: 'passport_number',
            // dataIndex: 'candidate.email',
            render: (record) => {

                return record.passport_number
            },

        },


        // {
        //     title: 'Appointment Time',
        //     key: 'created_at',
        //     render: (record) => {

        //         // return DateUtils.fromFirebaseTime({ time: record.appointment_time });
        //     }
        // },

        {
            title: 'Reference Number',
            key: 'reference_number',
            render: (record) => {

                return record.reference_number
            },
            // dataIndex: 'reference_number',
        },

        {
            title: 'Travel Agent',
            key: 'travelagentname',
            render: (record) => {

                return record.travelagentname
            },
            // dataIndex: 'reference_number',
        },


        {
            title: 'Remarks',
            key: 'remarks',
            dataIndex: 'remarks',
        },


        {
            title: 'Created At',
            key: 'created_at',
            dataIndex: 'created_at',
        },
        {
            title: 'Status',
            key: 'status',

            render: (record) => {
                console.log(record)
                return (
                    <>
                        <Select
                            value={record.mofa_status}
                            id="mofaStatus"
                            placeholder={'Mofa Status'}
                            onChange={(value) => {
                                updateMofaStatus(record.id, value, record);
                            }}
                        >
                            <Option value="AR">All Records</Option>
                            <Option value="NE">New Mofa</Option>
                            <Option value="UD">Mofa Updated</Option>
                            <Option value="PD">Error on mofa</Option>
                            <Option value="CN">Mofa entry cancelled</Option>
                        </Select>
                    </>
                );
            },

        },

        // {
        //     title: '#',
        //     render: (record) => {

        //         let redirectLink = `/candidates/:id`;

        //         if (menu.redirect_link) {
        //             redirectLink = menu.redirect_link
        //         }

        //         redirectLink = redirectLink.replace(':id', record.id)

        //         return (<Link to={redirectLink}>View</Link>)
        //     },
        // },
    ]

    let firebaseApp = CandidateVisits.firebase();

    const { user = {} } = useContext(GlobalContext);

    const [view, setView] = useState(false);

    const [selectedbranch, setSelectedbranch] = useState(user.branch_id[0]);

    const [selectedstatus, setSelectedstatus] = useState('NE');

    let format = 'YYYY-MM-DD hh:mm';

    // Setting starttime and endtime within a week from today
    var startTime = DateUtils.getStartOfDay();

    var endTime = DateUtils.getEndOfDay();

    // Copying a copy of the time range for fingerprint search
    var todayStartTime = startTime;

    var todayEndTime = endTime;

    var params = Location.search();

    if (params.start_time) {

        // startTime = moment(params.start_time,'')
        startTime = DateUtils.getMomentInFormat(params.start_time, format)
    }

    if (params.end_time) {

        endTime = DateUtils.getMomentInFormat(params.end_time, format)
    }

    const [loading, setLoading] = useState(true);

    const [expRecord, setExptRecords] = useState();

    const [candidates, setCandidates] = useState([])




    /**
     * 
     */

    const [range, setRange] = useState([startTime, endTime]);

    useEffect(() => {

        loadData(selectedbranch, selectedstatus);

    }, []);

    function loadData(branch_id, mofa_status) {

        getAppointments(startTime, endTime, branch_id, mofa_status);

    }

    function updateMofaStatus(id, value, record) {

        let formBody = {
            mofa_status: value,
        };

        return CandidateVisits.update(id, formBody).then(async (result) => {

            message.success('Mofa status has been updated');

            if (value === "UD") {

                formBody = {
                    ...formBody,
                    email: record.email,
                    candidate_name: record.name,
                    branch_id: selectedbranch,
                    candidate_id: id,
                    user
                }

                await CandidateVisits.loadFunction('triggerMail', formBody)
            }

            refresh();

        });


    }


    function refresh() {

        loadData(selectedbranch, selectedstatus);
    }

    /**
     * Selectedstatus
    */
    function selectStatus(branch_id, mofa_status) {

        // console.log(mofa_status);

        setSelectedstatus(mofa_status);

        loadData(branch_id, mofa_status);
    }


    /**
     * Loading the appointments
     * 
     * @param {*} startTime 
     * @param {*} endTime 
     */
    function getAppointments(startTime, endTime, branch_id, mofa_status, disableLoader) {

        !disableLoader && setLoading(true);

        var queries = [{
            field: 'recorded_at',
            operator: '>=',
            value: DateUtils.getFirebaseTimestamp(startTime).toDate()
        }, {
            field: 'recorded_at',
            operator: '<=',
            value: DateUtils.getFirebaseTimestamp(endTime).toDate()
        }
            ,
        {
            field: 'branch_id',
            value: branch_id
        }
        ]

        if (mofa_status !== 'AR') {
            queries.push({
                field: 'mofa_status',
                value: mofa_status
            })
        }

        return CandidateVisits.get(queries).then((result) => {

            console.log(result);


            // console.log("result", result.candidate_visits);

            setCandidates(result.candidate_visits);

            // console.log("searchRef", searchRef);
            // console.log(candidates, "candates list is")

            // searchRef.focus();

            // var exporData = getExportData(result.candidates, bookingColums);

            // setExptRecords(exporData);

            !disableLoader && setLoading(false);

            return result.candidates;

        })
    }


    function updateTime(dt) {
        // setRange(dt);

        let start_time = DateUtils.getFormattedTimeDate(dt[0])

        let end_time = DateUtils.getFormattedTimeDate(dt[1]);

        setRange(dt);

        Location.search({

            start_time,

            end_time,
        });

        getAppointments(dt[0], dt[1], selectedbranch, selectedstatus); //Patiant list get function
    }

    /**
     * 
     */
    function redirectToPatientDetails() {

        Location.navigate({ url: '/personal-details' })

    }

    //To download
    function getExportData(data, bookingColums) {

        return data.map((record, index) => {
            var content = {
                '#': index + 1,
            };


            bookingColums.forEach((column) => {
                if (column.title === '#') {

                } else if (column.title === 'Name') {
                    return content[column.title] = record.first_name + ' ' + record.last_name
                } else if (column.title === 'Appointment Time') {
                    return content[column.title] = DateUtils.fromFirebaseTime({ time: record.appointment_time });
                } else {
                    return (
                        content[column.title] = record[column.dataIndex])
                }

            })

            return content
        });

    }

    //Get locations user has access to
    var queries = [{
        field: firebaseApp.firestore.FieldPath.documentId(),
        operator: 'in',
        value: user.branch_id
    }]

    /**
     * Selectedbranch
     */
    const selectBranch = (branch_id, selectedbranch) => {

        setSelectedbranch(branch_id);

        loadData(branch_id, selectedbranch);
    }

    /**
     * Return todays candidate to compare for fingerprint
     */
    const searchTodaysCandidates = async () => {

        return await getAppointments(todayStartTime, todayEndTime, selectedbranch, true);
    }

    /**
     * 
     * 
     * @param {*} candidate 
     */
    const onFingerprintSearch = (visit) => {

        if (visit) {

            let { candidate } = visit;

            let redirectLink = `/candidates/:id`;

            if (menu.redirect_link) {
                redirectLink = menu.redirect_link
            }

            redirectLink = redirectLink.replace(':id', visit.id)

            message.success(`Welcome ${candidate.name} `)

            Location.navigate({
                url: `${redirectLink}?authorized=true`,
            });

        } else {

            message.warning('Could not find the candidate');

        }
    }

    const onSearch = (event) => {

        let matching = '';

        const search = event.target.value;

        setQuery(search);

        if (search.length > 10) {

            candidates.forEach((candidate) => {

                let reference_number = candidate.reference_number.split('/').join('');

                if (reference_number === search) {

                    matching = candidate;
                }
            })
        }

        if (matching) {

            let redirectLink = `/candidates/:id`;

            if (menu.redirect_link) {
                redirectLink = menu.redirect_link
            }

            redirectLink = redirectLink.replace(':id', matching.id)

            message.success(`Welcome ${matching.candidate.name} `)

            Location.navigate({
                url: `${redirectLink}?authorized=true`,
            });

        }
    }


    let filtered = candidates.filter((record) => {

        query = query.toUpperCase();

        if (query) {



            if (record) {

                if ((record && record.name.toUpperCase()).indexOf(query) != -1) {
                    return true;
                }
            }


        } else {
            return true;
        }
    });


    return (
        <section className="candidate-list">
            <Title level={4}>Candidate List</Title>

            {
                loading
                    ?
                    (
                        <Skeleton />
                    )
                    :
                    (
                        <div>
                            <div className="page-header">

                                <div className='page-search'>

                                    <RangePicker
                                        allowClear={false}
                                        inputReadOnly
                                        format={'DD/MM/YYYY'}
                                        value={range}
                                        onChange={(range) => {
                                            console.log(range);
                                            updateTime(range);
                                        }}
                                    />

                                    <input
                                        className='search-input'
                                        autoFocus
                                        ref={searchRef}
                                        // ref={(ref) => searchRef = ref}
                                        placeholder="Search"
                                        allowClear
                                        onChange={onSearch}
                                    // style={{ width: 304 }}
                                    />

                                </div>


                                <>
                                    <Select
                                        value={selectedstatus}
                                        id="mofaStatus"
                                        placeholder={'Mofa Status'}
                                        onChange={(value) => {
                                            selectStatus(selectedbranch, value);
                                        }}
                                    >
                                        <Option value="AR">All Records</Option>

                                        <Option value="NE">New Mofa</Option>
                                        <Option value="UD">Mofa Updated</Option>
                                        <Option value="PD">Error on mofa</Option>
                                        <Option value="CN">Mofa entry cancelled</Option>
                                    </Select>

                                </>
                                <div className='page-actions'>

                                    <Space>

                                        <Button size="small" onClick={() => refresh()}>Refresh</Button>

                                        {
                                            user.branch_id.length
                                                ?
                                                <ReferenceSelect style={{ width: 200 }} className="form-element" label="description" model={Branches} onChange={selectBranch} value={selectedbranch} queries={queries} />
                                                :
                                                null
                                        }

                                        {menu && menu.id && <CustomActions position="inline" menu={menu} />}

                                        {/* Add Button */}
                                        {enableAdd && <Button size="small" onClick={redirectToPatientDetails}>Add</Button>}
                                        {/* Add Button Ends */}

                                        {/* {expRecord && <ExportReactCSV csvData={expRecord} />} */}

                                    </Space>

                                </div>
                            </div>

                            {/* Appointment Details */}
                            {view ? (
                                <>
                                    <CardList dataSource={candidates} columns={bookingColums} />
                                </>
                            ) : (
                                <>
                                    <Card className="table-card" size="small">
                                        <Table
                                            scroll={{ x: true }}
                                            rowKey={(record) => record.da_id}
                                            dataSource={filtered}
                                            columns={bookingColums}
                                            pagination={true}
                                        />
                                    </Card>
                                </>

                            )}

                        </div>
                    )}
        </section>
    );
}



//Mobile view card Section
function CardList({ dataSource, columns, url }) {

    const { user = {}, isMobile, dispatch } = useContext(GlobalContext);

    function onClick(item) {

        Location.navigate({
            url: `/candidates/${item.da_id}`,
        });

        dispatch({ type: 'index', payload: item.rowIndex });
    }

    return dataSource.map((item, index) => {
        // to={`/lab-detail/${item.BillID}`}
        return (
            <div
                key={index}
                className="report-item"
                onClick={() => {
                    onClick(item);
                }}
            >
                <CandidateVisits.Card record={item} />
            </div>
        );
    });
}

