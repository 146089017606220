import React, { useEffect, useState, useContext } from 'react';

import { Form, Input, Button, Select, Typography, Skeleton, message } from 'antd';

import { GlobalContext } from '../../../../Store';

import { Location } from '../../../../utils/location/location.utils';

import { Pages, Models, Menus, Roles, Branches, Departments } from '../../../../../core/models/';

import { ReferenceSelect } from '../../../../elements';

const { Title } = Typography;

const { Option } = Select;

export default function UserAdd({ model, edit, history, match, callback, formContent }) {

    const [loading, setLoading] = useState(true);

    const [form] = Form.useForm();

    const [pages, setPages] = useState([]);

    const [models, setModels] = useState([]);

    // const [modules, setModules] = useState([]);

    const [response, setReponse] = useState([]);

    const { user } = useContext(GlobalContext);

    const [roles, setRoles] = useState([]);

    useEffect(() => {

        getRoles();

        getPages();

        getModels();

        // getModules();

        if (formContent.id) {

            setLoading(false);

            // getRecord(params.id);
        } else {
            setLoading(false);
        }
    }, []);

    /**
     * 
     */
    function getRoles() {

        Roles.get().then((result) => {

            setRoles(result.roles)
        })

    }

    // function getRecord(id) {
    //     // console.log(id);

    //     Menus.getDetail(id).then((result) => {
    //         console.log(result);
    //         setReponse(result);

    //         setLoading(false);
    //     });
    // }

    /**
     * Load the Staffs
     */
    const getPages = () => {
        Pages.get([]).then((result) => {
            // console.log(result);

            setPages(result.pages);
        });
    };

    /**
     * Load the Staffs
     */
    const getModels = () => {
        Models.get([]).then((result) => {
            // console.log(result);

            setModels(result.models);
        });
    };

    /**
     * Load the Staffs
     */
    // const getModules = () => {
    //     Modules.get([]).then((result) => {
    //         // console.log(result);

    //         setModules(result.modules);
    //     });
    // };

    const onSubmit = (values) => {

        setLoading(true);
        // console.log(values);

        if (formContent.id) {

            // Update of model
            model.update(formContent.id, values, user).then(() => {
                // Location.back();
                setLoading(false);

                callback();

            });

        } else {

            model.loadFunction('createUser', {
                ...values,
                password: '909090'
            }).then((result) => {

                model.addDocument(result.uid, values).then(() => {

                    message.success('User created');

                    callback();
                    // Location.back();

                    setLoading(false);

                })
            })
        }
    };

    return (
        <section className="collection-add">
            <Title level={4}>{edit ? 'Edit' : 'Add'} User</Title>

            {loading ? (
                <Skeleton />
            ) : (
                <Form initialValues={formContent} form={form} layout="vertical" onFinish={onSubmit}>

                    {/* Name */}
                    <Form.Item name="name" label="Name" required>
                        <Input placeholder="Enter name" />
                    </Form.Item>
                    {/* Name Ends */}

                    {/* Phone */}
                    <Form.Item name="phone" label="Phone" required>
                        <Input placeholder="Enter phone" />
                    </Form.Item>
                    {/* Phone Ends */}

                    {/* Email */}
                    <Form.Item name="email" label="Email" required>
                        <Input placeholder="Enter email" />
                    </Form.Item>
                    {/* Email Ends */}

                    {/* Role Begins */}
                    <Form.Item name="role_id" label="Role" rules={[{ required: true, message: 'Please Input Role' }]}>
                        <Select style={{ width: '100%' }}>
                            {roles.map((role) => (
                                <Option value={role.id}>{role.name}</Option>
                            ))}
                        </Select>

                    </Form.Item>
                    {/* Role Begins Ends */}


                    {/* Role Begins */}
                    {/* <Form.Item name="locations" label="Locations" rules={[{ required: true, message: 'Please Input location' }]}>
                        <Select mode="multiple" style={{ width: '100%' }}>
                            {['delhi'].map((role) => (
                                <Option value={role}>{role}</Option>
                            ))}
                        </Select>

                    </Form.Item> */}
                    {/* Role Begins Ends */}

                    <Form.Item name="branch_id" label="Branch" rules={[{ required: true, message: 'Please select a branch' }]}>
                        <ReferenceSelect mode="multiple" label="description" model={Branches} />
                    </Form.Item>


                    <Form.Item name="department_id" label="Department" rules={[{ required: false, message: 'Please select a department' }]}>
                        <ReferenceSelect mode="multiple" label="description" model={Departments} />
                    </Form.Item>

                    <Form.Item name="active" label="Active" rules={[{ required: true, message: 'Please Input location' }]}>
                        <Select style={{ width: 120 }}>
                            {[true, false].map((option, key) => <Option key={key} value={option}>{option ? 'Yes' : 'No'}</Option>)}
                        </Select>
                    </Form.Item>

                    {/* Password */}
                    {/* <Form.Item name="password" label="Password" required>
                        <Input placeholder="Enter password" />
                    </Form.Item> */}
                    {/* Email Ends */}

                    <Form.Item>
                        <Button loading={loading} htmlType={'submit'} type="primary">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
            )}
        </section>
    );
}
