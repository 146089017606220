/**
 * Component used for Table
 *
 * @author Ashique
 *
 * Ensure to follow a minimal standard
 */

import React, { useState, useEffect } from 'react'

// import { ResponsiveLine } from '@nivo/line';

import { ResponsiveBar } from '@nivo/bar'

import { Modal } from 'antd'

import StatisticCard from '../statistic-card/statistic-card'

/**
 * To Display a bar graph
 *
 * @param {*} param0
 * @returns
 */
export default function BarGraph({ data, index, ...props }) {
  const [visible, setVisible] = useState(false)

  const [selected, setSelected] = useState({})

  let cases = []

  var content = []

  // # Note a temporary setup
  // managing data manipulation here for the dashboard
  // var months = Object.keys(result);
  if (data.length) {
    //To show legends
    data = data.map((item) => {
      item.counts = {
        ...item.counts,
        'Total Cases': item.counts.total_cases,
        'Total Positive Cases': item.counts.total_positive_cases,
        'Total Negative Cases': item.counts.total_negative_cases,
      }

      return item
    })

    cases.push('Total Cases', 'Total Positive Cases', 'Total Negative Cases')

    //To remove the first element from array.(In map data first element is total counts )
    data = data.slice(1, data.length).map((item) => {
      if (!item[0]) {
        content.push(item.counts)
      }
    })
  } else {
    data.forEach((month) => {
      let element = {
        Month: month.Month,
      }

      //
      if (cases.indexOf(month.Service) === -1) {
        cases.push(month.Service)
      }

      element[month.Service] = month['Total_Nos']

      content.push(element)
    })
  }

  /**
   * On select of the record
   */
  const onSelect = (selected) => {
    setVisible(true)

    setSelected(selected)
  }

  return (
    <div className="graph-container">
      <ResponsiveBar
        data={content}
        keys={cases}
        indexBy={index}
        margin={{ top: 50, right: 90, bottom: 60, left: 60 }}
        padding={0.3}
        groupMode="grouped"
        valueScale={{ type: 'linear' }}
        indexScale={{ type: 'band', round: false }}
        colors={{ scheme: 'nivo' }}
        defs={[
          {
            id: 'dots',
            type: 'patternDots',
            background: 'inherit',
            color: '#30002',
            size: 4,
            padding: 1,
            stagger: true,
          },
          {
            id: 'lines',
            type: 'patternLines',
            background: 'inherit',
            color: '#eed312',
            rotation: -45,
            lineWidth: 6,
            spacing: 10,
          },
        ]}
        fill={[
          {
            match: {
              id: 'total_positive_cases',
            },
            id: 'lines',
          },

          {
            match: {
              id: 'total_negative_cases',
            },
            id: 'lines',
          },

          {
            match: {
              id: 'total_cases',
            },
            id: 'lines',
          },
        ]}
        borderColor={{
          from: 'color',
          modifiers: [['darker', 1.6]],
        }}
        axisTop={null}
        axisRight={null}
        axisBottom={{
          tickSize: 5,
          tickPadding: 7,
          tickRotation: 35,
          legend: 'City',
          legendPosition: 'middle',
          legendOffset: 32,
        }}
        axisLeft={{
          tickSize: 5,
          tickPadding: 5,
          tickRotation: 0,
          legend: 'Cases',
          legendPosition: 'middle',
          legendOffset: -40,
        }}
        labelSkipWidth={12}
        labelSkipHeight={12}
        labelTextColor={{
          from: 'color',
          modifiers: [['darker', 2.5]],
        }}
        onClick={(record) => {
          onSelect(record)

          setVisible(true)
          console.log(record)
        }}
        legends={[
          {
            dataFrom: 'keys',
            anchor: 'top-right',
            direction: 'column',
            justify: false,
            translateX: 20,
            translateY: -40,
            itemsSpacing: 2,
            itemWidth: 80,
            itemHeight: 20,
            itemDirection: 'left-to-right',
            // itemOpacity: 0.35,
            symbolSize: 20,
            effects: [
              {
                on: 'hover',
                style: {
                  itemOpacity: 1,
                },
              },
            ],
          },
        ]}
        role="application"
        ariaLabel="Nivo bar chart demo"
        // label={(d) => {

        //     return <>

        //         <h1>

        //             Ashique
        //         </h1>

        //         <p>

        //             HelloPform
        //             `${d.id}: ${d.value}`

        //         </p>

        //     </>
        // }}
        tooltip={(record) => {
          const { data } = record

          console.log(data.total_positive)

          return (
            <div className="card">
              <div>{record.label}</div>

              <div>{data.total_cases}</div>

              <div>
                <strong>Positive - {data.total_positive_cases}</strong>
              </div>
            </div>
          )
        }}
        barAriaLabel={function (e) {
          // return "Ashique";
          // return e.id + ": " + e.formattedValue + " in country: " + e.indexValue
        }}
      />

      {/* 
             <Modal
                 header={null}
                 // className="camera-modal"
                 visible={visible}
                 // title={'Camera'}
                 footer={null}
                 onCancel={() => { setVisible(false); }}
             >
 
                 {visible ? <StatisticCard result={selected.data} {...props} /> : null}
 
             </Modal> */}
    </div>
  )
}

var groupBy = function (xs, key) {
  return xs.reduce(function (rv, x) {
    ;(rv[x[key]] = rv[x[key]] || []).push(x)

    return rv
  }, {})
}
