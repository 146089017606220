/**
 * @author Hasna
 * @description Handles upload of file
 * @version
 *
 * autoUpload:true - We use the flag to control if the files has to be auto uploaded to any cloud storage - For use in firebase projects.
 * server:'firebase/s3/local'
 * path:'',
 * multiple:true,
 *
 *
 */
import React, { useState } from 'react'

import { message, Upload } from 'antd'

import { PlusOutlined } from '@ant-design/icons'

import FirebaseUtils from './../../utils/firebase.utils'

export default function FileUpload({
  callback,
  maxSize = 3,
  initial = [],
  onChange,
  autoUpload,
}) {
  // Variable to save the files
  const [files, setFiles] = useState(initial)

  /**
   * '
   */
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  )

  /**
   *
   */
  const uploadProps = {
    onRemove: (file) => {
      var index = files.indexOf(file)

      var newFileList = files.slice()

      newFileList.splice(index, 1)

      setFiles(newFileList)
    },
    onChange(info) {
      if (info.file.status !== 'uploading') {
        // console.log(info.file, info.fileList);
      }

      if (info.file.status === 'done' && autoUpload) {
        message.success(`${info.file.name} file uploaded successfully`)
      } else if (info.file.status === 'error' && autoUpload) {
        message.error(`${info.file.name} file upload failed.`)
      }
    },
    beforeUpload: (file) => {
      // Uploading to firebase

      if (autoUpload) {
        var random = {
          customerName:
            'CustomerDocument' + Math.round(Math.random() * 1000000),
        }

        FirebaseUtils.uploadAttachment(file, random, '/collections').then(
          (result) => {
            let file = {
              uid: random.customerName,
              name: random.customerName,
              status: 'done',
              url: result.url,
              type: result.meta.contentType,
            }

            files.push(file)

            if (callback) {
              if (maxSize > 1) {
                callback(files)
              } else {
                callback(files[0])
              }
            } else {
              if (maxSize > 1) {
                onChange(files)
              } else {
                onChange(files[0])
              }
            }

            setFiles([...files])
          },
        )
        return false
      }
      // Uploading to aws or localpath
      else {
        files.push(file)

        // onChange(files)

        setFiles([...files])

        callback(files)
      }
    },
    files,
  }

  return (
    <div>
      <>
        <Upload
          {...uploadProps}
          listType="picture-card"
          fileList={files}
          // onChange={handleChange}
        >
          {files.length >= maxSize ? null : uploadButton}
        </Upload>
      </>
    </div>
  )
}
