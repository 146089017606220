/**
 * Camera Component would save the photo with location
 */

import React, { useState } from "react";

import ConfirmModal from './../confirm-modal/confirm-modal';

import { Modal, Button } from 'antd';

import Camera, { FACING_MODES } from 'react-html5-camera-photo';

// import useGeolocation from 'react-hook-geolocation'

import { CameraOutlined, DeleteOutlined } from '@ant-design/icons';

import 'react-html5-camera-photo/build/css/index.css';

import './camera.scss';

/**
 * Camera Component accepts multiple , onUpload
 * 
 * @param {*} param0 
 * @returns 
 */
export default function CameraComponent({ onUpload, value, onChange = () => { }, mode, multiple = false }) {

    // const geolocation = useGeolocation({
    //     enableHighAccuracy: true,
    //     maximumAge: 15000,
    //     timeout: 12000
    // })

    // Variable to control the camera component
    const [visible, setVisible] = useState(false);

    return (<div className="web-camera">

        <div className="web-camera-container">

            <div className="web-camera-box">
                {/* 
                {
                    selected
                        ?
                        <div className="image-container"><ImagePreview dataUri={selected} /> </div>
                        :
                        null
                } */}

            </div>

            <div className="actions">

                <Button type="secondary" className="camera-trigger" onClick={() => { setVisible(true); }}>

                    <CameraOutlined />

                    Take Picture
                </Button>

            </div>
        </div>

        <Modal
            width={'100%'}
            header={null}
            destroyOnClose
            className="camera-modal"
            visible={visible}
            title={'Camera'}
            footer={null}
            onCancel={() => { setVisible(false); }}
        >

            <AdvancedCamera mode={mode} onUpload={onUpload} />
        </Modal>

    </div>)
}

/**
 * The camera Component , Preview and actions are moved 
 * to a seperate component for easy manage
 * 
 * @param {*} param0 
 * @returns 
 */
function AdvancedCamera({ mode, onUpload }) {

    // Variable to store the active value
    const [active, setActive] = useState(null);

    // Variable to save taken photos
    const [selected, setSelected] = useState([]);

    /**
     * 
     * @param {*} dataUri 
     */
    function handleTakePhoto(dataUri) {

        // Do stuff with the photo...

        selected.push(dataUri);

        setSelected([...selected]);

        console.log(selected);

        // setVisible(false);
    }

    /**
     * Upload the images
     */
    function uploadImages() {

        onUpload(selected);
    }

    /**
     * Delete the image at index 
     */
    function deleteImage(index) {

        selected.splice(index, 1);

        setSelected([...selected]);
    }

    /**
     * Clear the local data
     */
    function clear() {

        setSelected([]);
    }

    /**
     * 
     */
    function startCamera() {
        setActive(null);
    }

    return (<>

        <div className="camera-top-area">

            <div className="camera-window">
                {
                    active
                        ?
                        <ImagePreview dataUri={active} />
                        :
                        <Camera
                            isImageMirror={false}
                            // idealFacingMode={FACING_MODES.ENVIRONMENT}
                            onTakePhoto={(dataUri) => { handleTakePhoto(dataUri); }}
                            idealFacingMode={mode == 'rear' ? FACING_MODES.ENVIRONMENT : FACING_MODES.USER}
                        />
                }
            </div>

            <div className="camera-action-bar">

                {selected.length ? <Button onClick={uploadImages}>
                    Upload
                </Button> : null}

                {active ? <Button onClick={startCamera}>
                    Start Camera
                </Button> : null}

                <ConfirmModal callback={clear} />

            </div>

        </div>


        <div className="preview">

            {/* Preview Gallery */}
            {
                selected.map((record, index) => {

                    return <div className="image-gallery" onClick={() => { setActive(record) }}>

                        <ImagePreview dataUri={record} />

                        <ConfirmModal
                            disableRemarks={true}
                            className="delete-button"
                            caption={<DeleteOutlined />}
                            callback={() => {

                                deleteImage(index);
                            }} >
                            <DeleteOutlined />
                        </ConfirmModal>
                    </div>
                })
            }
            {/* Preview Gallery Ends */}

        </div>

        <div className="geotag">
        </div>
    </>)

}

/**
 * Image Preview Component for the Taken Photo
 * 
 * @param {*} param0 
 * @returns 
 */
export const ImagePreview = ({ dataUri, isFullscreen }) => {
    let classNameFullscreen = isFullscreen ? 'demo-image-preview-fullscreen' : '';

    return (
        <div className={'demo-image-preview image-preview' + classNameFullscreen}>
            <img src={dataUri} />
        </div>
    );
};