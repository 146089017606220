// utils/useDeviceDetect.js
import React, { useEffect, useState } from "react";

export default function useDeviceDetect() {
  const [isMobile, setMobile] = useState(false);

  useEffect(() => {

    // const userAgent =
    //   typeof window.navigator === "undefined" ? "" : navigator.userAgent;

    // const mobile = Boolean(
    //   userAgent.match(
    //     /Android|BlackBerry|iPhone|iPad|iPod|Opera Mini|IEMobile|WPDesktop/i
    //   )
    // );

    let mobile = ((window.innerWidth <= 800))
    // || (window.innerHeight <= 700))

    setMobile(mobile);

  }, []);

  return { isMobile };
}