/**
* Component for antd switch
 */

import React, { useState, useContext } from "react";

import { Switch as AntdSwitch} from 'antd';

import PropTypes from "prop-types";

export default function Switch({ onChange, checked }) {

    return (<div>
        <>
            <AntdSwitch
                checked={checked}
                onChange={onChange}
                // checked={ }
                listType="picture-card"
            />

        </>

    </div>)
}

Switch.propTypes = {
   /** Callback function that is called when the switch is clicked or toggled. */
  onChange: PropTypes.func,
   /** A boolean value indicating whether the switch is checked or not. Use this prop to control the component's state externally. */
  checked: PropTypes.bool,
   /** A boolean value indicating whether the switch is initially checked or not. */
  defaultChecked: PropTypes.bool,
   /** A boolean value indicating whether the switch is disabled or not. */
  disabled: PropTypes.bool,
   /** A string that represents the ID of the switch component. */
  id: PropTypes.string,
   /** A string that represents the size of the switch component. Can be 'small', 'default', or 'large'. */
  size: PropTypes.oneOf(['small', 'default', 'large']),
   /** The color of the switch when it is turned on. */
  checkedChildren: PropTypes.node,
   /** The color of the switch when it is turned off. */
  unCheckedChildren: PropTypes.node,
   /** A custom class name for the switch component. */
  className: PropTypes.string,
   /** A custom style object for the switch component. */
  style: PropTypes.object,
};