/**
 * Generic Detail accepts an array of fields to update any resource
 */

import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Button, Modal, Skeleton, Tabs, Space, Row } from 'antd';

import { withRouter } from 'react-router-dom';

import './menu-detail.scss';

import GenericList from './../../../../modules/generic/generic-list/generic-list';

import MenuList from './../menu-list/menu-list';

import { ReloadOutlined } from '@ant-design/icons';

import { MenuRoles, Menus, Actions } from './../../../../models';

const { Title } = Typography;

const { TabPane } = Tabs;

/**
 * Generic List
 *
 * @param {*} param0
 */
function MenuDetail({ match, model, columns: cols, schema = { custom_actions: [] }, disableEdit = schema.disableEdit }) {

    const [record, setRecord] = useState([]);

    const [visible, setVisible] = useState(false);

    const { params } = match;

    const [loading, setLoading] = useState(false);

    useEffect(() => {

        loadData();

    }, [params]);

    const loadData = () => {
        setLoading(true);

        getRecords(schema);
    };

    const getRecords = (schema) => {
        // Get the model by queries
        return model.getRecord(match.params.id, model).then((result) => {

            setRecord(result);

            setLoading(false);
        });
    };

    function refresh() {
        loadData();
    }

    function closeModal() {
        setVisible(false);
    }

    return (
        <section className="generic-list">
            {/* Table Header */}
            <div className="table-header">
                <div className="table-title">
                    <Title level={4}>{model.name}</Title>
                </div>

                <div className="table-actions">
                    <div className="button-container">
                        <Button onClick={refresh} type="secondary" size={'small'}>
                            <ReloadOutlined />
                        </Button>
                        {/* 
                        {disableEdit ? null : (
                            <Space size="middle">
                                <Button type="dashed">
                                    <Link to={`/${model.name}/${match.params.id}/e/`}>Edit</Link>
                                </Button>
                            </Space>
                        )} */}


                        {!model.ModalAddComponent ? null : (
                            <Button size={'small'} type="dashed" onClick={() => setVisible(true)}>
                                Edit
                            </Button>
                        )}

                    </div>
                </div>
            </div>

            {/* Table Header Ends */}

            {/* Add Modal */}
            <Modal destroyOnClose confirmLoading={loading} title="Edit Menu" visible={visible} onCancel={() => { closeModal() }} footer={null}>
                <model.ModalAddComponent
                    match={match}
                    // menu={menu}
                    model={model}
                    // additional_queries={additional_queries}
                    formContent={record}

                    callback={() => {
                        closeModal(true);
                    }}
                />
            </Modal>


            {loading ? (
                <Skeleton type="listing" />
            ) : (
                <>
                    <div className="card">

                        <div gutter={0} className="detail-wrapper">

                            <div className="detail-element">
                                <span>Name</span>
                                <Row span={6}>
                                    <h3>{record.name}</h3>
                                </Row>
                            </div>

                            <div className="detail-element">
                                <span>Description</span>
                                <Row span={6}>
                                    <h3>{record.description}</h3>
                                </Row>
                            </div>
                        </div>
                    </div>

                    <Tabs>
                        <TabPane tab="Sub Menus" key="0">
                            <MenuList
                                model={Menus}
                                history={history}
                                additional_queries={[
                                    {
                                        field: 'header_id',
                                        value: params.id,
                                    },
                                ]}
                            />
                        </TabPane>

                        <TabPane tab="Roles" key="1">
                            <GenericList
                                
                                model={MenuRoles}
                                additional_queries={[
                                    {
                                        field: 'menu_id',
                                        value: params.id,
                                    },
                                ]}
                            />
                        </TabPane>

                        <TabPane tab="Actions" key="2">
                            <GenericList
                                model={Actions}
                                additional_queries={[
                                    {
                                        field: 'menu_id',
                                        value: params.id,
                                    },
                                ]}
                            />
                        </TabPane>
                    </Tabs>
                </>
            )}
        </section>
    );
}

export default withRouter(MenuDetail);
