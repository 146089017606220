/**
 *
 *
 * @description Component for Copying the element
 * @author Ashique
 */

import React, { useState, useEffect, useContext } from "react";

import { Button, message } from "antd";

import PropTypes from "prop-types";

// import 'antd/dist/antd.css';


/**
 *
 */
export default function CopyToClipBoard({
  caption = "Element ID",
  id,
  record,
}) {
  /**
   * Copy to clipboard
   *
   * @param {*} step
   */
  function copyToClipBoard(id) {
    console.log(record);

    /* Copy the text inside the text field */
    navigator.clipboard.writeText(id);

    message.success(`${caption} copied to Clipboard`);
  }

  return (
    <>
      <Button
        size={"small"}
        onClick={() => {
          copyToClipBoard(id);
        }}
      >
        Copy
      </Button>
    </>
    
  );
}

CopyToClipBoard.propTypes = {

  /** A string that represents the caption or label for the element ID being copied to clipboard.  */
  caption: PropTypes.string,
  /** A string that represents the element ID that should be copied to the clipboard. This prop is required. */
  id: PropTypes.string.isRequired,
  /** An object that contains any additional information or data related to the element being copied to the clipboard. This prop is optional. */
  record: PropTypes.object,
};