import React from "react";


import Base from './../base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import DateUtils from '../../utils/date/date.utils';

class Comments extends Base {

    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },


            {
                field: 'gender',
                type: 'radio',
                caption: 'Gender',
                options: [
                    'Male',
                    'Female',
                    'Other'
                ]
            },

            {
                field: 'phone',
                caption: 'Phone',
            },

            {
                field: 'alternate_number',
                caption: 'Alternate Number',
            },

            {
                field: 'email',
                caption: 'Email Address',
            },

            {
                field: 'address',
                caption: 'Address',
                type: 'textarea'
            },

            {
                field: 'application_number',
                caption: 'Application Number',
                type: 'input'
            },

            {
                field: 'date_of_birth',
                caption: 'Date of Birth',
                type: 'date',
                changeTo: (value) => {

                    return DateUtils.formatDate(value);

                }
            },

            {
                field: 'medical_category',
                caption: 'Medical Category',
                type: 'select',
                options: [
                    'Cat 4 A',
                    'Cat 4 B',
                    'Cat 5',
                    'Cat 6 A',
                    'Cat 6 B',
                    'Cat 7',
                    'Cat 8',
                    'Lipid Profile',
                    'LFT',
                    'BP 1',
                    'BP 2',
                    'BP 3',
                ]
            },

        ];
    }

    get getEndpoint() {
        return 'comments';
    }

    get path() {
        return `comments`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `comment`;
    }

    get columns() {
        return [

            {
                caption: 'Name',
                render: (record) => {

                    return <Link className="booking-card" to={`/candidates/${record['id']}`}>
                        {record.name}
                    </Link>


                },
                key: 'created_at',
            },


            {
                caption: 'Phone',
                field: 'phone',
                key: 'phone',
            },

            {
                caption: 'Address',
                field: 'address',
                key: 'address',
            },

            {
                caption: 'Cateogory',
                field: 'medical_category',
                key: 'medical_category',
            },

            {
                caption: 'Date of Birth',
                field: 'date_of_birth',
                key: 'date_of_birth',
            },


            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },

        ];
    }

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = ({ record, redirect_to = 'candidates' }) => {
        return (
            <Link className="booking-card" to={`/${redirect_to}/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.name}</h2>

                    <h3 className="title">{record.address}</h3>


                    <p className="title">{record.email}</p>

                    <p className="address">
                        Created on {record.created_at}
                        <Tag color="magenta">{record.medical_category}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    getComments = (id) => {
        let obj = [];
        let app = this.getFireStoreApp();
        console.log(id)

        return new Promise((resolve, reject) => {
            app.collection('logs')
                .doc('comments')
                .collection(id)
                .get()
                .then((querySnapshot) => {
                    querySnapshot.forEach(function (doc) {
                        // obj[doc.id] = doc.data();

                        obj.push(doc.data());
                    });

                    resolve(obj);
                });
        });
    };

}

export default Comments;
