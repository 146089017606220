/**
 * 
 */

 import { Base } from 'soxo-firebase-core';


 
 class CandidateVisit extends Base {
 
     constructor() {
         super();
 
         this.fields = [
             {
                 field: 'name',
                 caption: 'Name',
             },
             {
                 field: 'phone',
                 caption: 'Phone',
             },
 
             {
                 field: 'address',
                 caption: 'Address',
                 type: 'textarea'
             },
             {
                field: 'Age',
                caption: 'Age',
            },
            {
                field: 'Date',
                caption: 'Date',
            },
 
 
         ];
     }
 
     get getEndpoint() {
         return 'candidate_visits';
     }
 
     get path() {
         return `candidate_visits`;
     }
 
     get getName() {
         return this.modelName + `s`;
     }
 
     get modelName() {
         return `candidate_visit`;
     }
 
     get columns() {
 
         return [
             {
                 caption: 'Name',
                 field: 'candidate.name',
                 key: 'name',
             },
 
             {
                 caption: 'Phone',
                 field: 'candidate.phone',
                 key: 'phone',
             },
             {
                caption: 'Date',
                field: 'candidate.Date',
                key: 'date',
            },
 
             {
                 caption: 'Age',
                 field: 'candidate.age',
                 key: 'age',
             },
 
             {
                 caption: 'Address',
                 field: 'candidate.address',
                 key: 'address',
             },
             
         ];
     }
 
     extraParams = () => {
 
 
         return {
             status: 'Initial'
         }
 
     }
     registerCandidate = async ({ formBody }) => {


        const app = this.getFireStoreApp();

        let batch = app.batch();

        formBody.cancellation_status = false;

        const addReference = await this.addBatch(batch, formBody);

        await batch.commit();

        return { id: addReference.id };
    }

 }
 
 export default CandidateVisit;
 