import React, { useState, useEffect, useContext } from "react";

import { Button, Empty, Card, Typography, Skeleton, message } from 'antd';

import { InfoCircleOutlined } from '@ant-design/icons';

import './slots.scss';

// import { Location } from '@soxo/bootstrap-core';

import { Calendar } from 'antd';

import moment from 'moment';

const { Title, Text } = Typography;

/**
 *
 * @returns
 */
export default function Slots() {

    // var params = Location.search();

    const [item, setItem] = useState();

    const [today, setToday] = useState(moment());

    
    const [available, setAvailable] = useState([{ as_starttime: '10 AM' }, { as_starttime: '12 AM' }, { as_starttime: '13 AM' }]);

    const [selected, setSelected] = useState({});

    const [loading, setLoading] = useState(false);

    const [appointment, setAppointment] = useState({});

    useEffect(() => {
        setLoading(false);

    }, []);

    /**
     *
     *
     * @param {*} dt
     */
    function getSlots(dt, selected_item, callback) {
        setLoading(true);

    }

    function onChange(value, mode) {
        setToday(value);
        setSelected({});
        getSlots(value, item);
    }

    /**
     *
     * @param {*} slot
     */
    const selectSlot = (slot, key) => {
        setSelected({ item: slot, key });
    };

    /**
     *
     */
    const onSlotConfirm = () => {

    };

    return (
        <>
            <section className="slots page">
                <div className="page-content">
                    {/* <Title level={3}>Select an appointment</Title> */}

                    <div className="calendar-wrapper">
                        <div className="left">

                            <div className="top-bar">

                                <div className="selected-package-container">
                                    {/* <SelectedPackage /> */}

                                </div>

                                <div className="notice-card card">

                                    <Title level={5}>
                                        <InfoCircleOutlined /> Note
                                    </Title>

                                    <p>
                                        Choose a convenient date and time to conduct the screening.

                                        {/* You have <h3>
{days}
</h3> days remaining to complete this process. */}
                                    </p>

                                    {/* <Link to="/change-password">
                                        Learn More
                                    </Link> */}

                                </div>
                            </div>



                            <div className="time-slot-container">
                                {loading ? (
                                    <div className="loading-text">
                                        <p>Loading slots</p>

                                        <Skeleton />
                                    </div>
                                ) : (
                                    <div className="calendar-container">
                                        <Card className="calendar">
                                            {/* Calendar */}
                                            <Calendar
                                                disabledDate={(current) => {
                                                    return current.diff(moment(), 'hours') < 0;
                                                }}
                                                value={today}
                                                fullscreen={false}
                                                onChange={onChange}
                                            />
                                            {/* Calendar Ends */}
                                        </Card>

                                        <div className="calendar-slots">
                                            <p>When do you want to schedule the appointment ?</p>

                                            <div className="time-slots">
                                                {available.map((entry, key) => (
                                                    <div
                                                        className={`item ${key === selected.key ? 'active' : ''} ${entry.booked ? 'booked' : ''}`}
                                                        key={key}
                                                        onClick={() => !entry.booked && selectSlot(entry, key)}
                                                    >
                                                        {/* {moment(entry.as_starttime).format('HH:mm A')} */}
                                                        {entry.as_starttime}
                                                    </div>
                                                ))}
                                            </div>
                                            {available.length ? (
                                                <>
                                                    <Button type="primary" onClick={onSlotConfirm}>
                                                        Proceed
                                                    </Button>
                                                </>
                                            ) : (
                                                <>
                                                    <Empty description={false} />
                                                    <p className="text-center">No slots available</p>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

/**
 *
 * @returns
 */
// function SelectedPackage() {
//     var params = Location.search();

//     const [selected, setSelected] = useState({});

//     const [loading, setLoading] = useState(false);

//     useEffect(() => {
//         getItems();
//     }, []);

//     /**
//      * Get the master data to show options for the user
//      */
//     function getItems() {
//         setLoading(true);

//         Items.getItems(params.da_packagetypevalue).then((result) => {

//             console.log(result);

//             // setSelected(result.filter((record) => record.itm_active === 'Y'));

//             setSelected(result)

//             setLoading(false);
//         });
//     }

//     return (
//         <div className="card card-shadow selected-package">
//             <Title level={4}>Packages Selected</Title>

//             {loading ? (

//                 <>Loading</>
//             )
//                 : (
//                     <>
//                         <div className="section-content">
//                             <div className="section-row">
//                                 <div className="section-label">
//                                     <Text type="secondary">{selected.itm_desc}</Text>
//                                 </div>
//                                 <div className="section-value">
//                                     <strong>{selected.ir_rate}</strong>
//                                 </div>
//                             </div>
//                         </div>
//                     </>
//                 )}
//         </div>
//     );
// }
