import React, { useEffect } from "react";
import { CSVLink } from "react-csv";
import { Button } from "antd";

import { Trans } from 'react-i18next';

export const ExportReactCSV = ({ csvData, headers,fileName }) => {
  return (
    <Button type="secondary" size="small" variant="warning">
      {csvData && (
        <CSVLink data={csvData} headers={headers} filename={fileName ||"Report.csv"}>
          <Trans i18nKey="DownloadButton"></Trans>
        </CSVLink>
      )}
    </Button>
  );
};
