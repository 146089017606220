import React, { useEffect, useContext, useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// import

import {
    Landing,
} from './../';

import ApplicationBootstrap from './../application-bootstrap/application-bootstrap';


function RootApplication({ children, ...props }) {

    let baseUrl = process.env.REACT_APP_ADMIN_BASE_URL || ""

    const { appSettings = {}, CustomComponents = [], CustomModels = [] } = props;

    useEffect(() => {
        return () => { };
    }, []);

    return (
        <ApplicationBootstrap {...props} >

            <Switch>

                <Route exact path={baseUrl}>
                    <Router basename={baseUrl}>

                        <Route
                            // exact
                            path=""
                            // component={Landing}
                            render={(routeParams) => <Landing

                                steps={props.steps}

                                appSettings={appSettings}
                                CustomComponents={CustomComponents}
                                CustomModels={CustomModels}
                                {...routeParams}
                            />}
                        ></Route>

                    </Router>

                </Route>


                <Route path="/">

                    <Router basename="">

                        {children}

                    </Router>

                </Route>

            </Switch>

        </ApplicationBootstrap>
    );
}


export default RootApplication;
