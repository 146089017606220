/**
 * Image Wrapper
 */


import React from "react";


import { Row, Image, Card, Button } from 'antd';

import { DownloadOutlined } from '@ant-design/icons'

// import PdfViewer from './../pdf-viewer/pdf-viewer';

export default function ImageWrapper({ type, content, field,width=200 }) {


    return (<div className="vehicle-card card image-wrapper">

        <div gutter={0} className="detail-wrapper">

            <div className="detail-element">
                <Row span={12}>

                    {
                        type === 'array' ?
                            <>

                                {
                                    content.map((file, key) => {

                                        return (
                                            <div key={key}>
                                                <div style={{ padding: '10px 4px 0px 4px', border: '1px solid #efefef', marginBottom: '10px', width: '50%' }}>
                                                    <DownloadOutlined style={{ marginBottom: '0px !important' }} />
                                                    <a href={file.url} style={{ paddingLeft: '8px' }} download={`newfilename${file.url}`}>Download</a>
                                                </div>
                                                {
                                                    file.type === "application/pdf" ?
                                                        <Card>
                                                            <div>
                                                                {/* <PdfViewer url={file.url} /> */}
                                                                <embed src={file.url} width="500" height="375"
                                                                    type="application/pdf" />
                                                            </div>
                                                        </Card>
                                                        :
                                                        <Image width={width} height={200} src={file.url} />
                                                }
                                            </div>
                                        )
                                    })
                                }

                            </>
                            :
                            <div className="">

                                <div className="page-header">

                                    <div className="actions">


                                        <a href={content.url} download={`newfilename${content.url}`}>

                                            <Button type="primary" size="small">

                                                <DownloadOutlined style={{ marginBottom: '0px !important' }} />

                                                Download
                                            </Button>
                                        </a>

                                    </div>

                                </div>
                                {
                                    content.type === "application/pdf"
                                        ?
                                        // <PdfViewer url={content.url} />
                                        <embed src={content.url} width="500" height="375"
                                            type="application/pdf" />
                                        :
                                        <Image width={width} src={content.url} />
                                }

                                <div>

                                    {
                                        content.type === "application/pdf"
                                            ?
                                            // <PdfViewer url={content.url} />
                                            <embed src={content.url} width="500" height="375"
                                                type="application/pdf" />
                                            :
                                            <Image width={'100%'} src={content.url} />
                                    }

                                </div>

                            </div>
                    }

                </Row>
            </div>
        </div>
    </div>)
}
