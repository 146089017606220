/**
 *  @author Sameena
 * Component for antd Tiemline
 */

import Timeline from 'antd/lib/timeline/Timeline';
import TimelineItem from 'antd/lib/timeline/TimelineItem';

const TimeLine = Object.assign(Timeline, {
  
  TimelineItem
});

export default TimeLine;