







/**
 * 
 * @param {*} records 
 * @param {*} columns 
 * @returns 
 */
export function getExportData(records, exportDataColumns) {

    let filteredColumns = exportDataColumns.filter((col) => !col.disableExport)

    // Preparing headers for data exporting
    let exportDataHeaders = filteredColumns.map((data) => {

        return {
            ...data,
            key: data.key,
            label: data.title
        }

    })

    // Iterate each record and map with the columns
    const response = records.map((row, columnIndex) => {

        let entry = {
            'Sl No': columnIndex + 1
        };

        filteredColumns.forEach((column, indexValue) => {

            // Data Index / Field
            if (column.exportDefinition) {

                entry[column.key] = column.exportDefinition(row, indexValue, columnIndex);

            } else if (column.render) {

                entry[column.key] = column.render(row, indexValue, columnIndex);

            } else if (column.dataIndex) {

                entry[column.key] = row[column.dataIndex];

            } else if (column.field) {

                entry[column.key] = row[column.field];

            } else {

                entry[column.key] = row[column.field];

            }
        })

        return entry;

    })

    return { exportDataColumns: response, exportDataHeaders }

}
