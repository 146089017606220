/**
 * Form Display Component 
 * 
 * @description Component to replace any custom form that you require to have in the project
 * @author Ashique Mohammed
 * @version 0.0.1
 * @date 28/01/2023
 * 
 */

import React, { useEffect, useState, Fragment, useContext } from "react";

import FormCreator from "./../../../../models/forms/components/form-creator/form-creator";

import FieldSelector from './../field-selector/field-selector';

import './form-display.scss';

const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

/**
 * FormDisplay
 * 
 * @param {*} param0 
 * @returns 
 */
export default function FormDisplay({ match, step, history }) {

    const [selected, setSelected] = useState([]);

    /**
     * The selected element is added to the list
     * 
     * @param {*} element 
     */
    const selectElement = (element) => {

        // We generate an initial random field name for the field added
        // This can be configured later with the field customizer
        let randomFieldName = 'field_' + Math.floor(Math.random() * (200000 - 10000 + 1) + 10000)

        // We need to check if the last element is a tab 
        // If its a tab , and if we add more tabs , it should be pushed to the array . 
        // If its a tab ,and we add a field input , we push to the array fields

        let lastElement = selected.length;

        if (selected.length && selected[lastElement - 1].type === 'Tabs') {

            // For upcoming tabs , we have to push to the array
            if (element.type === 'Tabs') {

                selected[lastElement - 1].tabs.push({
                    ...element,
                    caption: 'Caption',
                    name: 'caption',
                    field: randomFieldName,
                    type: element.value,
                    options: [],
                })
            } else {

                selected[lastElement - 1].tabs[0].fields.push({
                    ...element,
                    caption: 'Caption',
                    name: 'caption',
                    field: randomFieldName,
                    type: element.value,
                    options: [],
                })
            }

        } else {

            selected.push({
                ...element,
                caption: 'Caption',
                name: 'caption',
                field: randomFieldName,
                type: element.value,
                options: [],
            })

        }

        setSelected([...selected]);

        console.log(selected);
    }

    /**
     * On Update of field configuration 
     * We use the configuration to update the properties 
     * of the field
     * 
     * @param {'*'} index 
     * @param {*} field 
     * @param {*} value 
     */
    const onFieldUpdate = async (index, field, values) => {

        selected[index] = values;

        setSelected([...selected]);

        await true;

    }

    /**
     * On remove of field
     * 
     * @param {'*'} index 
     * @param {*} field 
     * @param {*} value 
     */
    const onFieldRemove = async (index) => {

        selected.splice(index, 1);

        setSelected([...selected]);
    }

    /**
     * Event triggered on end of drag
     * 
     * @param {*} source 
     * @param {*} destination 
     */
    const onListUpdate = async (source, destination) => {

        // Saving the source value
        let initial = selected[source.index];

        // Saving the destination value
        let final = selected[destination.index];

        // Shuffle the value as per final output
        selected[destination.index] = initial;

        // 
        selected[source.index] = final;

        setSelected([...selected]);
    }

    /**
     * Customize the tab from the form creator
     * 
     * @param {*} tab 
     * @param {*} index 
     */
    const onTabUpdate = (tab, index) => {

        selected[index] = values;

        setSelected([...selected]);
    }


    useEffect(() => {
    }, []);

    return (
        <div className="form-display">

            <div className="form-creator-container">

                {/* Selected Columns */}
                <FormCreator
                    fields={selected}
                    onFieldRemove={onFieldRemove}
                    onFieldUpdate={onFieldUpdate}
                    onListUpdate={onListUpdate}

                    onTabUpdate={onTabUpdate}

                    onSubmit={async (values) => {

                        console.log(values);

                        await values;
                    }}
                />
                {/* Selected Columns Ends */}

                {/* Form Configure */}
                {/* {selected.map((field) => <FieldCustomizer field={field} />)} */}
                {/* Form Configure Ends */}

            </div>

            {/* Field Selector */}
            <FieldSelector selectElement={selectElement} />
            {/* Field Selector Ends */}

        </div>
    );
}

