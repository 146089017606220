import React from "react";

import { Button as ButtonElement } from "antd";

import PropTypes from "prop-types";

// import 'antd/dist/antd.css';

Button.propTypes = {
  /** Size of the button */
  size: PropTypes.string,

  /** The caption or any icon that need to be under the button */
  children: PropTypes.any,

  /** Primary | Secondary */
  type: PropTypes.string,

  /** On click method */
  onClick: PropTypes.func,

  /** Control if the button is disabled */
  disabled: PropTypes.bool,

  /** Control if the button is loading */
  loading: PropTypes.bool,

  /** If you need to pass additional classname to the component */
  className: PropTypes.string,

  // You can pass antd icons to the component
  icon: PropTypes.object,
};

/**
 * Button element that is extending antd . So all its default behaviour is used
 * Refer antd @5.4.2
 *
 * @author Ashique Mohammed
 *
 */
function Button({
  size,
  children,
  type,
  onClick = () => {},
  disabled,
  loading,
  htmlType = "button",
  className,
  icon,
}) {
  return (

    <>

      {/* <AwesomeButton
        type={type}

        // type="primary"
        onPress={onClick}
        htmlType={htmlType}
        loading={loading}

      >

        {children}

        {/* Button */}

      {/* </AwesomeButton> */} 

      <ButtonElement
        type={type}
        htmlType={htmlType}
        loading={loading}
        size={size}
        className={`button-wrapper ${className}`}
        disabled={disabled}
        onClick={() => {
          onClick();
        }}
        icon={icon}
      >
        {children}
      </ButtonElement> 


    </>

  );
}

export default Button;
