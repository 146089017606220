import React, { useState, useContext } from 'react';

import './confirm-modal.scss';

import { Card, Input, Button, Modal, Form, Row, message, Popconfirm, Skeleton, Typography, Checkbox, Timeline, Tag } from 'antd';

// import { GlobalContext } from '../../Store';

const { Title, Text } = Typography;

const { TextArea } = Input;

export default function ConfirmModal({
    loading,
    className,
    caption,
    type = 'secondary',
    description = "Are you sure you want to proceed",
    size = 'small',
    callback,
    onReject,
    required = false,
    maxLength,
    disableRemarks = false
}) {
    // const { user } = useContext(GlobalContext);
    //Hooks
    const [visible, setVisible] = useState(false);

    /**
     * On submit of form
     *
     * @param {*} event
     */
    function onSubmit(values) {
        setVisible(false);

        callback(values);
    }

    function onFormReject() {

        onReject();
    }

    return (
        <>
            <span className="show-search">
                <Button className={className} loading={loading} type={type} size={size} onClick={() => setVisible(true)}>
                    {caption}
                </Button>
            </span>
            <Modal visible={visible} footer={null} className="" onCancel={() => setVisible(false)}>
                <Form layout="vertical" onFinish={onSubmit}>
                    
                    <p>
                        {description}
                    </p>

                    {/* Remarks Input */}
                    {!disableRemarks ? <Form.Item name={'remarks'} label="Remarks" rules={[{ required: required, message: 'Please Enter Remarks' }, { max: maxLength, message: "Exceed limit ! " }]} tooltip={{ title: 'Please Enter Remarks' }}>
                        <TextArea />
                    </Form.Item> : null}
                    {/* Remarks Input Ends */}

                    <Form.Item>
                        <Button htmlType="submit" type="primary">
                            Submit
                        </Button>

                        {onReject && <Button type="secondary" onClick={onFormReject}>
                            Reject
                        </Button>}

                    </Form.Item>
                </Form>
            </Modal>
        </>
    );
}
