/**
 * Generic List accepts an array of fields to update any resource
 */

import React, { useState, useRef, useEffect, useContext } from 'react';

import { Modal, Button, List } from 'antd';

import { TableOutlined, SelectOutlined, DeleteOutlined } from '@ant-design/icons';

import { Columns, UserPreferences } from '../../../../core/models/';

import './table-settings.scss';

/**
 * Generic List
 *
 * @param {*} param0
 */
function TableSettings({ menu, layout = {}, refresh }) {

    const [loading, setLoading] = useState(false)

    const [visible, setVisible] = useState(false);

    const [columns, setColumns] = useState([]);

    const [selected, setSelected] = useState(layout.selected || []);

    useEffect(() => {


        if (menu && menu.model && menu.model.id) {
            loadConfiguration(menu.model.id);
        } else {
            // setColumns(cols);
        }

    }, []);


    /**
   * Load the configuration
   */
    function loadConfiguration(id) {

        var queries = [{
            field: 'model_id',
            value: id
        }]

        return Columns.get(queries).then((result) => {

            setColumns(result.columns)
        })
    }

    /**
     * Add the column to the selected
     * 
     * @param {*} column 
     */
    function selectColumn(column) {

        selected.push({
            caption: column.caption,
            field: column.field
        });

        setSelected([...selected]);
    }

    /**
     * Remove the column
     * 
     * @param {*} column 
     * @param {*} key 
     */
    function removeColumn(column, key) {

        selected.splice(key, 1);

        setSelected([...selected]);
    }

    /**
     * 
     */
    function applyChanges() {

        let params = {
            selected,
            menu_id: menu.id,
        }

        if (layout.id) {

            UserPreferences.update(layout.id, params).then(() => {

                setVisible(false);

                refresh();

            })

        } else {


            UserPreferences.add(params).then(() => {

                setVisible(false);

                refresh();

            })

        }
    }

    /**
     * Close the modal
     */
    function closeModal(refresh) {

        setVisible(!visible);

    }

    // Selected Ids shown on the right
    let selected_ids = selected.map((record) => record.field);

    // Remaining are the available columns
    let available_columns = columns.filter((record) => selected_ids.indexOf(record.field) === -1)

    return (
        <section className="table-settings">

            <Button onClick={() => { setVisible(!visible) }} type="secondary" size={'small'}>
                <TableOutlined />
            </Button>

            {/* Add Modal */}
            <Modal className="table-settings-modal" width={'80%'} destroyOnClose confirmLoading={loading} title="Configure" visible={visible} onCancel={() => { closeModal() }} footer={null}>

                <div className="content">

                    <div className="card">
                        <List
                            size={'small'}
                            header={<strong>Available</strong>}
                            // footer={<div>Footer</div>}
                            bordered
                            dataSource={available_columns}
                            renderItem={item => (
                                <List.Item className="list-item">

                                    <span>
                                        {item.caption}
                                    </span>


                                    <Button onClick={() => {
                                        selectColumn(item)
                                    }} type="secondary" size={'small'}>
                                        <SelectOutlined />
                                    </Button>


                                </List.Item>
                            )}
                        />
                    </div>

                    <div className="card">

                        <List
                            size={'small'}
                            header={<strong>Selected</strong>}
                            // footer={<div>Footer</div>}
                            bordered
                            dataSource={selected}
                            renderItem={(item, key) => (
                                <List.Item className="list-item">
                                    {item.caption}

                                    <Button onClick={() => {
                                        removeColumn(item, key)
                                    }} type="secondary" size={'small'}>
                                        <DeleteOutlined />
                                    </Button>

                                </List.Item>
                            )}
                        />
                    </div>
                </div>

                <div className="modal-footer">

                    <div>
                        <Button onClick={() => {

                        }} type="secondary">

                            Cancel
                        </Button>

                        <Button onClick={() => {

                            applyChanges();

                        }} type="primary">
                            Submit
                        </Button>
                    </div>

                </div>
            </Modal>

            {/* Add Modal Ends */}
        </section>
    );
}

export default TableSettings;

