
import React, { useState, useContext } from "react"

import { Form, Button, Input, message, Row, Typography, Col } from 'antd';

import { withRouter } from 'react-router-dom'

import GenericHeader from './../../components/header/generic-header';

import './register.scss'

import { GlobalContext } from './../../Store';

import { Users } from "../../models";


const layout = {
    labelCol: { span: 8 },
    wrapperCol: { span: 8 },
};

const tailLayout = {
    wrapperCol: { span: 8 },
};

const { Title } = Typography;


function Register({ history, appSettings }) {

    const { brandLogo, registerImage, footerLogo } = appSettings;

    const { user, dispatch } = useContext(GlobalContext);

    const [loading, setLoading] = useState(false);

    const onFinish = async values => {

        setLoading(true);

        const result = await Users.registerUser(values);

        let params = {
            ...values,
            active: false
        }

        const user_result = await Users.addDocument(result.uid, params);

        message.success('User created');

        setLoading(false);

        if (result.id) {

            history.push('/');

        } else {

            message.error('No accounts found.');

        }
    };

    const onFinishFailed = errorInfo => {
        console.log('Failed:', errorInfo);
    };


    return (
        <section className="register-page">

            <Row className="page-wrapper">
                <Col>
                    <>
                        <span
                            className="toggle-box"
                            onClick={() => {
                                history && history.goBack();
                            }}
                        >
                            <GenericHeader />
                        </span>
                    </>
                </Col>

            </Row>


            <div className="auth-form-wrapper">

                <div className="brand-logo">
                    <img className="logo-welcome" src={brandLogo} alt="Logo" />
                </div>

                <div className="form-title">
                    <h3>Welcome</h3>

                    <p>Good day !</p>
                </div>

                <div>

                </div>

                <img className="vehicle-image" src={registerImage} alt="Logo" />

                <Form
                    {...layout}
                    layout='vertical'
                    name="basic"
                    // initialValues={{ email: 'afil.ahammed@gmail.com', password: '123456' }}
                    onFinish={onFinish}
                    onFinishFailed={onFinishFailed}
                >

                    <Form.Item
                        label="Name"
                        name="name"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Phone"
                        name="phone"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input />
                    </Form.Item>


                    <Form.Item
                        label="Email"
                        name="email"
                        rules={[{ required: true, message: 'Please input your email!' }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label="Password"
                        name="password"
                        rules={[{ required: true, message: 'Please input your password!' }]}
                    >
                        <Input.Password />
                    </Form.Item>

                    {/* <Form.Item {...tailLayout} name="remember" valuePropName="checked">
                        <Checkbox>Remember me</Checkbox>
                    </Form.Item> */}

                    <Form.Item {...tailLayout}>
                        <Button loading={loading} type="primary" htmlType="submit">
                            Submit
                        </Button>
                    </Form.Item>
                </Form>
                <p style={{ marginTop: '20px' }}>
                    👋 For a healthy tomorrow . <small>{process.env.REACT_APP_package_version}</small>

                </p>

                <div className="sidebar-footer">

                    {/* <img className="footer-logo" src={process.env.REACT_APP_landingFooter} alt={'user photograph'} /> */}

                </div>


            </div>
        </section>
    );
}

export default withRouter(Register)