import React, { useState, useEffect, useContext } from 'react';

import { Route, Switch } from 'react-router-dom';

import { Skeleton } from 'antd';

import GlobalHeader from '../../../core/components/global-header/global-header';

import MenuTemplate from '../menu-template/menu-template';

import './landing.scss';

import { GlobalContext } from './../../Store';

import HomePage from './../../../core/pages/homepage/homepage';

import ModuleRoutes from './../../../core/modules/modules-routes/module-routes';

import TaskList from './../../../core/models/process/components/task-list/task-list';

import TaskDetail from './../../../core/models/process/components/task-detail/task-detail';

import { ChangePassword } from '../..';

import ApprovalList from './../../components/approval-list/approval-list';

import ApprovalForm from './../../components/approval-form/approval-form';

import TaskForm from './../../../core/models/process/components/task-form/task-form';

// import WaitingRoom from '../../../business/common/components/waiting-room/waiting-room';

// import Twilio from '../../../business/common/components/twilio/twilio';

import Profile from '../../../core/pages/profile/profile';


// Maintaining a temporary task routes compeontns for mapping tests
import TaskRoutes from './../../models/process/components/task-routes/task-routes.js'



import {
    Models,
    Pages,
    Menus,
    Roles,
    Permissions,
    Process,
    // Appointments,
    Users,
    Modules,
    LookupTypes,
    Forms,
    Steps,
    Actions,

    FinancialYears,
    Branches,
    Departments,
    InvoiceNumbers

}
    from '../../../core/models';

// import { Questions, } from '../../../models';

import { message } from 'antd';

/**
 * Landing file for
 *
 * @param {*} props
 * @returns
 */
export default function Landing({ history, steps = {}, CustomModels, CustomComponents, appSettings }) {
    //
    const [tasks, setTasks] = useState(null);

    const [loader, setLoader] = useState(true);

    const [modules, setModules] = useState([]);

    const [connected, setConnected] = useState();

    const { user = {}, dispatch } = useContext(GlobalContext);

    // dispatch({})
    const [meta, setMeta] = useState({});

    var config = {};

    const { Questions } = CustomModels;

    useEffect(() => {

        setLoader(true);

        loadMenus();

        if (user.role && user.role.name) {

            var role = user.role.name.toLowerCase();

            // 
            if (steps[role] && steps[role].config) {

                setTasks(steps[role]);
            }
        }

        setLoader(false);


    }, []);

    /**
     * 
     */
    const loadMenus = async () => {

        const result = await Menus.getMenus(user);

        console.log(result);

        setModules(result);

        // setLoader(false);
    }


    let core_modules = [{
        caption: 'Preferences',
        sub_menus: [{
            path: '/change-password',
            caption: 'Change Password'
        }]
    }]

    if (user.isAdmin) {
        core_modules = Menus.getCoreMenus();
    }

    return (
        <section className="landing">

            <GlobalHeader loading={loader} appSettings={appSettings} meta={meta} isConnected={connected} reload={loadMenus} modules={[...modules, ...core_modules]} user={user} history={history}>


                {
                    loader
                        ?
                        <div className='card'>
                            <Skeleton />
                        </div>
                        :
                        <>

                            {/* <Switch> */}

                            {/* {tasks && <Route exact key={'home'} path={'/'} render={(props) => <HomePage steps={tasks} config={config} {...props} />} />}
 */}

                            <Route exact key={'home'} path={'/'} render={(props) => <HomePage steps={tasks} config={config} {...props} />} />


                            <Route exact key={'profile'} path={'/profile'} render={(props) => <Profile {...props} />} />

                            {/* <Switch> */}

                            {/* Modules and Menus */}

                            {/* <Route key={'rest-routes'} path={'/'} render={(props) => {


                                return <DefineRoute menus={modules} CustomModels={CustomModels} CustomComponents={CustomComponents} callback={(props) => {

                                    setMeta({
                                        ...props
                                    })

                                }} />
                            }

                            } /> */}

                            {/* <Switch> */}
                            {modules.length && <Route key={'landing-page'} path={''} render={(props) => <DefineRoute menus={modules} CustomModels={CustomModels} CustomComponents={CustomComponents} callback={(props) => {

                                setMeta({
                                    ...props
                                })

                            }} />} />}



                            {/* Modules and Menus Ends */}
                            {/* </Switch> */}

                            {/* Back Office Modules */}

                            {/* <Route key={'data-import'} path={'/candidates'} render={(props) => <ModuleRoutes model={Candidates} {...props} />} /> */}

                            {/* <Route key={'appointments'} path={'/appointments'} render={(props) => <ModuleRoutes model={Appointments} {...props} />} /> */}

                            {/* Back Office Modules Ends */}

                            {/* <Switch> */}
                            {/* Core Menus */}
                            {/* <Route key={'modules'} path={'/modules'} render={(props) => <ModuleRoutes model={Modules} {...props} />} /> */}

                            {/* Only Admins will have access to the below routes */}


                            {/* <Route path={'/requests?city=kozhikode'} render={() => {

                                return <h1>Hello</h1>
                            }} /> */}


                            {
                                user.isAdmin
                                    ?
                                    <>

                                        <Route path={'/menus'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Menus} />} />

                                        <Route path={'/models'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Models} />} />

                                        <Route path={'/pages'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Pages} />} />

                                        <Route path={'/roles'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Roles} />} />

                                        <Route path={'/permissions'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Permissions} />} />

                                        <Route path={'/users'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Users} />} />

                                        <Route path={'/questions'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Questions} />} />

                                        <Route path={'/lookup-types'} render={(routeParams) => <ModuleRoutes {...routeParams} model={LookupTypes} />} />

                                        <Route path={'/process'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Process} />} />

                                        <Route path={'/forms'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Forms} />} />

                                        <Route path={'/actions'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Actions} />} />

                                        <Route path={'/steps'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Steps} />} />

                                        {/* Routes Specific to Invoicing */}
                                        <Route path={'/financial-years'} render={(routeParams) => <ModuleRoutes {...routeParams} model={FinancialYears} />} />

                                        <Route path={'/branches'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Branches} />} />

                                        <Route path={'/departments'} render={(routeParams) => <ModuleRoutes {...routeParams} model={Departments} />} />

                                        <Route path={'/invoice-numbers'} render={(routeParams) => <ModuleRoutes {...routeParams} model={InvoiceNumbers} />} />
                                        {/* Routes Specific to Invoicing Ends */}


                                        {/* KIA Specific Models */}

                                    </>
                                    :
                                    <>
                                    </>
                            }


                            <Route path={'/list/:id'} render={(routeParams) => <TaskList {...routeParams} />} />

                            <Route path={'/task/:step_id'} render={(routeParams) => <TaskDetail {...routeParams} />} />


                            <Route path={'/change-password'} render={(routeParams) => <ChangePassword {...routeParams} />} />

                            {/* Maintaining listing page for different status */}
                            {tasks && <TaskRoutes steps={tasks.config} list={tasks.list} form={tasks.form} />}

                            {/* Only Admins will have access to the above routes */}

                            {/* Core Menus Ends */}

                            {/* </Switch> */}

                        </>}

            </GlobalHeader >
        </section >
    );
}


/**
 * Define route
 * 
 * @param {*} param0 
 * @returns 
 */
function DefineRoute({ menus, CustomComponents, CustomModels, callback }) {

    // console.log(menus);

    const [routes, setRoutes] = useState([]);

    useEffect(() => {

        buildRoutes(menus);

    }, [])

    async function buildRoutes(menus) {

        {/* If it has sub Menus */ }
        // {menu.sub_menus && menu.sub_menus.length ? <DefineRoute key={menu.id} menus={menu.sub_menus} CustomModels={CustomModels} CustomComponents={CustomComponents} callback={callback} /> : null}

        // console.log(menus);

        let dynamicRoutes = prepareRoutes(menus)

        // console.log(dynamicRoutes);

        setRoutes(dynamicRoutes);
    }


    let dynamic = [];

    function prepareRoutes(menus) {

        (menus.forEach(async (menu) => {

            dynamic.push(menu);

            if (menu.sub_menus.length) {

                return prepareRoutes(menu.sub_menus)

            }
        }))

        return dynamic;
    }


    function printRoute(menu, index, CustomComponents, CustomModels, callback) {

        // From the path we remove any query parameters that exist in the url
        //  
        let paths = menu.path.split('?');

        // Find the route path
        let path = paths[0];

        // console.log(path);

        return (
            <Route
                // exact
                key={index}
                path={path}
                render={(props) => {

                    return <MenuTemplate
                        menu={menu}

                        {...menu.attributes}

                        {...props}
                        CustomModels={CustomModels}
                        CustomComponents={CustomComponents}
                        callback={(props) => {
                            callback(props);
                        }} />;
                }}

            />
        );
    }




    return (<>


        <Switch>


            {
                routes.map((menu, index) => {

                    return printRoute(menu, index, CustomComponents, CustomModels, callback)

                })
            }

        </Switch>

    </>
    )

}