

import 'antd/dist/antd.css'


import './common.scss';

// For the Date Range picker
// import 'bootstrap/dist/css/bootstrap.css';

import 'bootstrap-daterangepicker/daterangepicker.css';

// For internationalization
import './i18n'
// For the Date Range picker

// import CoreElements from './core/'
// 
export * from './core/';

import { Location } from './core/'

export default {
    Location
}


// var elements = require('./core/')

// import Globalheader from './core/components/global-header/global-header';


// function log(msg) {
//     console.log(msg);

//     console.log("New changes")

// };


// exports * from './core/';

// module.exports = { log, Globalheader };