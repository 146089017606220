

import React from "react";

import Base from './../base';

import { Link } from 'react-router-dom';

import UserAdd from './components/user-add/user-add';

import { Tag } from 'antd';


class Users extends Base {

    constructor() {

        super()

        this.fields = [{
            field: 'name',
            caption: 'Name'
        }, {
            field: 'place',
            caption: 'Place'
        }, {
            field: 'phone',
            caption: 'Phone'
        }, {
            field: 'active',
            caption: 'Active',
            type: 'boolean'
        }]

        // this.columns = [];
    }

    get getEndpoint() {
        return 'users'
    }

    get path() {
        return `users`
    }

    get getName() {
        return `users`
    }

    create = (id, values) => {

        return this.addDocument(id, values);

    }


    get columns() {

        return [
            {
                caption: 'Name',
                field: 'name',
                key: 'name',
            },


            {
                caption: 'Email',
                field: 'email',
                key: 'email',
            },

            {
                caption: 'Phone',
                field: 'phone',
                key: 'phone',
            },

        ];
    }

    /**
     * 
     * @returns 
     */
    registerUser = (body) => {
        return this.loadFunction('createUser', body);
    }


    AddComponent = (props) => <UserAdd model={this} {...props} />;

    ModalAddComponent = (props) => <UserAdd model={this} {...props} />;

    /**
 * Card Component for Staff
 *
 * @param {*} record
 * @returns
 */
    Card = ({ record, redirect_to = 'users' }) => {
        return (
            <Link className="booking-card" to={`/${redirect_to}/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.name}</h2>

                    <h3 className="title">{record.weight}</h3>

                    <p className="address">
                        Created on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

}

export default Users;