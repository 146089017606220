/**
 * Schema required for implementing process
 */

import React from "react";


import Base from './../base';

import { Link } from 'react-router-dom'

import { Tag } from 'antd'

//  import FormDetail from './components/form-detail/form-detail';

import ActionDetail from './components/action-detail/action-detail';


class Action extends Base {

    constructor() {

        super()

        this.fields = [{
            field: 'caption',
            caption: 'Caption'
        }, {
            field: 'description',
            caption: 'Description'
        }, {
            field: 'identifier',
            caption: 'Identifier'
        }, {
            field: 'identifier',
            caption: 'Identifier'
        }, {
            field: 'active',
            type: 'radio',
            options: [true, false],
            caption: 'Active'
        }]

        // this.columns = [];
    }

    get getEndpoint() {
        return 'actions'
    }

    get path() {
        return `actions`
    }

    get getName() {
        return `actions`
    }


    get columns() {
        return [

            {
                caption: 'Name',
                render: (record) => {

                    return <Link className="booking-card" to={`/candidates/${record['id']}`}>
                        {record.name}
                    </Link>


                },
                key: 'created_at',
            },


            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },

            {
                caption: 'Identier',
                field: 'identier',
                key: 'identier',
            },

        ];
    }

    Card = (record) => {

        return (<Link className="booking-card" to={`sales/${record['id']}`}>
            <div className="card">
                <h2 className="">

                    {record['amount']}

                </h2>
                <h3 className="head">

                    {record['name']}

                </h3>

                <h3 className="title">

                    {record['title']}

                </h3>

                <p className="address">
                    Booked on {record['Booking Date']}
                    <Tag color="magenta">{record['Consultant Name']}</Tag>
                </p>

            </div>
        </Link>)
    }

    DetailComponent = ({ model }) => <ActionDetail model={model} />

    // AddComponent = ({ model }) => <ProcessAdd model={model} />

}

export default Action;