import React, { useState, useContext } from "react";

import { Form, Input, message, Result } from "antd";

import { withRouter, Link } from "react-router-dom";

import OTPInput from "otp-input-react";

import { Button } from "../../elements";

import "./login.scss";

// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// import { faCheck } from "@fortawesome/free-solid-svg-icons";

import FirebaseUtils from "../../../core/utils/firebase.utils";

import ApiUtils from "../../utils/api/api.utils";

import { GlobalContext } from "./../../Store";

const layout = {
  labelCol: { span: 24 },

  wrapperCol: { span: 24 },
};

const tailLayout = {
  wrapperCol: { span: 8 },
};

function LoginPhone({ history, appSettings }) {
  const { brandLogo, heroImage, footerLogo, headers } = appSettings;

  const { dispatch } = useContext(GlobalContext);
  // variabel used for btnloading

  const [loading, setLoading] = useState(false);
  // variable used to show otp generating

  const [otpVisible, setOtpVisible] = useState(false);
  // variabale used to show otp entering screen

  const [otpVerification, setotpVerification] = useState(false);

  const [otpValue, setOtpValue] = useState("");
  // setting user credentials
  const [user, setUser] = useState([]);


  const onFinish = (values) => {
    setLoading(true);

    if (process.env.REACT_APP_PRIMARY_BACKEND === "SQL") {
      let formBody = {
        username: values.email,

        password: values.password,
      };

      return ApiUtils.post({ url: "auth/login", formBody, headers })

        .then((result) => {
          setLoading(false);

          const { user, access_token } = result;

          localStorage.access_token = access_token;

          if (result.success) {
            //two_factor_authentication variable is present then proceed Two factor authentication 
            if (result.data && result.data.two_factor_authentication) {
              let data;
              // set variable used for otp send screen
              setOtpVisible(true);
              // set user credentials
              data = {
                username: result.data.email,
                password: values.password,
              };
              // set user values 
              setUser(data);
            } else {
              let d = user;

              let userInfo = {
                id: user.id,
                locations: [],
                ...d,
                ...{
                  loggedCheckDone: true,
                },
              };

              dispatch({ type: "user", payload: userInfo });

              localStorage.setItem("userInfo", JSON.stringify(userInfo));
              history.push("/");
            }
          } else {
            message.error(result.message);
          }
        })
        .catch((error) => {
          console.log(error);

          message.error("This operation has failed.");

          setLoading(false);
        });
    } else {
      FirebaseUtils.loginWithEmail(values)

        .then((result) => {
          setLoading(false);

          if (result.id) {
            if (result.active) {
              let d = result;

              let userInfo = {
                id: result.id,
                locations: [],
                ...d,
                ...{
                  loggedCheckDone: true,
                },
              };

              if (result.role && result.role.name === "admin") {
                userInfo.isAdmin = true;
              }

              dispatch({ type: "user", payload: userInfo });

              localStorage.setItem("userInfo", JSON.stringify(userInfo));

              history.push("/admin/");
            } else {
              message.error(
                "Your account is inactive, Please contact support."
              );
            }
          } else {
            message.error("No accounts found.");
          }
        })
        .catch((result) => {
          console.log(result);

          setLoading(false);

          message.error("Login Failed");
        });
    }
  };

  /**
   *
   * Function to implement 2 factor authentication on login
   *
   */
  const sendAuthenticationOtp = () => {
    setLoading(true)
    // set formbody 
    let formBody;
    formBody = {
      email: user.username,
    };

    // api used to send otp corresponding mail address
    return ApiUtils.post({
      url: "auth/send-authentication-otp",
      formBody,
      headers,
    }).then((result) => {
      // if the api is sucess then go for otpverification step 
      if (result.success) {
      // if the api is sucess then go for otpverification step 
        setotpVerification(true);
        // set  button loading false 
        setLoading(false)
      } else {
        setLoading(false)
        message.error(result.message);
      }
    });
  };

  /**
   * Function to implement 2 factor authentication verification
   * @returns
   */

  const verifyOtp = () => {
    setLoading(true)
    let formBody;
    // set formBody
    formBody = {
      username: user.username,
      password: user.password,
      otp: otpValue,
    };
    // verifying is otp is correct or not
    return ApiUtils.post({
      url: "auth/verify-authentication-otp",
      formBody,
      headers,
    }).then((result) => {
      if (result.success) {
        setLoading(false)
        let d = result.user;
       // set user info 

        let userInfo = {
          id: result.user.id,
          locations: [],
          ...d,
          ...{
            loggedCheckDone: true,
          },
        };

        dispatch({ type: "user", payload: userInfo });
       // set user info into local storage
        localStorage.setItem("userInfo", JSON.stringify(userInfo));
        history.push("/");
      } else {
        setLoading(false)
        message.error(result.message);
      }
    });
  };

  const onFinishFailed = (errorInfo) => {
    console.log("Failed:", errorInfo);

    setLoading(false);

    setOtpVisible(false);
  };

  /**
   * otp onange
   * @param {*} value 
   */
  const handleOtpChange = (value) => {
    setOtpValue(value);
  };

  /**
   * Otp resend Logic
   */
  const handleResendOTP = () => {
    // Resend OTP logic
    setOtpVisible(true);
    setotpVerification(false);
  };

  /**
   * Cancel  otp send process 
   */
  const handlecancel=() =>{
    setOtpVisible(false);
    setotpVerification(false);
  }

  
  return (
    <section className="user-authentication-section">
      {/* Background Section */}
      <div className="page-background"></div>
      {/* Background Section Ends */}

      <div className="auth-form-wrapper">
        <div className="card login-form-container">
          <div className="brand-logo">
            <img className="logo-welcome" src={brandLogo} alt="Logo" />
          </div>

          <div className="otp-form">
            {/* Two factor authentication section start */}
            {otpVerification ? (
              <div className="otp-input-container">
                <div className="form-title">
                  <h3>Verify your account</h3>
                  <p>
                    An OTP has been sent to your Email<br></br>
                  </p>
                </div>
                <p className="otp-title">ENTER OTP</p>
                <div className="otp-container">
                  <OTPInput
                    value={otpValue}
                    onChange={handleOtpChange}
                    autoFocus
                    OTPLength={6}
                  />
                </div>
                <Button
                  type="primary"
                  className="SubmitBtn"
                  onClick={verifyOtp}
                >
                  <i class="fa-regular fa-circle-check"></i>&nbsp;&nbsp; Verify
                  OTP
                </Button>
                <Link className="resend-otp-link" onClick={handleResendOTP}>
                  <i class="fa-solid fa-arrows-rotate"></i>&nbsp;&nbsp; Resend
                  OTP
                </Link>
                {/* Two factor authentication section end */}
              </div>
            ) : otpVisible ? (
              <div className="otp-input-container">
                {/* Two factor authentication otp verification section start  */}
                <div className="form-title">
                  <h4>Two Factor Authentication</h4>
                  <p>
                    To enhance the security of your account .We have implemented Two-Factor Authentication
                    <br></br>
                  </p>
                  &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                </div>

                <div className="otp-container">
                  <Button
                    type="primary"
                    className="SubmitBtn"
                    onClick={sendAuthenticationOtp}
                    loading={loading}
                  >
                    Send OTP
                  </Button>
                  &nbsp;&nbsp;
                  <Button
             
                  onClick={handlecancel}
                  >
                    Cancel
                  </Button>
                  &nbsp;&nbsp;
                </div>
                {/* Two factor authentication otp verification section end  */}
              </div>
            ) : (
              <Form
                {...layout}
                layout="vertical"
                name="basic"
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
              >
                <div className="form-title">
                  <h4>Login to your account</h4>
                  <p>Your work assistant for the day!</p>
                </div>
                {heroImage && (
                  <img className="customers" src={heroImage} alt="Logo" />
                )}

                <Form.Item
                  label="Email"
                  name="email"
                  rules={[
                    { required: true, message: "Please input your email!" },
                  ]}
                >
                  <Input autoComplete="off" />
                </Form.Item>

                <Form.Item
                  label="Password"
                  name="password"
                  rules={[
                    { required: true, message: "Please input your password!" },
                  ]}
                >
                  <Input.Password autoComplete="off" />
                </Form.Item>

                <Form.Item {...tailLayout}>
                  <Button
                    loading={loading}
                    type="primary"
                    htmlType="submit"
                    className="SubmitBtn"
                  >
                    {/* <FontAwesomeIcon icon={faCheck} /> */}
                    &nbsp;&nbsp;
                    {/* <span>
                      <img src="../assets/tick-icon.png" alt="Tick Icon" />
                    </span> */}
                    Submit
                  </Button>
                </Form.Item>
              </Form>
            )}
          </div>
        </div>

        {/* <div className="center-line"> */}
        {/* <Link to="/register">Create Account</Link> */}
        {/* <img className="footer-logo" src={footerLogo} alt={'user photograph'} /> */}
        {/* </div> */}

        {/* {heroImage && <img className="customers2" src={heroImage} alt="Logo" />} */}
      </div>
    </section>
  );
}

export default withRouter(LoginPhone);
