
import React from "react";


import { Base } from './../';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import MenuList from './components/menu-list/menu-list';

import MenuAdd from './components/menu-add/menu-add';

import MenuDetail from './components/menu-detail/menu-detail';

import { Models, Pages, MenuRoles } from '../../../core/models/';

import firebase from 'firebase/compat/app';


class Menus extends Base {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'amount',
                caption: 'Amount',
            },
        ];
    }

    get getEndpoint() {
        return 'menus';
    }

    get path() {
        return `menus`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `menu`;
    }

    get columns() {
        return [
            {
                caption: 'Name',
                field: 'name',
                key: 'name',
            },

            {
                caption: 'Module',
                field: 'module.name',
                key: 'module',
            },

            {
                caption: 'Path',
                field: 'path',
                key: 'path',
            },

            {
                caption: 'Order',
                field: 'order',
                key: 'order',
            },



            {
                caption: 'Model',
                field: 'model.name',
                key: 'model',
            },

            {
                caption: 'Model Path',
                field: 'model.path',
                key: 'model',
            },

            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Get the data from the table
     */
    get = (queries) => {
        return this.getMethod(queries).then((result) => {
            return Promise.all(result.menus.map((menu) => this.loadDetail(menu))).then((result) => {
                return { menus: result };
            });
        });
    };

    /**
     * Function to do necessary filtering before order
     * the array to display as a sidemenu
     * 
     * @param {*} menus 
     * @returns 
     */
    screenMenus = (menus) => {

        let arr = menus.filter((record) => {

            if (record.id) {

                if (record.is_visible) {
                    return true;
                } else {
                    return false;
                }

            } else {

                return true;
            }

        })

        return this.orderArray(arr, 'order')
    }


    getCoreMenus = () => {

        return [

            {

                caption: 'Configure',
                sub_menus: [

                    {
                        // is_visible: true,
                        path: '/process',
                        caption: 'Process',
                    },

                    {
                        path: '/models',
                        caption: 'Models',
                    },

                    {
                        path: '/pages',
                        caption: 'Pages',
                    },


                    {
                        path: '/forms',
                        caption: 'Forms',
                    },

                    {
                        path: '/actions',
                        caption: 'Actions',
                    },

                    {
                        path: '/permissions',
                        caption: 'Permissions',
                    },

                    {
                        path: '/questions',
                        caption: 'Questions',
                    },


                    {
                        path: '/lookup-types',
                        caption: 'Lookup Types',
                    },

                ],
            },

            {

                caption: 'Access Control',
                sub_menus: [
                    {
                        path: '/users',
                        caption: 'Users',
                    },

                    {
                        path: '/roles',
                        caption: 'Roles',
                    },

                    {
                        path: '/menus',
                        caption: 'Menus',
                    },
                ]
            },

            {

                caption: 'Settings',
                sub_menus: [
                    {
                        path: '/branches',
                        caption: 'Branches',
                    },

                    {
                        path: '/departments',
                        caption: 'Departments',
                    },

                    {
                        path: '/financial-years',
                        caption: 'Financial Years',
                    },
                    {
                        path: '/invoice-numbers',
                        caption: 'Invoice Numbers',
                    },

                ]
            },
            // {
            //     caption: 'Preferences',
            //     sub_menus: [{
            //         path: '/change-password',
            //         caption: 'Change Password'
            //     }]
            // }

        ];

    }


    /**
     * Get All the menus and submenus the user
     * has access to 
     */
    getMenus = async (user) => {

        // Get all the menus the role has access to 
        var queries = [{
            field: 'role_id',
            value: user.role.id
        }]

        const { menu_roles } = await MenuRoles.get(queries);

        // For the menu roles , get the actual menu record
        const menus = await Promise.all(menu_roles.map(async (menu) => {

            const result = await this.getRecord(menu.menu_id);

            return this.loadDetail(result)

        }))

        // var queries = [{
        //     field: firebase.firestore.FieldPath.documentId(),
        //     operator: 'in',
        //     value: menu_roles.map((menu) => menu.menu_id)
        // }]

        // const { menus } = await this.get(queries)

        let filtered = menus.filter((menu) => {

            return !menu.deleted_at

        })

        return this.prepareSideMenus(filtered)
        // return this.prepareSideMenus(menus.filter((record) => record.is_visible))

    }

    /**
     * Function to load the
     */
    loadMenu = () => {

    }




    /**
     * Prepare the sidemenus
     * 
     * @param {*} menus 
     * @returns 
     */
    prepareSideMenus = async (menus) => {

        let arr = [];

        const result = await Promise.all(menus.map(async (menu) => {

            if (menu.step === 1) {

                menu.caption = menu.name;

                menu.sub_menus = await this.findSubMenus(menu, menus)

                arr.push(menu);

            }
        }))

        return arr;
    }

    /**
     * Recursive function that is supposed to find all the submenus
     * 
     * @param {*} submenu 
     * @param {*} menus 
     */
    findSubMenus = async (submenu, menus) => {

        let arr = [];

        return Promise.all(menus.map(async (menu) => {

            if (menu.header_id === submenu.id) {

                const matchingMenu = menu;

                matchingMenu.caption = menu.name;

                matchingMenu.sub_menus = await this.findSubMenus(menu, menus);

                arr = [].concat(arr, matchingMenu);

            }

        })).then(() => {

            return arr;

        })
    }

    /**
     * load the detail of menu
     *
     * @param {*} menu
     * @returns
     */
    getDetail = (menu) => {
        return this.getRecord(menu).then((result) => {
            return result;
        });
    };

    /**
     * Function to load the menus
     *
     * @param {*} item
     * @returns
     */
    loadMenus = (item) => {
        return this.get().then((result) => {
            return Promise.all(result.menus.map(this.loadDetail)).then((result) => {
                return { menus: result };
            });
        });
    };

    /**
     * Load the related model and the page
     *
     * @param {*} item
     * @returns
     */
    loadDetail = (item) => {

        if (item.model_id) {

            return Models.getRecord(item.model_id)
                .then((result) => {


                    return {
                        ...item,
                        attributes: item.attributes || {},
                        caption: item.name,
                        model: result,
                    };
                })
                .then((item) => {

                    if (item.page_id) {

                        return Pages.getRecord(item.page_id).then((result) => {
                            return {
                                ...item,
                                page: result,
                            };
                        });

                    } else {

                        return {
                            ...item
                        }
                    }
                });

        } else {

            if (item.page_id) {

                return Pages.getRecord(item.page_id).then((result) => {
                    return {
                        ...item,
                        attributes: item.attributes || {},
                        caption: item.name,
                        page: result,
                    };
                });


            } else {

                return new Promise((resolve) => {

                    resolve({
                        attributes: {},
                        ...item,
                        caption: item.name,
                    });


                    return {
                        ...item,
                        attributes: item.attributes || {},
                        caption: item.name,
                    }
                })
            }
        }
    };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/menus/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.name}</h2>

                    <h3 className="title">{record.weight}</h3>

                    <p className="address">
                        Created on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    ListComponent = (props) => <MenuList model={this} {...props} />

    ModalAddComponent = (props) => <MenuAdd model={this} {...props} />;

    EditComponent = (props) => <MenuAdd {...props} edit={true} model={this} />;

    DetailComponent = () => <MenuDetail model={this} />;
}

export default Menus;
