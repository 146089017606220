
import React from "react";


import { Base } from '..';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

// import Staffs from '../../../models/staffs';

class Pages extends Base {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'description',
                caption: 'Description',
            },
            {
                field: 'path',
                caption: 'Path',
            },
            {
                field: 'identifier',
                caption: 'Identifier',
            },

        ];

        // this.columns = ;
    }

    get getEndpoint() {
        return 'pages';
    }

    get path() {
        return `pages`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `page`;
    }

    get columns() {
        return [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'description',
                caption: 'Description',
            },
            {
                field: 'path',
                caption: 'Path',
            },

            {
                field: 'identifier',
                caption: 'Identifier',
            },
        ];
    }


    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/collections/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.name}</h2>

                    <p className="">{record.description}</p>

                    <p>
                        <small className="">{record.path}</small>{' '}
                    </p>

                    <p className="address">
                        Created by Admin on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };
}

export default Pages;
