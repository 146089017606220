
/**
 * Camera Component would save the photo with location
 */

import React, { useState, useEffect, useContext } from "react";

import { Typography, Button, message } from 'antd';

import { ScanOutlined, LoadingOutlined } from '@ant-design/icons';

import './finger-print-reader.scss';

const { Title } = Typography;

export default function FingerPrintReader({ value, onChange, scanMode, template }) {

    // Response
    const [response, setResponse] = useState(value);

    const [loading, setLoading] = useState(false);

    /**
     * Function that starts / restarts scanning
     */
    async function startScanning() {

        if (scanMode) {

            const result = await readFingerPrint()

            console.log(result.TemplateBase64, template)

            if (result.TemplateBase64 && template) {

                const confirm = await compareTemplate(result.TemplateBase64, template)

                console.log(confirm);

            } else {

                message.error("Failed to read");

            }

        } else {

            const result = await readFingerPrint()

            onChange(`${result.TemplateBase64}`)

        }
    }

    /**
     * Read the fingerprint
     */
    async function readFingerPrint() {

        setLoading(true);

        let formBody = {
            Timeout: 10000,
            Quality: "50",
            // "Licstr": "123hE/78I5oOUJnm5fa5zDDRrEJb5tdqU71AVe+/Jc2RK0=",
            "templateFormat=": "ISO",
            "imageWSQRate=": "0.75",
        };

        // return ApiUtils.post({ url: "https://localhost:8443/SGIFPCapture", formBody })
        return fetch("https://localhost:8000/SGIFPCapture", {
            headers: {
                // 'Content-Type': 'application/json',
            },
            method: 'POST',
            body: formBody
        })
            .then((res) => res.json())
            .then((json) => {

                console.log(json);

                setResponse(json);

                setLoading(false);

                // #Note Disabling upload here so that we save unwanted file uplaods
                // The file needs to be manually uploaded to the sytem 
                // onChange(`data:image/png;base64,${json.BMPBase64}`);

                // onChange(`${json.TemplateBase64}`)
                return json;
                // return Attachments.uploadBase64(`data:image/png;base64,${json.BMPBase64}`).then((result) => {

                //     console.log(result);

                //     onChange(result.url);

                //     setLoading(false);

                // })
            });
    }


    /**
     * Compare the template
    * 
    * @param {*} template1 
    * @param {*} template2 
    * @returns 
    */
    function compareTemplate(template1, template2) {

        let payload = "template1=" + encodeURIComponent(template1) + '&template2=' + encodeURIComponent(template2) + '&templateFormat=ISO';

        return fetch("https://localhost:8000/SGIMatchScore", {
            method: 'POST',
            body: payload,
        })
            .then((res) => res.json())
            .then((json) => {

                console.log(json);

                // message.info("Matching Score : ", json.MatchingScore)

                if (json.MatchingScore > 100) {

                    message.success("User Verified");

                    onChange(`${json.TemplateBase64}`)

                } else {

                    message.warning("User Rejected");

                }

                setLoading(false);

                return json;

            });
    }

    /**
     * 
     * @param {*} template1 
     * @param {*} template2 
     * @returns 
     */

    useEffect(() => {

        return () => {

        }

    }, [])

    return (<div className="finger-print-reader">

        <Title level={5}>
            Fingerprint Scanner
        </Title>

        {
            !loading
                ?
                <p>
                    Please place the fingerprint of the candidate to access the page.
                </p>
                :
                <p>

                    Device enabled to read the data
                    {/* <LoadingOutlined /> */}
                </p>}

        <div className="finger-print-container">

            <div className="finger-print-box">
                {
                    response
                        ?
                        <div className="image-container"><img src={`data:image/png;base64,${response.BMPBase64}`} /></div>
                        :
                        <div className="">

                            {loading ? <LoadingOutlined /> : <ScanOutlined />}

                        </div>}
            </div>

            {/* Extra Actions needs to be displayed only when there is data */}
            <div className="actions">

                <Button type={!loading ? 'primary' : 'secondary'} onClick={() => { startScanning(true); }}>
                    {
                        loading
                            ?
                            <>

                                <LoadingOutlined /> Loading
                            </>
                            :
                            'Read'
                    }
                </Button>

                {loading ? <>

                    <Button onClick={() => {
                        setLoading(false);
                    }}>
                        Cancel
                    </Button>

                </> : null}

                {
                    response
                        ? <>

                            <Button onClick={() => {

                                setResponse(null);

                                setLoading(false);

                            }}>
                                Clear
                            </Button>

                            <Button onClick={() => {

                                setResponse(false);

                                startScanning(true);

                            }}>
                                Retake
                            </Button>

                        </>
                        :
                        null
                }

            </div>

        </div>

    </div>)
}



// let formBody = {
//     // Timeout: 10000,
//     // Quality: "50",
//     template1: encodeURIComponent(template1),
//     template2: encodeURIComponent(template2),
//     // "Licstr": "hE/78I5oOUJnm5fa5zDDRrEJb5tdqU71AVe+/Jc2RK0=",
//     "templateFormat": "ISO",
//     // "imageWSQRate=": "0.75",
// };

// let payload = "template1=" + encodeURIComponent(template1) + '&template2=' + encodeURIComponent(template2) + '&templateFormat=ISO';

// // fetch("https://localhost:8000/SGIMatchScore", {
// //     method: 'POST',
// //     // body: new URLSearchParams(formBody),
// //     body: formBody,
// //     headers: {
// //         // 'Content-Type': 'application/json',
// //     },
// // })
// //     // return ApiUtils.post({ url: "https://localhost:8443/SGIFPCapture", formBody })
// //     .then((res) => res.json())
// //     .then((json) => {

// //         console.log(json);

// //         setResponse(json);

// //         setLoading(false);

// //         // #Note Disabling upload here so that we save unwanted file uplaods
// //         // The file needs to be manually uploaded to the sytem 
// //         // onChange(`data:image/png;base64,${json.BMPBase64}`);
// //         return json;
// //         // onChange(`${json.TemplateBase64}`)

// //     });