


/**
 * JSON Input Component
 */

import React from 'react';

import JSONInput from 'react-json-editor-ajrm';

import locale from 'react-json-editor-ajrm/locale/en';


//  import './card.scss';

/**
 * Use `Card` for Card you need to keep.
 */
export default function JSONInputComponent({
    className,
    onChange,
    value = {}
}) {


    const onCallback = (value, arg) => {

        console.log(value, arg);

        onChange(value.jsObject);
        // onChange()
    }



    return <div className={`card card-shadow ${className}`}>

        <JSONInput
            waitAfterKeyPress={800}
            onChange={onCallback}
            id='a_unique_id'
            placeholder={value}
            // colors={darktheme}
            locale={locale}
            height='505px'
            width='100%'
        />

    </div>;
}
