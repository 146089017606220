/**
 * Field Selector Component 
 * 
 * @description Component to replace any custom form that you require to have in the project
 * @author Ashique Mohammed
 * @version 0.0.1
 * @date 28/01/2023
 * 
 */

import React, { useEffect, useState, Fragment, useContext } from "react";

import { Models, LookupValues, LookupTypes } from '../../../../models';

import { Typography, Divider } from "antd";

import { Button } from "../../../../elements";

// import { ScriptInput } from './../../../../elements/'
import ScriptInput from './../../../../components/script-input/script-input';


import './field-selector.scss';

const { Title } = Typography;

/**
 * 
 * Field Selector
 * 
 * @param {*} param0 
 * @returns 
 */
export default function FieldSelector({ selectElement }) {

    const [types, setTypes] = useState([]);

    useEffect(() => {

        getColumnTypes();

    }, [])


    async function getColumnTypes() {

        var queries = [{
            field: 'name',
            value: 'Column Types'
        }]

        const result = await LookupTypes.get(queries)

        if (result['lookup-types'].length) {

            // setTypes(result['lookup-types'][0]);

            getLookup(result['lookup-types'][0])
        }
    }

    /**
     * 
     */
    async function getLookup(record) {

        var queries = [{
            field: 'lookup_type_id',
            value: record.id
        }]

        const result = await LookupValues.get(queries)

        console.log(result);

        if (result['lookup_values'].length) {

            setTypes(result['lookup_values']);
        }

    }

    return (<div className="field-selector card card-shadow">

        <Title level={5}>Form Inputs</Title>

        <div className="selector-box">

            {
                types.map((type) => {

                    return (<div className="field-element" onClick={() => {
                        selectElement(type);
                    }} >

                        {type.name}

                        <Button size={'small'}>+</Button>

                    </div>)

                })
            }

        </div>

        <Divider />

        <div className="field-selector">

            <Title level={5}>Group</Title>

            <div className="selector-box">

                {
                    [{
                        name: 'Tabs',
                        value: 'Tabs',
                        type: 'Tabs',
                        tabs: [{
                            caption: 'Tab',
                            fields: []
                        }]
                    }].map((type) => {

                        return (<div className="field-element" onClick={() => {
                            selectElement(type);
                        }}>

                            {type.name}

                            <Button size={'small'}>+</Button>

                        </div>)

                    })
                }
            </div>

        </div>

        <Divider />

        <div>

            <Title level={5}>Listners</Title>

            <ScriptInput callback={(script) => {

                console.log(script);

            }} />

        </div>

    </div>)
}
