// /**
// * Upload file implementation
// * All upload calls are made through this method
// * @param  {object} obj - contains url, params(optional){proccessed and attached to url}, 
// * headers(optional)
// */
export async function UploadUtils(url, data) {

    return fetch(process.env.REACT_APP_endpoint + url, { // Your POST endpoint
        method: 'POST',
        headers: {
            //   'App-Type': 313,
            //   'App-Version': '1.0.1',
            Authorization: 'Bearer ' + localStorage.access_token,
            // type:'multipart/formData'
        },
        //   credentials: 'include',
        body: data
    }).then((result) => {

        return result.json();

    }, (error) => {
        console.log(error);
        return error;
    }, (progress) => {
        console.log(progress);
        return progress;
    })
}