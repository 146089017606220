/**
 * Component used for Table
 *
 * @author Ashique
 *
 * Ensure to follow a minimal standard
 */

import React, { useState, useEffect } from "react";

import TableData from "./components/table-data/table-data";

import TableActions from "./components/table-actions/table-actions";

import "./portlet-table.scss";

export default function PortletTable({
  columns = [],
  records = [],
  actions = [],
}) {
  useEffect(() => {
    adjustWidth();
  }, []);

  function adjustWidth() {
    // According to action width
    // width of table is assigned

    const tableBody = document.getElementsByClassName("portlet-table")[0];

    if (tableBody) {
      var bodyWidth = tableBody.clientWidth;
      var action = document.getElementsByClassName("actions-table")[0];

      var actionWidth = 0;

      if (action) {
        actionWidth = action.clientWidth;
      }

      var percent = bodyWidth - actionWidth;

      var tableContent = document.getElementsByClassName("table-data")[0];

      tableContent.setAttribute("style", "width:" + percent + "px");
    }
  }

  return (
    <div className="portlet-table">
      {/* Table Data */}
      <TableData columns={columns} records={records} />
      {/* Table Data Ends */}

      {/* Table Actions */}
      {actions.length ? (
        <TableActions records={records} actions={actions} />
      ) : null}
      {/* Table Actions Ends */}
    </div>
  );
}
