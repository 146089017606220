/**
 * 
 * Store as an initial local store for the app
 * 
 * All the data that is needed accross the app like user, openProperties etc goes from here.
 * 
 */

import React, { useEffect, useState, createContext, useReducer } from 'react';



/**
 * Initial State to be stored
 * accross the app
 * 
 * For now maintaining only user data 
 * 
 * #todo Compare the setup with state manager utility 
 * 
 */
const initialState = {
    menu: {}
};

/**
 * Context for sharing state accross app
 */
export const MenuGlobalContext = createContext(initialState);

let app = {};

/**
 *  Provider links the reducer and context
 */
export const GlobalMenuProvider = ({ children, menu }) => {

    // console.log(menu);

    // Store that is accessible accross the app
    // let store = { user: state.user, slot: state.slot, dispatch: dispatch, selectedLocation: state.selectedLocation }
    let store = {
        menu
    };

    return (<MenuGlobalContext.Provider value={store}>
        {children}
    </MenuGlobalContext.Provider>)

}

/**
 * Reducer for managing the state
 * 
 * @param state 
 * @param action 
 */
export const AppReducer = (state, action) => {

    switch (action.type) {


        case 'menu':

            return { ...state, menu: action.payload };

        default:
            return state;
    }
}