// <<<<<<< HEAD
// import React from 'react'

// import { Form as AntdForm } from 'antd'

// // import './button.scss'

// export default class Form extends React.Component {

//     render() {

//         const { children, ...props } = this.props;

//         return (<>

//             <AntdForm {...props}>

//                 {children}

//             </AntdForm >

//         </>)

//     }

// }

// export const Item = AntdForm.Item;
// =======
/**
 *
 * @author Sameena Ammed
 * This component exports antd form.
 * Here the components needed for antd form are all imported and form along with all those components are exported
 * This gives a custom form to be used in development
 *
 */

import React from "react";


// Import all the needed components from antd library
import InternalForm, { useForm } from "antd/lib/form/Form";

import Item from "antd/lib/form/FormItem";

import ErrorList from "antd/lib/form/ErrorList";

import List from "antd/lib/form/FormList";

import { FormProvider } from "antd/lib/form/context";

import "./form.scss";

// import 'antd/dist/antd.css';

// Assign all the imported components and form so that it can be exported
const Form = Object.assign(InternalForm, {
  useForm,
  Item,
  List,
  ErrorList,
  Provider: FormProvider,
  create: () => {
    console.warn("This method is deprecated and should not be used.");
  },
});

export default Form;
// >>>>>>> 64a2e254065eded31111b89ea538c10bb23a4cd3
