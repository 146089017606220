/**
 *
 * Final Entry for all the API calls
 *
 * All the common configurations should bypass httpUtils
 * Keep adding additional methods on requirement
 *
 */


import { message } from 'antd';

import FirebaseUtils from '../firebase.utils';

import { Location } from '../location/location.utils';

let headers = {
    // 'Accept': 'application/json',
    'Content-Type': 'application/json',
    // 'Cache-Control': 'no-cache',
    // 'X-Apartment-Id': 1,
};

/**
 * Method for Get Call
 *
 * @param {*} param
 * @public
 */
export async function GetData({ url, formBody, settings, ...props }) {
    // console.log(props);
    return ApiCall({ url, formBody, method: 'GET', settings, ...props });
}

/**
 * Method posts data to mentioned url
 *
 * @param {*} param
 * @public
 */
export async function PostData({ url, formBody, ...props }) {
    return ApiCall({ url, formBody, method: 'POST', returnResponse: true, ...props });
}

/**
 * Method for update the data
 * @public
 */
export async function PutData({ url, formBody, ...props }) {
    return ApiCall({ url, formBody, method: 'PUT', returnResponse: true, ...props });
}



/**
 * Method for update the data
 * @public
 */
export async function PatchData({ url, formBody, ...props }) {
    return ApiCall({ url, formBody, method: 'PATCH', returnResponse: true, ...props });
}


/**
 * Method for update the data
 * @public
 */
export async function DeleteData({ url, ...props }) {
    return ApiCall({ url, method: 'DELETE', returnResponse: true, ...props });
}


/**
 * Final Exit for API call
 *
 * @param {*} param
 */

export async function ApiCall({ url, formBody, method, settings, ...props }) {

    // var token = await FirebaseUtils.getToken();

    // var branch = this.getBranch();

    // This function is passed from the main application
    // Can either use localstorage to save token
    // Or use the Firebase Auth to get the

    // The variable token will assign the value of 'props.token' if it exists,otherwiae it will take value form the getToken()
    let token = props.token || await settings.getToken();

    let { headers: propsHeaders, disableErrorMessage } = props;

    return new Promise((resolve, reject) => {
        // Payload for the request
        let payload = {
            method: method,
            // mode:'no-cors',
            headers: {
                // mode:'no-cors',
                // db_ptr:'nura',
                ...settings.headers,
                // db_ptr: 'alsalama',
                // db_ptr: process.env.REACT_DB_PTR,
                ...headers,

                ...propsHeaders,
                // Authorization: 'Bearer ' + token,
                Authorization: 'Bearer ' + token,
            },
            body: formBody ? JSON.stringify(formBody) : null,
        };

        // let baseUrl = 'http://localhost:4000/dev/'

        // console.log(process.env.REACT_APP_endpoint);

        // let baseUrl = 'http://localhost:4000/dev/'

        var baseUrl = props.baseUrl || process.env.REACT_APP_endpoint;

        // let baseUrl = GLOBAL.API.API_BASE_URL

        return fetch(baseUrl + url, payload)
            .then((res) => {
                if ([400, 404].indexOf(res.status) !== -1) {
                    reject(res.json());

                    return res.json();
                } else {
                    return res.json();
                }
            })
            .then((res) => {
                if (res && (res.statusCode === 400 || res.statusCode === 500)) {
                    if (!props.hideError) {
                        message.error(JSON.stringify(res.message));
                    }

                    reject(res);
                } else {
                    resolve(res);
                }
            })
            .catch((error) => {
                message.error('Somethng went wrong');

                console.log(error);

                reject(error);
            });
    });
}
