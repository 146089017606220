
import React, { useState, useContext, useEffect } from "react";

import './launch-page.scss';


import ApplicationBootstrap from './../../../../core/components/application-bootstrap/application-bootstrap'


export default function BrandLaunchPage({ multiple, callback, maxSize = 3, initial = [] }) {

    useEffect(() => {

    }, [])



    return (<div className="brand-launch-page">


        {/* <ApplicationBootstrap>

            <h1>
                Hello
            </h1>

        </ApplicationBootstrap> */}

    </div>)
}

