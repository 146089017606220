/**
 * All the models in the application
 */

import Base from './base';

import BaseLoader from './base-loader';

import Menu from './menus/menus';

import Model from './models/components/models';

import Column from './columns/columns';

import Page from './pages/pages';

import Role from './roles/roles';

// import Module from './modules/modules';

import MenuRole from './menu-roles/menu-roles';

import Permission from './permissions/permissions';

import LookupType from './lookup-types/lookup-types';

import LookupValue from './lookup-values/lookup-values';

import Attachment from './attachments/attachments';

import User from './users/users';

// import Question from './questions/questions';/


// import QuestionAnswer from './question-answers/question-answers';

// import { QuestionAnswers } from '.';

import Comment from './comments/comments';

import UserPreference from './user-preferences/user-preferences';

import ProcessModel from './process/process';

import Step from './steps/steps';

import Checklist from './checklists/checklists';


// import StepForm from './step-forms/step-forms';

import Form from './forms/forms';

// import FormField from './form-fields/form-fields';

import ProcessTransaction from './process-transactions/process-transactions';

import StepTranscation from './step-transactions/step-transcations';

import Script from './scripts/scripts';


import Action from './actions/actions'

// const Modules = new Module();


// Models Related to Billing

import InvoiceNumber from './invoice-numbers/invoice_numbers';

import Branch from './branches/branches';

import FinancialYear from './financial-years/financial_years';

import Department from './departments/departments';

import OutboxModel from './outbox/outbox';


const Menus = new Menu();

const Models = new Model();

const Pages = new Page();

// const Modules = new Module();

const Roles = new Role();

const MenuRoles = new MenuRole();

const Permissions = new Permission();

const BaseLoaderModel = new BaseLoader();

const Columns = new Column();

const LookupTypes = new LookupType();

const Users = new User();

// const Questions = new Question();

// const QuestionAnswers = new QuestionAnswer();

const Attachments = new Attachment();

const Comments = new Comment();

const LookupValues = new LookupValue();

const UserPreferences = new UserPreference()

const Process = new ProcessModel();

const Steps = new Step();

const Checklists = new Checklist();

// const StepForms = new StepForm();

const Forms = new Form();

const ProcessTransactions = new ProcessTransaction();

const StepTransactions = new StepTranscation();

const Scripts = new Script();

const Actions = new Action();


const InvoiceNumbers = new InvoiceNumber();

const FinancialYears = new FinancialYear();

const Departments = new Department();

const Branches = new Branch();

const Outbox = new OutboxModel();

export {
    Base,
    BaseLoader,
    BaseLoaderModel,
    // Modules,
    Menus,
    Models,
    Pages,
    Roles,
    MenuRoles,
    Permissions,
    Columns,
    LookupTypes,
    LookupValues,

    // Business Related
    Users,

    // 
    // Questions,
    // QuestionAnswers,

    Attachments,

    Comments,

    UserPreferences,

    // Process Models
    Process,
    ProcessTransactions,

    Steps,
    Checklists,
    StepTransactions,

    // StepForms,
    // FormFields,

    Forms,

    Scripts,

    Actions,

    // Models for invoicing and registrations

    InvoiceNumbers,

    FinancialYears,

    Departments,

    Branches,

    Outbox

};

