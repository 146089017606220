/**
 * Permissions model to set permissions for the application
 *
 *
 * @author Ashique
 */

import React from "react";


import Base from './../base';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

class Permissions extends Base {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'description',
                caption: 'Description',
            },
        ];

        this.columns = [];
    }

    get getEndpoint() {
        return 'permissions';
    }

    get path() {
        return `permissions`;
    }

    get getName() {
        return `permissions`;
    }

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/permissions/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.name}</h2>

                    <h3 className="title">{record.description}</h3>

                    <p className="address">
                        Created on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };
}

export default Permissions;
