import React, { useState, useEffect, useContext } from 'react';

import { Link } from 'react-router-dom';

import { Typography, Statistic, Tabs, Badge, Button } from 'antd';

// import "./tasl-o.scss";

import { GlobalContext } from './../../../../Store';

import FirebaseUtils from './../../../../utils/firebase.utils';

import './task-overview.scss';

const { TabPane } = Tabs;

const { Title } = Typography;

let badges = {
    kozhikode: 'error',
    kannur: 'success',
    malappuram: 'processing',
};

var cityCode = {
    kozhikode: 'KL305',
    kannur: 'KL306',
    malappuram: 'KL307',
};

export default function TaskOverview({ steps = [] }) {

    const [loader, setLoader] = useState(true);

    // Cotent
    const [content, setContent] = useState([]);

    const { user } = useContext(GlobalContext);

    useEffect(() => {
        loadData(steps);
    }, []);

    function loadData(steps) {
        setLoader(true);

        // For each of the locations , we have to load the data of each step
        Promise.all(user.locations.map((location) => getStepData(steps, location)))
            .then((result) => {
                console.log(result);

                setContent(result);

                setLoader(false);
            })
            .then(() => { });
    }

    /**
     * Load the data
     */
    function getStepData(steps, location) {
        var data = {
            location: location,
            steps: [],
        };

        return new Promise((resolve, reject) => {
            return Promise.all(
                steps
                    .filter((step) => !step.isForm)
                    .map((step) => {
                        return loadLocationData(step, location).then((result) => {
                            data.steps.push({
                                step,
                                ...result,
                            });

                            // return {
                            //     location:location,
                            //     step:step,
                            //     result
                            // }
                        });
                    })
            ).then(() => {
                resolve(data);
            });
        });
    }

    /**
     * For the step load the location data
     *
     * @param {*} step
     * @param {*} url
     */
    function loadLocationData(step, url) {
        var queries = [];

        let code = cityCode[url];

        if (typeof step.queries === 'function') {
            queries = step.queries({ user, code });
        } else {
            queries = step.queries;
        }

        // var queries = step.queries({ code });

        if (step.path === '/inward_transfers') {
            url = null
        }

        return FirebaseUtils.getRecordsLegacy(url, queries, step.model || 'requests').then((result) => {
            // console.log(result);
            setLoader(false);

            return {
                // location: url,
                step,
                result: result[step.model || 'requests'],
            };
        });
    }

    return (
        <section className="task-overview">
            {!loader ? (
                <>
                    <div className="list-header">
                        <Title level={4}>Task Overview</Title>
                    </div>

                    {/* Content Begins */}

                    {content.map((dealer, index) => {
                        return (
                            <div key={index}>
                                <section className="dashboard dashboard-card">
                                    <div className="list-subheader">
                                        <Title level={5}>{dealer.location} Customers</Title>

                                        <div>{/* <Button onClick={getData} type="secondary" size={'small'}>Refresh</Button> */}</div>
                                    </div>

                                    <div>
                                        <div gutter={16} className="score">
                                            {dealer.steps.map((step, key) => {
                                                return step.submenus && step.submenus.length ? (
                                                    step.submenus.map((submenu) => <Score url={dealer.location} data={submenu} />)
                                                ) : (
                                                    <Score url={dealer.location} data={step} />
                                                );
                                            })}
                                        </div>
                                    </div>
                                </section>
                            </div>
                        );
                    })}

                    {/* Content Ends */}
                </>
            ) : null}
        </section>
    );
}

function Score({ data, url }) {
    const { step } = data;

    useEffect(() => {
        // getData();

        return () => { };
    }, []);

    // console.log(data);

    return (
        <>
            <Link className="card dashboard-item" to={`${step.path}`}>
                <Badge status={'success'} />

                <Statistic className="" title={step.caption} value={data.result.length} />

                {/* <small>{step.description}</small> */}
            </Link>
        </>
    );
}
