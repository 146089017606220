/**
 * Component used for Table 
 * 
 * @author Ashique
 * 
 * Ensure to follow a minimal standard
 */

import React, { useState, useEffect } from 'react';

import './table-data.scss';


export default function TableData({ columns = [], records = [] }) {

    return (<div className='table-data'><table className="table-body">

        <TableHeader columns={columns} />

        <tbody>

            {

                records.map((row, index) => {

                    return (<TableRow index={index} row={row} columns={columns} />)

                })}

        </tbody>
    </table></div>)

}

/**
 * The Table Header Element
 * 
 * @param {*} param0 
 * @returns 
 */
function TableHeader({ columns }) {

    return (
        <thead>
            <tr>
                {columns.map((column) => {

                    return (<TableHeaderColumn column={column} />)
                })}

            </tr>
        </thead>
    )
}


function TableHeaderColumn({ column }) {


    return (<th>
        {column.title}
    </th>
    )
}




/**
 * Table Row Element
 * 
 * @param {*} param0 
 * @returns 
 */
function TableRow({ row, columns, index }) {

    return (<tr>

        {
            columns.map((column, columnIndex) => {

                return (<TableField index={index} key={index} row={row} column={column} />)

            })
        }

    </tr>)
}


/**
 * 
 * 
 * @param {*} param0 
 * @returns 
 */
export function TableField({ row, column, index }) {

    // console.log(row, column);

    return (<td>

        {column.render ? column.render(row, column, index) : row[column.dataIndex]}

    </td>)

}