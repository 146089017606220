
import React from "react";

import { Base } from './../';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

// import CollectionAdd from '../../../business/accounts/components/collection-add/collection-add';

import MenuRolesAdd from './../menus/components/menu-roles-add/menu-roles-add';

import { Roles } from '../../../core/models/';

class MenuRole extends Base {
    constructor() {
        super();

        this.fields = [
            {
                field: 'name',
                caption: 'Name',
            },
            {
                field: 'description',
                caption: 'Description',
            },
        ];

        // this.columns = ;
    }

    get getEndpoint() {
        return 'menu_roles';
    }

    get path() {
        return `menu_roles`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `menu_role`;
    }

    disableAdd() {
        return false;
    }

    disableAddModal() {
        return false;
    }

    get columns() {
        return [
            {
                caption: 'Name',
                field: 'role.name',
                key: 'name',
            },
            {
                caption: 'Description',
                field: 'description',
                key: 'description',
            },
            {
                caption: 'Date',
                field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    /**
     * Get the data from the table
     */
    get = (queries) => {
        return this.getMethod(queries).then((result) => {
            return Promise.all(result.menu_roles.map(this.loadRoles)).then((result) => {
                return { menu_roles: result };
            });
        });
    };

    // Attach the related staff with the object
    loadRoles = (item) => {
        return Roles.getRecord(item.role_id).then((result) => {
            return {
                ...item,
                role: result,
            };
        });
    };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = (record) => {
        return (
            <Link className="booking-card" to={`/collections/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.role.name}</h2>

                    <h3 className="title">{record.weight}</h3>

                    <p className="address">
                        Collected on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    // AddComponent = () => <CollectionAdd model={this} staff={Staffs} />;

    ModalAddComponent = (props) => <MenuRolesAdd model={this} {...props} />;
}

export default MenuRole;
