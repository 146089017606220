import React, { useState, Suspense, useEffect, lazy, Component } from 'react';

import { BaseLoader } from './../../../core/models/';

import { GlobalMenuProvider } from './../menu-context/menu-context';

import { PageContainer } from '..';

import { FingerprintProtected } from '..';

import './menu-template.scss';

export default class MenuTemplate extends Component {

    constructor(props) {

        super(props);

        this.state = {
            loading: false,
            model: null,
            view: [],
            component: null,
        };

        this.loadedComponent = () => null;
        this.loadedModel = () => null;
        this.menuDetail = () => { };
    }

    /**
     * 
     */
    componentDidMount() {

        // console.log("Initialising menu", menu.name);

        var genericComponents = require('./../../modules/');

        var genericModels = require('./../../models/')

        const { menu, callback, CustomComponents, CustomModels } = this.props;

        var allComponents = {
            ...genericComponents,
            ...CustomComponents,
        }

        var allModels = {
            ...genericModels,
            ...CustomModels
        }

        let BaseModel = new BaseLoader(menu.model);

        if (menu && menu.page && ((menu.page.identifier) || (menu.page.path))) {

            let identifier = menu.page.identifier || menu.page.path;

            if (identifier && allComponents[identifier]) {

                this.loadedComponent = allComponents[identifier]

            }
        }


        if (menu && menu.model_id) {

            if (menu.model_id && allModels[menu.model.name]) {

                this.loadedModel = allModels[menu.model.name]

            } else {

                // BaseModel = this.loadedModel[menu.model.name];

                this.loadedModel = BaseModel

            }
        }

        callback({ menu, model: this.loadedModel });

        this.setState({ loading: true, component: this.loadedComponent, model: this.loadedModel });

    }

    render() {
        const { props } = this;

        const { menu } = this.props;

        const { loading, model, menuDetail } = this.state;

        const ComponentLoaderForParticulatMenu = this.loadedComponent;

        // Parse the attrbutes for the menu to customize the menu
        let attributes = {};

        if (menu.attributes) {
            if (typeof menu.attributes === 'string') {

                attributes = JSON.parse(menu.attributes);
            }
        }

        return (
            <div className="menu-template">
                {loading && ComponentLoaderForParticulatMenu && model ? (
                    <GlobalMenuProvider menu={menu}>

                        {/* Page Container */}
                        {/* # Note
                        
                        Page Container can be bought here but here but have to bind with the refresh 
                        action in the page . This would need to pass the handle as reference to the child
                        This should avoid use of page container in every component 
                        

                        // Make sure note to make overruse this area

                        */}
                        {/* <PageContainer menu={menu} title={menu.caption}> */}

                        {/* We extend the menu template to mould the components to control access to the page */}
                        {/* <FingerprintProtected menu={menu}> */}

                        <ComponentLoaderForParticulatMenu
                            {...props}
                            menu={menu}
                            model={model}

                            {...attributes}

                            {...props}
                            menuDetail={menuDetail}
                        />

                        {/* </FingerprintProtected> */}

                        {/* </PageContainer> */}

                    </GlobalMenuProvider>

                ) : null}
            </div>
        );
    }
}





// import LoadAsyncComponent from './../../Async/async';

// export default function MenuTemplate({ menu, ...props }) {


//     const [loading, setLoading] = useState(false);

//     // Model
//     const [model, setModel] = useState(() => null);

//     const [view, setView] = useState([]);

//     const [component, setComponent] = useState(() => null);

//     useEffect(() => {
//         // if (menu) {
//         // }

//         async function loadComponent() {
//             // Path to the component
//             // let path = menu.page.path;
//             console.log(menu);

//             const BaseModel = new BaseLoader(menu.model);

//             // Load the component with path
//             let loadedComponent = LoadAsyncComponent(() => require(`./../../../business/common/${menu.page.path}`));

//             let loadedModel = require('./../../../models/');

//             // const DynamicComponent = lazy(() => import(`./${component_path}`).catch(() => {}));
//             //

//             // let path = 'modules/common/resources/generic/generic-list/generic-list'

//             // const DynamicComponent = lazy(() => import(`./../../../${path}`).catch(() => {}));

//             // var component_url = `./../../../` + `/business/common/resources/generic/generic-list/generic-list`;

//             // loadedcomponent = require(`${component_path}`);

//             // loadedcomponent = LoadAsyncComponent(() => require(`${component_path}`));

//             // var component_url = '/src/business/common/resources/generic/generic-list/generic-list';

//             // loadedcomponent = await import(component_url);

//             // loadedcomponent = DynamicComponent;

//             // Load the models
//             // loadedModel = require('./../../../models/');

//             setComponent(loadedComponent);

//             setModel(BaseModel);
//             // setModel(loadedModel[menu.model.name]);

//             setLoading(true);

//             // list promises from menu
//             // const componentPromises = [menu].map(async (menu) => {
//             //     console.log(menu.page.path);

//             //     const View = await importView(menu.page.path);

//             //     console.log(View);

//             //     return <View key={''} {...menu} />;
//             // });

//             // Promise.all(componentPromises).then(setView);
//         }

//         loadComponent();
//     }, []);

//     const ComponentLoaderForParticulatMenu = component;

//     // const View = view;

//     console.log(ComponentLoaderForParticulatMenu);

//     return (
//         // <Suspense fallback={<div>Loading...</div>}>{OtherComponent ? <OtherComponent menu={menu} model={model} {...props} menuDetail={menuDetail} /> : null}</Suspense>
//         // <div className="menu-template">
//         //     {View && model ? <View.default.WrappedComponent menu={menu} model={model} {...props} menuDetail={menuDetail} /> : null}
//         // </div>
//         loading && ComponentLoaderForParticulatMenu && model ? (
//             <ComponentLoaderForParticulatMenu menu={menu} model={model} {...props} menuDetail={menuDetail} />
//         ) : null
//     );
// }

// const DynamicComponent = (subreddit) => lazy(() => import(`${subreddit}`).catch(() => {}));