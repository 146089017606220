
/**
 *
 * Generic Form create accepts an array of fields to update any resource
 */

import React, { useState, useEffect } from 'react';

import { Select } from 'antd';

import { BaseLoader } from '../../..';

const { Option } = Select;


// #TODO
/**
 * Below is the plan for clearing the pending list
 * 
 * nura single needs to be removed , 
 * 
 * as it has no dependency , Passing path should be a feature of both firebase and SQL Implementation
 * Or the component should accept model 
 * 
 * 
 */

/**
 * Component for selecting head
 *
 * @param {*} param0
 * @returns
 */
function ReferenceSelect({
    model,
    path = '',
    config,
    value,
    queries = [],
    onChange,
    label = "name",
    mode = "",
    field = 'id',
    ...componentProps
}) {

    let [options, setOptions] = useState([]);

    useEffect(() => {
        loadOptions()
    }, [])

    /**
     * Load the matching heads
     */
    function loadOptions() {

        const { defaultValueCondition = null } = componentProps

        // Mode to handle all cases of Nura/StopTB/Medicant
        if (mode === 'nura-base') {

            model.get({ ...config }).then((response) => {

                // Since base class implementation , data is available under data.
                if (response.result) {
                    setOptions(response.result);
                } else {
                    setOptions(response)
                }

                // Default value
                if (defaultValueCondition) {

                    // If the response 
                    if (response.result.length) {

                        // Trigger the default value
                        triggerDefaultValue(response.result);
                    }

                    // #Temporary Handling
                    if (response.length) {

                        triggerDefaultValue(response);
                    }
                }
            })

        } else if (mode === 'nura-single') {

            model.get(queries).then(({ result }) => {

                setOptions(result);

            })

        } else {

            if (path) {

                let dynamicModel = new BaseLoader({ path })

                dynamicModel.get(queries).then((result) => {

                    setOptions(result[dynamicModel.name]);

                })

            } else {

                model.get(queries).then((result) => {

                    setOptions(result[model.name]);

                })
            }
        }
    }

    /**
     * Function defaults the value from the matching record
     */
    function triggerDefaultValue(records) {

        const { defaultValueCondition = null } = componentProps

        let matching = defaultValueCondition(records);

        if (matching) {

            onChange(matching[field]);
        }
    }

    let props = {}

    if (mode == 'multiple') {
        props.mode = mode;
    }

    return (
        <div className="head-selector">
            <Select {...props} style={{ width: '100%' }} onChange={onChange} value={value} {...componentProps}>
                {options.map((option, key) => (
                    <Option key={key} value={option[field]} record={option}>
                        {option[label]}
                    </Option>
                ))}
            </Select>
        </div>
    );
}

export default (ReferenceSelect);



// import React, { useState } from "react";

// import PropTypes from "prop-types";

// import 'antd/dist/antd.css';

// /**
//  * A reference Select component.
//  */
// function ReferenceSelect({ options, onSelect, defaultValue }) {
//   const [selectedValue, setSelectedValue] = useState(defaultValue);

//   const handleChange = (event) => {
//     const value = event.target.value;
//     setSelectedValue(value);
//     onSelect(value);
//   };

//     useEffect(() => {
//         loadOptions()
//     }, [])

//     /**
//      * Load the matching heads
//      */
//     function loadOptions() {

//         const { defaultValueCondition = null } = componentProps

//         // Mode to handle all cases of Nura/StopTB/Medicant
//         if (mode === 'nura-base') {

//             model.get({ ...config }).then((response) => {

//                 // Since base class implementation , data is available under data.
//                 if (response.result) {
//                     setOptions(response.result);
//                 } else {
//                     setOptions(response)
//                 }

//                 // Default value
//                 if (defaultValueCondition) {

//                     // If the response 
//                     if (response.result.length) {

//                         // Trigger the default value
//                         triggerDefaultValue(response.result);
//                     }

//                     // #Temporary Handling
//                     if (response.length) {

//                         triggerDefaultValue(response);
//                     }
//                 }
//             })

//         } else if (mode === 'nura-single') {

//             model.get(queries).then(({ result }) => {

//                 setOptions(result);

//             })

//         } else {

//             if (path) {

//                 let dynamicModel = new BaseLoader({ path })

//                 dynamicModel.get(queries).then((result) => {

//                     setOptions(result[dynamicModel.name]);

//                 })

//             } else {

//                 model.get(queries).then((result) => {

//                     setOptions(result[model.name]);

//                 })
//             }
//         }
//     }

//     /**
//      * Function defaults the value from the matching record
//      */
//     function triggerDefaultValue(records) {

//         const { defaultValueCondition = null } = componentProps

//         let matching = defaultValueCondition(records);

//         if (matching) {

//             onChange(matching[field]);
//         }
//     }

//     let props = {}

//     if (mode == 'multiple') {
//         props.mode = mode;
//     }

//     return (
//         <div className="head-selector">
//             <Select {...props} style={{ width: '100%' }} onChange={onChange} value={value} {...componentProps}>
//                 {options.map((option, key) => (
//                     <Option key={key} value={option[field]} record={option}>
//                         {option[label]}
//                     </Option>
//                 ))}
//             </Select>
//         </div>
//     );
// }

// ReferenceSelect.propTypes = {
//   /**
//    * An array of reference options for the Select component.
//    */
//   options: PropTypes.arrayOf(
//     PropTypes.shape({
//       value: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
//         .isRequired,
//       label: PropTypes.string.isRequired,
//     })
//   ).isRequired,
//   /**
//    * A callback function that is called when a reference is selected.
//    */
//   onSelect: PropTypes.func.isRequired,
//   /**
//    * The default value for the Select component.
//    */
//   defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
// };

// ReferenceSelect.defaultProps = {
//   defaultValue: "",
// };

// export default ReferenceSelect;
