/**
 * Route of the application
 */
import "./registration.scss";

import { useEffect, useState, useCallback } from "react";

import {
  Location,
  DateUtils,
  Branches,
} from "soxo-firebase-core";


import {
  Form,
  Input,
  InputNumber,
  Button,
  Typography,
  DatePicker,
  Col,
  Row,
} from "antd";

import moment from "moment";

import { CandidateVisits } from "../../models/";
import Success from "../success/success";

// const { Outbox } = require('../index')

/**
 * 
 * @returns 
 */
function Registration() {

  const [branch, setBranch] = useState({});

  const [success, setSuccess] = useState(false);

  const layout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 7,
    },
  };

  const customLayout = {
    labelCol: {
      span: 12,
    },
    wrapperCol: {
      span: 14,
    },
  };

  let { branch_id = '' } = Location.search();

  const { Title } = Typography;

  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm();

  useEffect(() => {

    //  form.setFieldsValue({ remarks: 'Prefered report status'});


    if (branch_id) {

      localStorage.branch_id = branch_id;

    } else {

      if (localStorage.branch_id) {

        branch_id = localStorage.branch_id
      }
    }

    getBranch();
  }, []);

  /**
   * Get the branch
   */
  async function getBranch() {
    const branch = await Branches.getRecord(branch_id);

    setBranch(branch);
    console.log(branch, "fjgef");
    //  setlogo(logo)
  }



  /**
   *
   * @param {*} formBody
   */
  const onFinish = (formBody) => {

    setLoading(true)

    let params = {
      ...formBody,
      date: DateUtils.getDate(formBody.date),
      reference_number: DateUtils.getDate(formBody.date) + '/' + formBody.reference_index,
      branch_id: branch_id,
      mofa_status: "NE",
      remarks: formBody.remarks || 'Prefered report delivery and other details'
    };



    CandidateVisits.add(params).then(() => {

      form.resetFields();

      // CandidateVisits.loadFunction('triggerMail',{})

      setSuccess(true)

      // Location.navigate({
      //   url: `/success`,
      // });

      setLoading(false)


    });

  };


  /* eslint-disable no-template-curly-in-string */

  const validateMessages = {
    required: "${label} is required!",
    types: {
      number: "${label} is not a valid number!",
    },
    number: {
      range: "${label} must be between ${min} and ${max}",
    },
  };


  // Can not select days before today and today
  const disabledDate = (current) => {

    return current > moment().endOf('day');

  };

  /* eslint-enable no-template-curly-in-string */

  return (
    <div className="main">

      <div>
        {branch_id ? (
          <>
            {success ? (
              <Success
                callback={() => {
                  setSuccess(false);
                }}
              // callback={handleResetSuccess} 
              />
            ) : (
              <>
                <div className="title">
                  <Title level={2}>{branch.description}</Title>
                  <p>{branch.location}</p>
                </div>

                <p>Please fill the form below</p>

                <Form
                  form={form}
                  {...layout}
                  name="nest-messages"
                  layout="vertical"
                  onFinish={onFinish}
                  validateMessages={validateMessages}
                >
                  <Form.Item
                    name="name"
                    label="Name"
                    rules={[
                      {
                        max: 30,
                        message: 'Name cannot be more than 30 characters',
                      },
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Row>
                    <Col xs={12} md={8} xl={4}>
                      <Form.Item
                        {...customLayout}
                        name="date"
                        label="Medical Date"
                        rules={[
                          {
                            required: true,
                            message: 'Please choose a date!',
                          },
                        ]}
                      >
                        <DatePicker
                          disabledDate={disabledDate}
                          format="DD/MM/YYYY"
                          defaultPickerValue={moment()}
                        />
                      </Form.Item>
                    </Col>
                    <Col xs={12} md={8} xl={6}>
                      <Form.Item
                        {...customLayout}
                        name="reference_index"
                        label="Medical Reference No"
                        rules={[
                          {
                            max: 10,
                            message: 'Reference No cannot be more than 10 characters',
                          },
                          {
                            required: true,
                          },
                        ]}
                      >
                        <Input />
                      </Form.Item>
                    </Col>
                  </Row>

                  <Form.Item
                    name="email"
                    label="Email"
                    rules={[
                      {
                        max: 100,
                        message: 'Email cannot be more than 100 characters',
                      },
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input />
                  </Form.Item>

                  <Form.Item
                    name="passport_number"
                    label="Passport Number"
                    rules={[
                      {
                        max: 20,
                        message: 'Passport Number cannot be more than 20 characters',
                      },
                      {
                        required: true,
                        message: 'Please input your Passport number!',
                      },
                    ]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item
                    name="travelagentname"
                    label="Travel Agent"
                    rules={[
                      {
                        max: 30,
                        message: 'Travel Agent name cannot be more than 30 characters',
                      },
                      {
                        required: true,
                      },
                    ]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item
                    name="mofa_number"
                    label="Mofa Number"
                    rules={[
                      {
                        max: 20,
                        message: 'Mofa Number cannot be more than 20 characters',
                      },
                      {
                        required: true,
                        message: 'Please input your Mofa number!',
                      },
                    ]}
                  >
                    <Input style={{ width: '100%' }} />
                  </Form.Item>

                  <Form.Item
                    name="remarks"
                    label="Remarks"
                    extra="Preferred report delivery and other details."
                    rules={[
                      {
                        max: 30,
                        message: 'Remarks cannot be more than 30 characters',
                      },
                    ]}
                  >
                    <Input.TextArea />
                  </Form.Item>

                  <Form.Item wrapperCol={{ ...layout.wrapperCol, offset: 0 }}>
                    <Button type="primary" htmlType="submit" loading={loading}>
                      Submit
                    </Button>
                  </Form.Item>
                </Form>

                <p>* You will receive an email alert after Mofa update in the mentioned email address.</p>
              </>
            )}
          </>
        ) : (
          <h3>Invalid selection. Please contact admin</h3>
        )}
      </div>
    </div>
  );

}

export default Registration;
