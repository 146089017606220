
import React, { useState, useEffect, useContext } from "react";

import { Link } from 'react-router-dom'

import { Typography } from 'antd';

import "./notice-board.scss";

import { GlobalContext } from "./../../Store";

import moment from 'moment';

// var welcomeLogo = require('./../../../assets/welcome-image.png');

var welcomeLogo = require('./../../../assets/plant.png');

const { Title, Text } = Typography;


/**
 * Function would act as the notice board 
 */
export default function NoticeBoard({ steps = [] }) {

    const { user = {} } = useContext(GlobalContext);

    useEffect(() => {

        checkPasswordSecurity();

    }, []);

    /**
     * 
     */
    function checkPasswordSecurity() {

        if (user.passwordChanged) {

        }
    }

    let hours = (new Date()).getHours();

    let day = 'morning';

    if (hours < 11) {

        day = 'morning';

    } else if (hours > 11 && hours < 16) {

        day = 'afternoon';


    } else if (hours > 16 && hours < 21) {

        day = 'evening';

    } else {

        day = 'night';

    }

    var a = moment('2021/5/28');

    var b = moment();

    var days = a.diff(b, 'days') // 1


    return (
        <section className="notice-board">

            <div className="welcome-block card">
                <div className="content-block">

                    <Title level={4}>
                        Hello,
                    </Title>

                    <Title level={3}>
                        {user.name}
                    </Title>
                    <p>Welcome back . Wishing you a good day and good health. </p>
                </div>
                <div className="image-block">
                    <img
                        className="welcome-logo"
                        style={{ width: '100px' }}
                        src={welcomeLogo}
                        alt="Logo"
                    />
                </div>
            </div>

            {/* Change Password Block */}
            {/* {
                !user.passwordChanged
                    ?
                    <Link to="/change-password">

                        <div className="change-password-notice card">

                            <Title level={5}>
                                Change your password
                            </Title>

                            <p>
                                To ensure the security of your account please change your password . You have <h3>
                                    {days}
                                </h3> days remaining to complete this process.
                            </p>

                            <Link to="/change-password">
                                Do it Now
                            </Link>

                        </div>

                    </Link> : null
            } */}

            {/* Change Password Block Ends*/}


        </section>
    );
}


