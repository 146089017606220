/**
 * @author Hasna
 * Component to debounce api without debounce function
 */
import React, { useState, useEffect } from 'react'

import { Input } from 'antd'

const { Search } = Input

export default function SearchDebounce({ value, onChange }) {
  // To store query
  const [query, setQuery] = useState(value)

  /**
   * To avoid repeated call of Api, function will be triggered whenever query changes
   */
  useEffect(() => {
    const getData = setTimeout(() => {
      // Api that has to be called at repeated intervals
      onChange(query)
    }, 900)

    return () => clearTimeout(getData)
  }, [query])

  return (
    // Component to search query
    <Search
      placeholder="Enter Search Value"
      value={query}
      onChange={(e) => {
        setQuery(e.target.value)
      }}
    />
  )
}
