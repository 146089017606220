
import React, { useEffect, useState, Fragment } from "react";

import { Button, Skeleton, Timeline, Typography } from 'antd';

import { Steps, Forms, Columns, Scripts, ProcessTransactions, StepTransactions } from '../../..';

import { SettingOutlined, ExclamationCircleOutlined, CheckCircleOutlined } from '@ant-design/icons'

import { Location } from "../../../../utils";


export default function ProcessTimeline({ step, record }) {

    let { process_transaction_id, } = Location.search();

    const [steps, setSteps] = useState([])

    const [transactions, setTransactions] = useState([]);

    useEffect(() => {

        getStepTransactions().then((transactions) => {

            getProcess(step, transactions);

        });


        return () => {

        }

    }, [])

    /**
     * Get the process
     * 
     * @param {*} step 
     * @param {*} transactions 
     */
    function getProcess(step, transactions) {

        var queries = [{
            field: 'process_id',
            value: step.process_id
        }, {
            field: 'active',
            value: true
        }]

        Steps.get(queries).then((result) => {

            var modified_steps = Steps.orderArray(result.steps, 'order').map((step) => {

                var matching = transactions.filter((ele) => ele.step_id === step.id)

                if (matching.length) {

                    step = {
                        ...step,
                        complete: true,
                        transactions: matching
                    }

                } else {


                }

                return step;
            });

            setSteps(modified_steps);

            console.log(modified_steps);
        })

    }

    /**
     * Get the step transaction
     */
    function getStepTransactions() {

        var queries = [{
            field: 'process_transaction_id',
            value: process_transaction_id
        }]

        return StepTransactions.get(queries).then((result) => {

            console.log(result);

            return result.step_transactions;

        })
    }

    // color={Candidates.status === 'Schedule Pending' ? 'green' : 'gray'}
    // dot={Candidates.status === 'Schedule Pending' ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}


    return (<>

        <Timeline>

            {
                steps.sort((record) => record.order).map((entry) => {

                    return (<Timeline.Item color={entry.complete ? 'green' : 'gray'} dot={entry.complete ? <CheckCircleOutlined /> : <ExclamationCircleOutlined />}>
                        {entry.name}


                        <p>
                            <small>
                                {
                                    entry.complete
                                        ?
                                        <>

                                            Completed at {entry.transactions[0].created_at}

                                        </>
                                        :
                                        null
                                }
                            </small>
                        </p>

                    </Timeline.Item>)

                })
            }

        </Timeline>

    </>)

}