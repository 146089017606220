/***
 *
 *
 * @description
 * @author Jinisha kappil
 */

/**
 *
 * Generic componet used for user search
 */

import React, { useState, useEffect } from "react";

import {
  Input,
  Modal,
  Button,
  Table,
  Select,
  Form,
  Row,
  Col,
  Typography,
  Skeleton,
} from "antd";

import { ApiUtils } from "../../..";

const { Search } = Input;

const { Title } = Typography;

/**
 * This  Component  is used for Common  Search . Is Based On criteria and Search By its comes from model  .
 * In this componet open a model and and select search aby and criteria after that it load corresponding results 
 *
 * @param {*} param0
 * @returns
 */
function UserSearch({ model }) {

  let config = model.searchConfig;

  let [result, setResult] = useState([]);

  let [searchby, setSearchBy] = useState(config.searchBy[0].field);

  let [criteria, setCriteria] = useState([]);

  const [visible, setVisible] = useState(false);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
  }, []);


  /**
   *    Is used to Load search heads
   * @param {*} event
   * @returns
   */
  async function loadSearch(event) {

    setResult([]);

    if (model.searchConfig.url) {

      setLoading(true);

      return await ApiUtils.get({

        url: model.searchConfig.url + "?" + searchby + "=" + event.target.value,

      }).then((result) => {

        setResult(result.result);

        setLoading(false);
      });
    }
  }
  return (
    <div className="head-selector">
      <Button
        className="billing"
        onClick={() => {
          setVisible(true);
        }}
        type="secondary"
        shape="round"
        // size="small"
        color="green"
      >
        Search{" "}
      </Button>

      <Modal
        destroyOnClose={true}
        visible={visible}
        // title='Advance Search'
        footer={null}
        className=""
        onCancel={() => {
          setVisible(false);
          setResult(null);
        }}
        width={"80%"}
        maskClosable={false}
      >
        <div>
          <Title level={4}>Advance Search</Title>
        </div>
        <Form>
          <div className="card">
            <Row gutter={12}>
              {/** Search Section Start */}
              <Col xs={24} md={12} xl={8}>
                <h3>SEARCH </h3>

                <Form.Item>
                  <Search
                    placeholder="Enter Search Value"
                    // allowClear
                    onChange={loadSearch}
                  />
                </Form.Item>
              </Col>
              {/** Search section end  */}
              {/** Search  By Section Start  */}
              <Col xs={24} md={12} xl={7}>
                <h3>SEARCH BY</h3>
                <Form.Item>
                  <Select
                    defaultValue="Fist Name"
                    //   style={{ width: "99%" }}
                    onChange={(i) => {
                      setSearchBy(i);
                    }}
                  >
                    {config.searchBy.map((option, key) => (
                      <Option value={option.field}>{option.caption}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {/** Search B Section end  */}

              {/** Criteria Section  starts */}
              <Col xs={3} md={6} xl={6}>
                <h3>CRITERIA</h3>

                <Form.Item>
                  <Select
                    defaultValue="Start With"
                    //   style={{ width: "99%" }}
                    onChange={(i) => {
                      setCriteria(i);
                    }}
                  >
                    {config.criteria.map((option, key) => (
                      <Option value={option.field}>{option.caption}</Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>

              {/** Criteria section End */}
            </Row>
          </div>

          <h2 className="size-hint">
            {result ? result.length : 0} Matches Found For Your Search .
          </h2>
          {loading ? (
            <Skeleton />
          ) : (
            <Table columns={config.columns} dataSource={result}></Table>
          )}

          {/* </Row> */}
        </Form>
      </Modal>
    </div>
  );
}

export default UserSearch;
