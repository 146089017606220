/*
Implements utility functions to be used across project
*/

/*eslint no-useless-escape:"off",eqeqeq: "off"*/
import moment from "moment";

export function IsObjectHaveKeys(obj) {
  return obj && typeof obj == "object" && Object.keys(obj).length;
}

export function capitalizeSentence(sentence) {
  const words = sentence.split(" ");

  return words
    .map((word) => {
      return word[0].toUpperCase() + word.substring(1);
    })
    .join(" ");
}

// export function capitalizeStrings(str) {
//     // Split the string at all space ccaracters
//     return str.trim().split(' ')
//         // get rid of any extra spaces using trim
//         .map(a => a.trim())
//         // Convert first char to upper case for each word
//         .map(a => a[0].toUpperCase() + a.substring(1).toLowerCase())
//         // Join all the strings back together
//         .join(" ")
// }

export function capitalizeStrings(str) {
  return str;
}

export function getFormattedDateAndTime(val) {
  var time = [];
  var year = moment.duration(val).years();
  var month = moment.duration(val).months();
  var days = moment.duration(val).days();
  var hour = moment.duration(val).hours();
  var min = moment.duration(val).minutes();
  if (year > 0) {
    time.push(year + "  Years" + "  ");
  }
  if (month > 0) {
    time.push(month + "  Months" + "  ");
  }
  if (days > 0) {
    time.push(days + "  Days" + "  ");
  }
  if (hour > 0) {
    time.push(hour + "  Hours" + "  ");
  }
  if (min > 0) {
    time.push(min + "  Minutes" + "  ");
  }
  time = [].concat(...time);
  return time;
}

/**
 * Function converts bytes to array
 *
 * @param {*} bytes
 * @returns
 */
export function ConvertBytesToArray(bytes) {
  var bytearray = Object.keys(bytes);

  var arrayelement = Object.values(bytes);

  var uint8Array = new Uint8Array(bytearray.length);

  for (var i = 0; i < uint8Array.length; i++) {
    uint8Array[i] = arrayelement[i];
  }

  for (var i = 0; i < bytearray; i++) {
    var ascii = arrayelement.charCodeAt(i);
    uint8Array[i] = ascii;
  }

  return uint8Array;
}
