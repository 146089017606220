import React, { useEffect, useContext, useState } from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';


// import

import {
    GlobalHeader,
    Landing,
} from './../';

import { Login, Register } from './../../pages/'

import {
    GlobalContext,
    GlobalProvider,
} from '../../Store';

import { Location } from './../../utils/'


export default function ApplicationBootstrap({ children, CustomComponents, CustomModels, appSettings, steps = {}, legacyMode = false, ...props }) {

    console.log("Starting Application now");

    useEffect(() => {
        return () => { };
    }, []);

    return (
        <GlobalProvider CustomModels={CustomModels} appSettings={appSettings}>
            <Router>
                <Route exact path="" component={(props) => {

                    return <Routes children={children} steps={steps} {...props} appSettings={appSettings} CustomComponents={CustomComponents} CustomModels={CustomModels} />

                }} />
            </Router>
        </GlobalProvider>
    );
}




function Routes({ history, children, appSettings }) {

    const [loader, setLoader] = useState(false);

    const { user = {}, twilio } = useContext(GlobalContext);

    useEffect(() => {
        Location.getHistoryMethod(getRouterProps);
    }, []);

    useEffect(() => {

        // console.log(user);

        // if (user && user.loggedCheckDone) {

        //     // Redirect to login page
        //     setLoader(false);
            

        //     if (!user.id) {

        //         history.push('/login');
        //     }
        // }
    }, [user]);


    const getRouterProps = () => ({ history: history });


    useEffect(() => {
        return () => { };
    }, []);

    return (
        <div className={`app`}>
            <div className={`content ${twilio ? 'connected' : ''}`}>
                {/* Onboarding welcome card */}
                {!loader && user.loggedCheckDone ? (
                    <div>
                        <Switch>

                            {/* Login Page */}
                            <Route exact path="/login" render={(routeParams) => {

                                return <Login appSettings={appSettings} {...routeParams} />
                            }} />
                            {/* Login Page Ends */}

                            {/* Other Custom Components in addition to the default */}
                            {children}
                            {/* Other Custom Components in addition to the default Ends */}

                        </Switch>
                    </div>
                ) : (
                    <div>
                        <GlobalHeader appSettings={appSettings}
                        />
                        {/* <PlaceHolder type="listing" /> */}
                    </div>
                )}
            </div>
        </div>
    );
}



