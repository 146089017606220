/**
 * 
 */

import React from "react";


import { Base } from './../';

import { Link } from 'react-router-dom';

import { Tag } from 'antd';

import { Models, Pages } from '../../models/';

// import { Candidates } from '../../../models';

import DateUtils from '../../utils/date/date.utils';

import { ImagePreview } from './../../components/'

// import AppointmentDetail from '../business/appointments/components/appointment-detail/appointment-detail';

class Attachments extends Base {

    constructor() {

        super();

        this.fields = [
            {
                field: 'meeting_link',
                caption: 'Meeting Link',
            },
            {
                field: 'report_link',
                caption: 'Report Link',
            },

        ];

        // this.disableAddModal = true;
    }

    get disableAdd() {
        return true;
    }

    get getEndpoint() {
        return 'attachments';
    }

    get path() {
        return `attachments`;
    }

    get getName() {
        return this.modelName + `s`;
    }

    get modelName() {
        return `attachment`;
    }

    // get disableAddModal() {
    //     return true;
    // }

    get columns() {

        return [

            {
                caption: 'Aadhar Number',
                field: 'adhar_number',
                key: 'adhar_number',
            },

            {
                caption: 'Photograph',
                render: (record) => {

                    return <ImagePreview type={'array'} content={record.photograph} />
                },
                // field: 'created_at',
                key: 'created_at',
            },
        ];
    }

    extraParams = () => {


        return {
            // status: 'Initial'
        }

    }

    /**
     * Get the data from the table
     */
    // get = (queries) => {
    //     return this.getMethod(queries).then((result) => {
    //         return Promise.all(result.appointments.map((menu) => this.loadCandidate(menu))).then((result) => {
    //             return { appointments: result };
    //         });
    //     });
    // };

    /**
     * Get the details appointment
     * 
     * @param {*} id 
     * @returns 
     */
    getDetail = (id) => {

        return this.getRecord(id).then((appointment) => {

            // return Candidates.getRecord(appointment.candidate_id).then((result) => {

            return {
                ...appointment,
                // candidate: result
            }
            // })
        })
    }

    /**
     * Load the module of the menu
     *
     * @param {*} param0
     * @returns
     */
    // loadCandidate = (appointment) => {

    //     return Candidates.getRecord(appointment.candidate_id)
    //         .then((result) => {
    //             return {
    //                 ...appointment,
    //                 candidate: result,
    //             };
    //         })
    // };

    /**
     * load the detail of menu
     *
     * @param {*} menu
     * @returns
     */
    // getDetail = (menu) => {
    //     return this.getRecord(menu).then((result) => {
    //         return result;
    //     });
    // };

    /**
     * Function to load the menus
     *
     * @param {*} item
     * @returns
     */
    loadMenus = (item) => {
        return this.get().then((result) => {
            return Promise.all(result.menus.map(this.loadDetail)).then((result) => {
                return { menus: result };
            });
        });
    };

    /**
     * Load the related model and the page
     *
     * @param {*} item
     * @returns
     */
    loadDetail = (item) => {
        return Models.getRecord(item.model)
            .then((result) => {
                return {
                    ...item,
                    caption: item.name,
                    model: result,
                };
            })
            .then((item) => {
                return Pages.getRecord(item.page).then((result) => {
                    return {
                        ...item,
                        page: result,
                    };
                });
            });
    };

    /**
     * Card Component for Staff
     *
     * @param {*} record
     * @returns
     */
    Card = ({ record, redirect_to = 'appointments' }) => {
        return (
            <Link className="booking-card" to={`/${redirect_to}/${record['id']}`}>
                <div className="card">
                    <h2 className="">{record.candidate.name}</h2>

                    <h3 className="title">{DateUtils.appointmentTime(record)}</h3>

                    <p className="address">
                        Created on {record.created_at}
                        <Tag color="magenta">{record['Consultant Name']}</Tag>
                    </p>
                </div>
            </Link>
        );
    };

    // AddComponent = (props) => <PatientAdd model={this} {...props} />;

    // UploadComponent = (props) => <PatientUpload model={this} {...props} />;

    // EditComponent = (props) => <MenuAdd {...props} edit={true} model={this} />;

    // DetailComponent = (props) => <AppointmentDetail {...props} model={this} />;
}

export default Attachments;
